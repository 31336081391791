import { createSlice } from "@reduxjs/toolkit";

const initialStateObj = {
  roomDetails: {},
};

export const roomDetails = createSlice({
  name: "roomDetails",
  initialState: initialStateObj,
  reducers: {
    setRoomDetails: (state: any, { payload }) => {
      state.roomDetails = payload;
    },
    removeRoomDetails: (state: any) => {
      state.roomDetails = {};
    },
  },
});

//const { setcommonDetails, removecommonDetails } = commonDetailSlice.actions;

//it behave like connector (old redux)

export default roomDetails;

import {PlayerContext} from '../../adapters/amazonIVS/player/AmazonIVSPlayerContext';
import {AnalyticsEventBase} from '../../types/EventData';

export interface HeartbeatListener {
  onHeartbeat: (eventObject: AnalyticsEventBase) => void;
}

export class HeartbeatService {
  private heartbeatInterval: number | undefined;
  private listener: HeartbeatListener | undefined;

  constructor(
    private heartbeatIntervalMs: number,
    private playerContext: PlayerContext,
  ) {}

  setListener(listener: HeartbeatListener) {
    this.listener = listener;
  }

  startHeartbeat() {
    if (this.heartbeatInterval !== undefined) {
      this.clearHeartbeat();
    }
    this.heartbeatInterval = window.setInterval(() => {
      const eventData: AnalyticsEventBase = {
        currentTime: this.playerContext.position,
      };
      this.listener?.onHeartbeat(eventData);
    }, this.heartbeatIntervalMs);
  }

  stopHeartbeat() {
    this.clearHeartbeat();
  }

  private clearHeartbeat() {
    window.clearTimeout(this.heartbeatInterval);
    this.heartbeatInterval = undefined;
  }
}

import { Avatar, Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EllipsisContent from "../../../../Common/Component/EllipsisContent";
import NOTEAM from "../../../../assets/img/noteam.png";
const TeamBox = ({ el, selectedTeams, setSelectedTeam }: any) => {
  const [isDiabled, setIsDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleChangeCheckBox = (e: any) => {
    const isChecked = e?.target?.checked;
    if (isChecked) {
      const array: any = [];
      array.push(el);
      setSelectedTeam([...selectedTeams, ...array]);
    } else {
      const filterOutTeams = selectedTeams?.filter(
        (ele: any) => ele?.team_id != el?.team_id
      );
      setSelectedTeam(filterOutTeams);
    }
  };
  const checkChecked = () => {
    if (selectedTeams?.length) {
      const findTeam = selectedTeams?.find(
        (ele: any) => ele?.team_id == el?.team_id
      );
      if (findTeam) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    } else {
      setIsChecked(false);
    }
  };
  const checkIsDisabled = () => {
    if (selectedTeams?.length >= 5) {
      const findTeam = selectedTeams?.find(
        (ele: any) => ele?.team_id == el?.team_id
      );
      if (findTeam) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }
  };
  useEffect(() => {
    checkIsDisabled();
    checkChecked();
  }, [selectedTeams]);
  return (
    <Box
      sx={{
        border: "1px solid #DADADA",
        borderRadius: "4px",
        p: 2,
        bgcolor: "#fff",
      }}
    >
      <Grid container justifyContent={"space-between"} spacing={1}>
        <Grid item xs={10}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar src={el?.team_logo_url ? el?.team_logo_url : NOTEAM} />
            <Box sx={{ width: "fit-content" }}>
              <EllipsisContent
                text={el?.team_name}
                variant="body2"
                maxWidth={120}
                sx={{ fontStyle: "normal", textTransform: "capitalize" }}
              />
              {el?.team_description && (
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "capitalize",
                    fontSize: "10px",
                  }}
                >
                  ({el?.team_description})
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Checkbox
            disabled={isDiabled}
            checked={isChecked}
            onChange={handleChangeCheckBox}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default TeamBox;

import { Box, Typography } from "@mui/material";
import React from "react";
import PlayerAlertListTable from "./PlayerAlertListTable";

const PlayerAlertList = () => {
  return (
    <Box>
      <PlayerAlertListTable />
    </Box>
  );
};
export default PlayerAlertList;

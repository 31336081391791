import styled from "@emotion/styled";
import {TableRow,} from "@mui/material";
export const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#0053a01f",
    },
  }));
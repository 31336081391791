import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PageRoutes from "../../../../../utils/constants";
import ImageDialogBox from "../../../SliderSettings/ReorderTable/dialog";
import StatusBox from "../PlayerAlertListTableStatus";
import { Link } from "react-router-dom";

const PlayerAlertTable = ({
  allPlayerAlerts,
  isLoading,
  handleRefresh,
}: {
  allPlayerAlerts: any[];
  isLoading: boolean;
  handleRefresh: () => void;
}) => {
  const emptyRows = 0;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Overlays Text/Image
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Game Details
                </Typography>
              </TableCell>

              <TableCell align="right">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              {new Array(5).fill(0).map((el) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>

                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {allPlayerAlerts?.map((row: any) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.Type === "text" ? (
                        row?.Message
                      ) : (
                        <ImageDialogBox src={row.Image} />
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      <Link
                        target="_blank"
                        to={PageRoutes.Watch.replace(
                          ":gameId",
                          row?.GameDetails?.game_id
                        )}
                      >
                        {row?.GameDetails?.away_team} @{" "}
                        {row?.GameDetails?.home_team}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    <StatusBox data={row} handleRefresh={handleRefresh} />
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {/* <TablePagination
          component="div"
          colSpan={3}
          rowsPerPageOptions={[5, 10, 25]}
          count={allUsers.total_entries}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        /> */}
      </TableContainer>
    </Box>
  );
};
export default PlayerAlertTable;

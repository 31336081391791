import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentAuth, Logout } from "../../../hooks/useAxiosPrivate";
import useConfig from "../../../hooks/useConfig";
import commonDetailSlice from "../../../store/reducers/commonReducer";
import PageRoutes from "../../../utils/constants";
import { FooterBlock, FooterBody, FooterMenu } from "./Footer.style";
import moment from "moment";

const Footer = () => {
  const navigate = useNavigate();
  const auth = getCurrentAuth();
  const configData = useConfig();
  const dispatch = useDispatch();
  return (
    <Box id="footer" sx={{ ...FooterBlock, bgcolor: configData.primary }}>
      {/* <Box sx={PaddingBlock} /> */}
      <Box sx={FooterBody}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column-reverse", md: "row" },
              gap: 5,
              padding: "20px",
              justifyContent: { xs: "end", lg: "flex-end" },
            }}
          >
            <Box
              sx={{
                m: { xs: "auto", md: 0 },
                textAlign: "left",
                display: "flex",
                width: 1,
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Typography variant="body2" sx={{ ...FooterMenu, mb: 2 }}>
                © {moment().format("YYYY")} {configData?.subtitle}. All Rights
                Reserved.
              </Typography>
              <Typography variant="body2" sx={FooterMenu}>
                <span onClick={() => navigate(PageRoutes.TermAndConditions)}>
                  Terms & Conditions
                </span>{" "}
                /{" "}
                <span onClick={() => navigate(PageRoutes.PrivacyPolicy)}>
                  Privacy Policy
                </span>{" "}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;

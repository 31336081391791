import { SxProps } from "@mui/material";
const Style: { [key: string]: SxProps } = {
  LoginBox: {
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    minHeight: "100vh",
  },
  LoginWithExternal: {
    bgcolor: "white",
    color: "black",
    ":hover": { bgcolor: "white" },
    borderRadius: "2px",
    width: 1,
    gap: 1,
    display: "flex",
  },
  LoginLeft: {
    display: "flex",

    gap: 1.5,
    width: { xs: "300px", md: "400px" },
    m: "auto",
    p: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  LoginRight: {
    display: "flex",
    alignItems: "center",
    mt: { xs: 4, md: 0 },
    mx: "auto",
    flexDirection: "column",
    minHeight: "100vh",
    maxWidth: "1000px",
    justifyContent: "center",
  },
  Card: {
    border: "2px solid #CEDDFF",
    background: "rgba(255, 255, 255, 0.002)",
    boxShadow: "0px 4px 12px rgba(31, 33, 41, 0.08)",
    borderRadius: "8px",
    px: { xs: 1.5, md: 3 },
    py: 2,
    display: "flex",
    justifyContent: "space-between",
    height: "85%",

    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
};
export default Style;

import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import Style from "../Login/Login.style";
import AiryisLogo from "../../assets/img/AiryisLogo.svg";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { toast } from "react-toastify";
import React from "react";
import HttpService from "../../hooks/Https-services";
const VerifyEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSucces, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const VerfiyEmailFunction = async () => {
    setIsSkeleton(true);
    try {
      if (searchParams.get("token")) {
        const res = await HttpService.post(APIRoutes.VerifyEmail, {
          token: searchParams.get("token"),
        });
        if (res) {
          setIsSuccess(true);
          toast.success("Email Verified Successfully");
          navigate(PageRoutes.Login);
        }
      }
      setIsSkeleton(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.message);
      setErrorMessage(error.response.data.message);
      setIsSuccess(false);
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    VerfiyEmailFunction();
  }, []);
  return (
    <Box sx={{ minHeight: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={Style.LoginBox}>
            <Box sx={{ ...Style.LoginLeft, width: 1 }}>
              {isSkeleton ? (
                <CircularProgress />
              ) : (
                <Box>
                  {isSucces ? (
                    <Typography variant="h1" sx={{ textAlign: "center" }}>
                      Email Verified Successfully
                    </Typography>
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h1" sx={{ textAlign: "center" }}>
                        {errorMessage}
                      </Typography>
                      {errorMessage == "Email is already verified" ? (
                        <Button
                          variant="contained"
                          onClick={() => navigate(PageRoutes.Login)}
                        >
                          Login
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigate(`${PageRoutes.Login}?q=false`)
                          }
                        >
                          Try again
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VerifyEmail;

import React from "react";
import { useEffect } from "react";
import useConfig from "../../hooks/useConfig";
import "./index.css";
const TermAndCondition = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const configData = useConfig();
  return (
    <div className="container-fluid">
      <div className="main-content padding-top">
        <div className="container-fluid">
          <div className="main-container mb-5">
            <div className="pricing-box d-flex w-100">
              <div className="pricing-title">
                <h3>Terms and Conditions</h3>
              </div>
              <div style={{ textAlign: "left", padding: "20px" }}>
                {/* Hello world */}

                <style
                  id="dynCom"
                  type="text/css"
                  dangerouslySetInnerHTML={{ __html: "<!-- -->" }}
                />
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;\n\tpanose-1:2 0 0 0 0 0 0 0 0 0;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n',
                  }}
                />
                <div className="WordSection1">
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Your Acceptance of These Terms of Use
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THESE TERMS OF USE APPLY TO ALL USERS OF THIS SITE. BY
                          USING THIS SITE YOU ARE AGREEING TO COMPLY WITH AND BE
                          BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO
                          THESE TERMS OF USE, YOU MAY NOT ACCESS OR USE THIS
                          SITE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IMPORTANT NOTE:&nbsp; YOUR SUBSCRIPTION OR PLAN IS NOT
                          TO ANY SPECIFIC CONFERENCE, LEAGUE, TEAM, GAME OR
                          EVENT. &nbsp;IT IS A SUBSCRIPTION OR PLAN TO THE
                          SITE.&nbsp; A GAME OR MULTIPLE GAMES MAY HAVE ISSUES
                          WITH THE STREAM, VIDEO, AUDIO, GRAPHICS, PRODUCTION OR
                          OTHER ELEMENTS.&nbsp; THIS DOES NOT ENTITLE YOU TO A
                          REFUND, IN WHOLE, OR IN PART.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Your Acceptance of Our Privacy Policy
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          BY AGREEING TO THESE TERMS OF USE, YOU AGREE TO THE
                          TERMS OF OUR PRIVACY POLICY WHICH IS EXPRESSLY
                          INCORPORATED HEREIN. BEFORE USING THIS SITE, PLEASE
                          CAREFULLY REVIEW OUR PRIVACY POLICY. ALL PERSONAL
                          INFORMATION PROVIDED TO US AS A RESULT OF YOUR USE OF
                          THIS SITE WILL BE HANDLED IN ACCORDANCE WITH OUR
                          PRIVACY POLICY. TO THE EXTENT THERE ARE
                          INCONSISTENCIES BETWEEN THESE TERMS OF USE AND OUR
                          PRIVACY POLICY, THESE TERMS OF USE CONTROL.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Your Consent to Other Agreements
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WHEN YOU SIGN UP TO USE A SPECIAL FEATURE OF THIS
                          SITE, YOU MAY BE ASKED TO AGREE TO SPECIAL TERMS
                          GOVERNING YOUR USE OF THE SPECIAL FEATURE. IN SUCH
                          CASES, YOU MAY BE ASKED TO EXPRESSLY CONSENT TO THE
                          SPECIAL TERMS, FOR EXAMPLE, BY CHECKING A BOX OR
                          CLICKING ON A BUTTON MARKED “I AGREE.” THIS TYPE OF
                          AGREEMENT IS KNOWN AS A “CLICK-THROUGH” AGREEMENT. IF
                          ANY OF THE TERMS OF THE CLICK-THROUGH AGREEMENT ARE
                          DIFFERENT THAN THE TERMS OF THESE TERMS OF USE, THE
                          TERMS OF THE CLICK-THROUGH AGREEMENT WILL SUPPLEMENT
                          OR AMEND THIS THESE TERMS OF USE, BUT ONLY WITH
                          RESPECT TO THE MATTERS GOVERNED BY THE “CLICK-THROUGH
                          AGREEMENT.”
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Ownership of this Site and its Content
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THIS SITE, INCLUDING ALL ITS CONTENT ARE PROTECTED
                          UNDER APPLICABLE INTELLECTUAL PROPERTY AND OTHER LAWS,
                          INCLUDING WITHOUT LIMITATION THE LAWS OF THE UNITED
                          STATES AND OTHER COUNTRIES. ALL CONTENT AND
                          INTELLECTUAL PROPERTY RIGHTS THEREIN ARE THE PROPERTY
                          OF THE SITE OWNER&nbsp; OR THE MATERIAL IS INCLUDED
                          WITH THE PERMISSION OF THE RIGHTS OWNER AND IS
                          PROTECTED PURSUANT TO APPLICABLE COPYRIGHT AND
                          TRADEMARK LAWS.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE PRESENCE OF ANY CONTENT ON THIS SITE DOES NOT
                          CONSTITUTE A WAIVER OF ANY RIGHT IN SUCH CONTENT. YOU
                          DO NOT ACQUIRE OWNERSHIP RIGHTS TO ANY SUCH CONTENT
                          VIEWED THROUGH THIS SITE. EXCEPT AS OTHERWISE PROVIDED
                          HEREIN, NONE OF THIS CONTENT MAY BE USED, COPIED,
                          REPRODUCED, DISTRIBUTED, REPUBLISHED, DOWNLOADED,
                          MODIFIED, DISPLAYED, POSTED OR TRANSMITTED IN ANY FORM
                          OR BY ANY MEANS, INCLUDING, BUT NOT LIMITED TO,
                          ELECTRONIC, MECHANICAL, PHOTOCOPYING, RECORDING, OR
                          OTHERWISE, WITHOUT OUR EXPRESS PRIOR WRITTEN
                          PERMISSION.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          PERMISSION IS HEREBY GRANTED TO THE EXTENT NECESSARY
                          TO LAWFULLY ACCESS AND USE THIS SITE AND TO DISPLAY,
                          DOWNLOAD, OR PRINT PORTIONS OF THIS SITE ON A
                          TEMPORARY BASIS AND FOR YOUR PERSONAL, EDUCATIONAL,
                          NONCOMMERCIAL USE ONLY, PROVIDED THAT YOU (I) DO NOT
                          MODIFY THE CONTENT; (II) YOU RETAIN ANY AND ALL
                          COPYRIGHT AND OTHER PROPRIETARY NOTICES CONTAINED IN
                          THE CONTENT; AND (III) YOU DO NOT COPY OR POST THE
                          CONTENT ON ANY NETWORK COMPUTER OR BROADCAST THE
                          CONTENT IN ANY MEDIA.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Trademarks
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE SITE OWNER’S NAMES&nbsp; AND LOGOS (INCLUDING,
                          WITHOUT LIMITATION, THOSE OF ITS AFFILIATES,
                          INCLUDING, WITHOUT LIMITATION, ANY ASSOCIATED OR
                          AFFILIATED&nbsp; COMPANIES), ALL PRODUCT AND SERVICE
                          NAMES, ALL GRAPHICS, ALL BUTTON ICONS, AND ALL
                          TRADEMARKS, SERVICE MARKS AND LOGOS APPEARING WITHIN
                          THIS SITE, UNLESS OTHERWISE NOTED, ARE TRADEMARKS
                          (WHETHER REGISTERED OR NOT), SERVICE MARKS AND/OR
                          TRADE DRESS OF THE SITE OWNER&nbsp; AND/OR ITS
                          AFFILIATES. ALL OTHER TRADEMARKS, PRODUCT NAMES,
                          COMPANY NAMES, LOGOS, SERVICE MARKS AND/OR TRADE DRESS
                          MENTIONED, DISPLAYED, CITED OR OTHERWISE INDICATED
                          WITHIN THIS SITE ARE THE PROPERTY OF THEIR RESPECTIVE
                          OWNERS. YOU ARE NOT AUTHORIZED TO DISPLAY OR USE THE
                          SITE OWNER’S MARKS IN ANY MANNER WITHOUT OUR PRIOR
                          WRITTEN PERMISSION. YOU ARE NOT AUTHORIZED TO DISPLAY
                          OR USE TRADEMARKS, PRODUCT NAMES, COMPANY NAMES,
                          LOGOS, SERVICE MARKS AND/OR TRADE DRESS OF OTHER
                          OWNERS FEATURED WITHIN THIS SITE WITHOUT THE PRIOR
                          WRITTEN PERMISSION OF SUCH OWNERS. THE USE OR MISUSE
                          OF THE SITE OWNER’S&nbsp; MARKS OR OTHER TRADEMARKS,
                          PRODUCT NAMES, COMPANY NAMES, LOGOS, SERVICE MARKS
                          AND/OR TRADE DRESS OR ANY OTHER MATERIALS CONTAINED
                          HEREIN, EXCEPT AS PERMITTED HEREIN, IS EXPRESSLY
                          PROHIBITED.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Responsibility for User-Generated Content Posted on or
                        Through this Site
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU ARE RESPONSIBLE FOR USER-GENERATED CONTENT THAT
                          YOU POST. UNDER NO CIRCUMSTANCES WILL WE BE LIABLE IN
                          ANY WAY FOR ANY UGC.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THIS MEANS THAT YOU, NOT THE SITE OWNER, ARE ENTIRELY
                          RESPONSIBLE FOR ALL UGC THAT YOU POST AND THAT YOU CAN
                          BE HELD PERSONALLY LIABLE FOR COMMENTS THAT ARE
                          DEFAMATORY, OBSCENE, OR LIBELOUS, OR THAT VIOLATE
                          THESE TERMS OF USE, AN OBLIGATION OF CONFIDENTIALITY,
                          OR THE RIGHTS OF OTHERS. IF ANY PART OF THE UGC YOU
                          POST IS NOT YOUR ORIGINAL WORK, IT IS YOUR
                          RESPONSIBILITY TO OBTAIN ANY NECESSARY PERMISSION TO
                          POST IT.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          BECAUSE WE DO NOT CONTROL THE UGC POSTED ON OR THROUGH
                          THIS SITE, WE CANNOT AND DO NOT WARRANT OR GUARANTEE
                          THE TRUTHFULNESS, INTEGRITY, SUITABILITY, OR QUALITY
                          OF THAT UGC. YOU ALSO AGREE AND UNDERSTAND THAT BY
                          ACCESSING THIS SITE, YOU MAY ENCOUNTER UGC THAT YOU
                          MAY CONSIDER TO BE OBJECTIONABLE. WE HAVE NO
                          RESPONSIBILITY FOR ANY UGC, INCLUDING WITHOUT
                          LIMITATION ANY ERRORS OR OMISSIONS THEREIN. WE ARE NOT
                          LIABLE FOR ANY LOSS OR DAMAGE OF ANY KIND YOU MAY
                          CLAIM WAS INCURRED AS A RESULT OF THE USE OF ANY UGC
                          POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE
                          AVAILABLE ON OR THROUGH THIS SITE. THE UGC POSTED ON
                          OR THROUGH THIS SITE EXPRESSES THE PERSONAL OPINIONS
                          OF THE INDIVIDUALS WHO POSTED IT AND DOES NOT
                          NECESSARILY REFLECT THE VIEWS OF THE SITE OWNER&nbsp;
                          OR ANY PERSON OR ENTITY ASSOCIATED WITH THE SITE
                          OWNER.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU OWN USER-GENERATED CONTENT, BUT WE MAY USE IT. YOU
                          OWN THE COPYRIGHT IN ANY ORIGINAL UGC YOU POST. WE DO
                          NOT CLAIM ANY COPYRIGHTS IN UGC. HOWEVER, BY USING
                          THIS SITE YOU ARE GRANTING US AND OUR SUBSIDIARIES,
                          AFFILIATES, SUCCESSORS AND ASSIGNS, A NONEXCLUSIVE,
                          FULLY PAID, WORLDWIDE, PERPETUAL, IRREVOCABLE,
                          ROYALTY-FREE, TRANSFERABLE LICENSE (WITH THE RIGHT TO
                          SUBLICENSE THROUGH UNLIMITED LEVELS OF SUBLICENSEES)
                          TO USE, COPY, MODIFY, DISTRIBUTE, PUBLICLY DISPLAY AND
                          PERFORM, PUBLISH, TRANSMIT, REMOVE, RETAIN REPURPOSE,
                          AND COMMERCIALIZE UGC YOU POST IN ANY AND ALL MEDIA OR
                          FORM OF COMMUNICATION WHETHER NOW EXISTING OR
                          HEREAFTER DEVELOPED, WITHOUT OBTAINING ADDITIONAL
                          CONSENT, WITHOUT RESTRICTION, NOTIFICATION, OR
                          ATTRIBUTION, AND WITHOUT COMPENSATING YOU IN ANY WAY,
                          AND TO AUTHORIZE OTHERS TO DO THE SAME. FOR THIS
                          REASON, WE ASK THAT YOU NOT POST ANY UGC THAT YOU DO
                          NOT WISH TO LICENSE TO US, INCLUDING ANY PHOTOGRAPHS,
                          VIDEOS, CONFIDENTIAL INFORMATION, OR PRODUCT IDEAS.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          ANY UGC THAT INCORPORATES ANY SITE OWNER CONTENT
                          CONTINUES TO BE OWED BY SITE OWNER SUBJECT TO THE
                          TERMS OF THESE TERMS OF USE.{" "}
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WE MAY DISCLOSE AND/OR REMOVE USER-GENERATED CONTENT.
                          THE SITE OWNER HAS CERTAIN RIGHTS. WE HAVE THE RIGHT
                          (BUT DO NOT ASSUME THE OBLIGATION) TO:
                        </span>
                      </i>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        monitor all UGC;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        require that you avoid certain subjects;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        remove or block any UGC at any time without notice at
                        our sole and absolute discretion;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        disclose any UGC and the identity of the user who posted
                        it in response to a subpoena or whenever we believe that
                        disclosure is appropriate to comply with the law or a
                        court order, to prevent or investigate a possible crime
                        or other violation of law, to protect the rights of the
                        Site Owner&nbsp; or others, or to enforce these Terms of
                        Use; and
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12.0pt",
                      marginLeft: ".5in",
                      textIndent: "-.25in",
                    }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        terminate your access to and use of this Site, or to
                        modify, edit or block your transmissions thereto in our
                        sole discretion.
                      </span>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU AGREE THAT OUR EXERCISE OF SUCH DISCRETION SHALL
                          NOT RENDER US THE OWNERS OF UGC YOU POST, AND THAT YOU
                          WILL RETAIN OWNERSHIP THEREOF AS DESCRIBED ABOVE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          RESTRICTIONS ON USER-GENERATED CONTENT. IT IS A
                          CONDITION OF THESE TERMS OF USE THAT YOU DO NOT:
                        </span>
                      </i>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        upload, post, transmit or otherwise make availableany
                        UGC that is unlawful, harmful, hateful, threatening,
                        abusive, harassing, libelous, defamatory, obscene,
                        vulgar, pornographic, profane, racially disparaging,
                        indecent, or invasive of another’s privacy;any UGC that
                        constitutes or encourages activity illegal under
                        criminal or civil law;any UGC that is false, misleading,
                        or fraudulent;any UGC that you do not have a right to
                        make available under any law or under contractual or
                        fiduciary relationships (such as inside information or
                        proprietary and confidential information learned or
                        disclosed as part of employment relationships or under
                        nondisclosure agreements);any UGC that violates or
                        infringes upon the rights of others, including UGC which
                        violates the patent rights, copyrights, trademark
                        rights, privacy rights, publicity rights, trade secret
                        rights, confidentiality rights, contract rights, or any
                        other rights of any individual, living or deceased, or
                        any legal entity;any UGC that contains the image, name
                        or likeness of anyone other than yourself, unless (i)
                        that person is at least eighteen years old and you have
                        first obtained his/her express permission or (ii) that
                        person is under eighteen years old but you are his/her
                        parent or legal guardian;any request for or solicitation
                        of any personal or private information from any
                        individual;any request for or solicitation of money,
                        goods, or services for private gain; any material that
                        contains software viruses or any other computer code,
                        files or programs designed to interrupt, destroy or
                        limit the functionality of any computer software or
                        hardware or telecommunications equipment; orany UGC that
                        contains advertising, promotions or marketing, or which
                        otherwise has a commercial purpose;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that is unlawful, harmful, hateful, threatening,
                        abusive, harassing, libelous, defamatory, obscene,
                        vulgar, pornographic, profane, racially disparaging,
                        indecent, or invasive of another’s privacy;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that constitutes or encourages activity illegal
                        under criminal or civil law;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that is false, misleading, or fraudulent;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that you do not have a right to make available
                        under any law or under contractual or fiduciary
                        relationships (such as inside information or proprietary
                        and confidential information learned or disclosed as
                        part of employment relationships or under nondisclosure
                        agreements);
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that violates or infringes upon the rights of
                        others, including UGC which violates the patent rights,
                        copyrights, trademark rights, privacy rights, publicity
                        rights, trade secret rights, confidentiality rights,
                        contract rights, or any other rights of any individual,
                        living or deceased, or any legal entity;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that contains the image, name or likeness of
                        anyone other than yourself, unless (i) that person is at
                        least eighteen years old and you have first obtained
                        his/her express permission or (ii) that person is under
                        eighteen years old but you are his/her parent or legal
                        guardian;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any request for or solicitation of any personal or
                        private information from any individual;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any request for or solicitation of money, goods, or
                        services for private gain;{" "}
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any material that contains software viruses or any other
                        computer code, files or programs designed to interrupt,
                        destroy or limit the functionality of any computer
                        software or hardware or telecommunications equipment; or
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        any UGC that contains advertising, promotions or
                        marketing, or which otherwise has a commercial purpose;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        impersonate any person or entity or falsely state or
                        otherwise misrepresent your affiliation with a person or
                        entity; or
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12.0pt",
                      marginLeft: ".5in",
                      textIndent: "-.25in",
                    }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        violate any local, state, national or international law,
                        rule or regulation.
                      </span>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          BY POSTING USER-GENERATED CONTENT, YOU REPRESENT AND
                          WARRANT THAT (I) YOU OWN OR OTHERWISE CONTROL ALL OF
                          THE RIGHTS TO THE UGC AND HAVE THE RIGHT TO GRANT THE
                          LICENSE SET FORTH IN THESE TERMS OF USE; (II) THE UGC
                          IS ACCURATE, AND (III) YOU HAVE READ AND
                          UNDERSTOOD—AND YOUR UGC FULLY COMPLIES WITH—THESE
                          TERMS OF USE AND APPLICABLE LAWS AND WILL NOT CAUSE
                          INJURY TO ANY PERSON OR ENTITY.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Removal of Content
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IN GENERAL. YOU CAN SEEK REMOVAL OF OBJECTIONABLE UGC
                          BY CONTACTING US THROUGH THE SUPPORT CENTER.
                        </span>
                      </i>
                    </b>

                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          . WE WILL REVIEW ALL SUCH REQUESTS AND WILL REMOVE UGC
                          THAT WE DETERMINE SHOULD BE REMOVED, IN OUR SOLE
                          DISCRETION AND IN ACCORDANCE WITH THESE TERMS OF USE
                          AND APPLICABLE LAW. PLEASE BE AWARE, HOWEVER, THAT IF
                          THE UGC HAS ALREADY BEEN DISTRIBUTED TO OTHER WEBSITES
                          OR PUBLISHED IN OTHER MEDIA, WE WILL NOT BE ABLE TO
                          RECAPTURE AND DELETE IT. ALSO, A BACK-UP OR RESIDUAL
                          COPY OF THE UGC WE REMOVE FROM THIS SITE MAY REMAIN ON
                          BACK-UP SERVERS.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          VIOLATION OF COPYRIGHTS. THE SITE OWNER&nbsp; DOES NOT
                          KNOWINGLY VIOLATE OR PERMIT OTHERS TO VIOLATE THE
                          COPYRIGHTS OF OTHERS. WE WILL PROMPTLY REMOVE OR
                          DISABLE ACCESS TO MATERIAL THAT WE KNOW IS INFRINGING
                          OR IF WE BECOME AWARE OF CIRCUMSTANCES FROM WHICH
                          INFRINGING ACTIVITY IS APPARENT.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IF YOU ARE REQUESTING REMOVAL OF CONTENT BECAUSE OF A
                          VIOLATION OF YOUR COPYRIGHTS, PLEASE NOTE THAT THE
                          DIGITAL MILLENNIUM COPYRIGHT ACT OF 1998 (THE “DMCA”)
                          PROVIDES RECOURSE FOR COPYRIGHT OWNERS WHO BELIEVE
                          THAT MATERIAL APPEARING ON THE INTERNET INFRINGES
                          THEIR RIGHTS UNDER U.S. COPYRIGHT LAW. IF YOU BELIEVE
                          THAT YOUR OWN WORK, OR THE WORK OF A THIRD PARTY FOR
                          WHOM YOU ARE AUTHORIZED TO ACT, IS FEATURED ON THIS
                          SITE OR HAS BEEN OTHERWISE COPIED AND MADE AVAILABLE
                          ON THIS SITE IN A MANNER THAT CONSTITUTE COPYRIGHT
                          INFRINGEMENT, PLEASE NOTIFY US IMMEDIATELY. YOUR
                          NOTICE MUST BE IN WRITING AND MUST INCLUDE
                        </span>
                      </i>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        an electronic or physical signature of the copyright
                        owner or of the person authorized to act on behalf of
                        the owner of the copyright interest;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        a description of the copyrighted work that you claim has
                        been infringed;
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        a description of where the material that you claim is
                        infringing is located on this Site (including the URL,
                        title and/or item number if applicable, or other
                        identifying characteristics);
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        your name, address, telephone number, and email address,
                        and, if you are not the owner of the copyright, the name
                        of the owner; and
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginLeft: ".5in", textIndent: "-.25in" }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        a written statement by you that you have a good-faith
                        belief that the disputed use is not authorized by the
                        copyright owner, its agent, or the law; and
                      </span>
                    </b>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12.0pt",
                      marginLeft: ".5in",
                      textIndent: "-.25in",
                    }}
                  >
                    <span
                      lang="EN"
                      style={{
                        fontSize: "9.0pt",
                        lineHeight: "115%",
                        color: "#555555",
                      }}
                    >
                      ●
                      <span style={{ font: '7.0pt "Times New Roman"' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </span>
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "9.0pt",
                          lineHeight: "115%",
                          color: "#555555",
                        }}
                      >
                        a statement by you, made under penalty of perjury, that
                        the above information in your notice is accurate and
                        that you are the copyright owner or authorized to act on
                        the copyright owner's behalf.
                      </span>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          ANY NOTIFICATION BY A COPYRIGHT OWNER OR A PERSON
                          AUTHORIZED TO ACT ON ITS BEHALF THAT FAILS TO COMPLY
                          WITH REQUIREMENTS OF THE DMCA SHALL NOT BE CONSIDERED
                          SUFFICIENT NOTICE AND SHALL NOT BE DEEMED TO CONFER
                          UPON US ACTUAL KNOWLEDGE OF FACTS OR CIRCUMSTANCES
                          FROM WHICH INFRINGING MATERIAL OR ACTS ARE EVIDENT.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Your Feedback
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          ALTHOUGH WE DO NOT CLAIM OWNERSHIP OF USER-GENERATED
                          CONTENT YOU POST USING THIS SITE, THE FEEDBACK YOU
                          PROVIDE TO US THROUGH THIS SITE WILL BE AND REMAIN OUR
                          EXCLUSIVE PROPERTY. YOUR SUBMISSION OF FEEDBACK WILL
                          CONSTITUTE AN ASSIGNMENT TO US OF ALL WORLDWIDE
                          RIGHTS, TITLE AND INTERESTS IN YOUR FEEDBACK,
                          INCLUDING ALL COPYRIGHTS AND OTHER INTELLECTUAL
                          PROPERTY RIGHTS IN YOUR FEEDBACK. WE WILL BE ENTITLED
                          TO REDUCE TO PRACTICE, EXPLOIT, MAKE, USE, COPY,
                          DISCLOSE, DISPLAY OR PERFORM PUBLICLY, DISTRIBUTE,
                          IMPROVE AND MODIFY ANY FEEDBACK YOU SUBMIT FOR ANY
                          PURPOSE WHATSOEVER, WITHOUT RESTRICTION AND WITHOUT
                          COMPENSATING YOU IN ANY WAY. FOR THIS REASON, WE ASK
                          THAT YOU NOT SEND US ANY FEEDBACK THAT YOU DO NOT WISH
                          TO ASSIGN TO US.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Your Obligations
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IN CONSIDERATION OF YOUR USE OF THIS SITE, YOU AGREE
                          THAT TO THE EXTENT YOU PROVIDE PERSONAL INFORMATION TO
                          THE SITE OWNER&nbsp; IT WILL BE TRUE, ACCURATE,
                          CURRENT, AND COMPLETE AND THAT YOU WILL UPDATE ALL
                          PERSONAL INFORMATION AS NECESSARY.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          TO THE EXTENT YOU CREATE AN ACCOUNT THROUGH THIS SITE,
                          YOU UNDERSTAND AND AGREE THAT ANY ACCOUNT YOU CREATE,
                          INCLUDING YOUR USERNAME AND PASSWORD, ARE PERSONAL TO
                          YOU AND MAY NOT BE USED BY ANYONE ELSE. YOU ARE
                          RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF
                          YOUR USERNAME AND PASSWORD AND ARE FULLY RESPONSIBLE
                          FOR ALL ACTIVITIES THAT OCCUR UNDER YOUR USERNAME AND
                          PASSWORD BY YOU OR BY ANYONE ELSE USING YOUR USERNAME
                          AND PASSWORD, WHETHER OR NOT AUTHORIZED BY YOU. YOU
                          AGREE TO CHANGE YOUR PASSWORD IMMEDIATELY IF YOU
                          BELIEVE YOUR PASSWORD MAY HAVE BEEN COMPROMISED OR
                          USED WITHOUT AUTHORIZATION. YOU ALSO AGREE TO
                          IMMEDIATELY INFORM US OF ANY APPARENT BREACHES OF
                          SECURITY SUCH AS LOSS, THEFT OR UNAUTHORIZED
                          DISCLOSURE OR USE OF YOUR USERNAME OR PASSWORD BY
                          CONTACTING US THROUGH THE SUPPORT CENTER.
                        </span>
                      </i>
                    </b>

                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          . UNTIL WE ARE SO NOTIFIED YOU WILL REMAIN LIABLE FOR
                          ANY UNAUTHORIZED USE OF YOUR ACCOUNT.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU AGREE TO USE THIS SITE IN A MANNER CONSISTENT WITH
                          ANY AND ALL APPLICABLE RULES AND REGULATIONS. YOU
                          AGREE NOT TO UPLOAD OR TRANSMIT THROUGH THIS SITE ANY
                          COMPUTER VIRUSES, TROJAN HORSES, WORMS OR ANYTHING
                          ELSE DESIGNED TO INTERFERE WITH, INTERRUPT OR DISRUPT
                          THE NORMAL OPERATING PROCEDURES OF A COMPUTER. ANY
                          UNAUTHORIZED MODIFICATION, TAMPERING OR CHANGE OF ANY
                          INFORMATION, OR ANY INTERFERENCE WITH THE AVAILABILITY
                          OF OR ACCESS TO THIS SITE IS STRICTLY PROHIBITED. WE
                          RESERVE ALL RIGHTS AND REMEDIES AVAILABLE TO US.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        DISCLAIMERS
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT
                          TO THIS SITE OR ITS CONTENT, OR ANY PRODUCT OR SERVICE
                          AVAILABLE ON OR PROMOTED THROUGH THIS SITE. THIS SITE
                          AND ALL OF ITS CONTENT (INCLUDING USER-GENERATED
                          CONTENT) ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE”
                          BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY
                          KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, THE SITE
                          OWNER , ITS AFFILIATES, AND THEIR SERVICE PROVIDERS
                          AND LICENSORS DISCLAIM ANY AND ALL REPRESENTATIONS AND
                          WARRANTIES, WHETHER EXPRESS, IMPLIED, ARISING BY
                          STATUTE, CUSTOM, COURSE OF DEALING, COURSE OF
                          PERFORMANCE OR IN ANY OTHER WAY, WITH RESPECT TO THIS
                          SITE, ITS CONTENT, AND ANY PRODUCTS OR SERVICES
                          AVAILABLE OR PROMOTED THROUGH THIS SITE. WITHOUT
                          LIMITING THE GENERALITY OF THE FOREGOING, THE SITE
                          OWNER, ITS AFFILIATES, AND THEIR SERVICE PROVIDERS AND
                          LICENSORS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES
                          (A) OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
                          FITNESS FOR A PARTICULAR PURPOSE; (B) RELATING TO THE
                          SECURITY OF THIS SITE; (C) THAT THE CONTENT OF THIS
                          SITE IS ACCURATE, COMPLETE OR CURRENT; OR (D) THAT
                          THIS SITE WILL OPERATE SECURELY OR WITHOUT
                          INTERRUPTION OR ERROR.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WE DO NOT REPRESENT OR WARRANT THAT THIS SITE, ITS
                          SERVERS, OR ANY TRANSMISSIONS SENT FROM US OR THROUGH
                          THIS SITE WILL BE FREE OF ANY HARMFUL COMPONENTS
                          (INCLUDING VIRUSES).
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE SITE OWNER DOES NOT ENDORSE AND IS NOT RESPONSIBLE
                          FOR STATEMENTS, ADVICE AND OPINIONS MADE BY ANYONE
                          OTHER THAN AUTHORIZED SITE OWNER SPOKESPERSONS. WE DO
                          NOT ENDORSE AND ARE NOT RESPONSIBLE FOR ANY
                          STATEMENTS, ADVICE OR OPINIONS CONTAINED IN
                          USER-GENERATED CONTENT AND SUCH STATEMENTS, ADVICE AND
                          OPINIONS DO NOT IN ANY WAY REFLECT THE STATEMENTS,
                          ADVICE AND OPINIONS OF THE SITE OWNER. WE DO NOT MAKE
                          ANY REPRESENTATIONS OR WARRANTIES AGAINST THE
                          POSSIBILITY OF DELETION, MISDELIVERY OR FAILURE TO
                          STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER
                          DATA. YOU ACCEPT THAT OUR SHAREHOLDERS, OWNERS,
                          OFFICERS, DIRECTORS, EMPLOYEES AND OTHER
                          REPRESENTATIVES SHALL HAVE THE BENEFIT OF THIS CLAUSE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF CERTAIN
                          WARRANTIES, SO ALL OR PART OF THIS DISCLAIMER OF
                          WARRANTIES MAY NOT APPLY TO YOU.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "115%",
                          fontFamily: "Roboto",
                        }}
                      >
                        PAYMENT
                      </span>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <span lang="EN" style={{ fontFamily: "Roboto" }}>
                      &nbsp;
                    </span>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          BY PROVIDING A CREDIT CARD OR OTHER "PAYMENT METHOD"
                          ACCEPTED BY THE SITE OWNER FOR YOUR SUBSCRIPTION OR A
                          SINGLE GAME, YOU ARE EXPRESSLY AGREEING THAT WE ARE
                          AUTHORIZED TO CHARGE YOU A MONTHLY OR YEARLY
                          SUBSCRIPTION FEE (DEPENDING ON THE NATURE OF THE
                          SUBSCRIPTION YOU SELECT),&nbsp; FOR A SINGLE GAME ANY
                          OTHER FEES FOR ADDITIONAL SERVICES YOU MAY PURCHASE,
                          AND ANY APPLICABLE TAXES IN CONNECTION WITH YOUR USE
                          OF YOUR SUBSCRIPTION OR SINGLE GAME TO THE PAYMENT
                          METHOD. IF YOU WANT TO USE A DIFFERENT PAYMENT METHOD
                          THAN THE ONE YOU SIGNED UP TO USE DURING REGISTRATION,
                          OR IF THERE IS A CHANGE IN YOUR CREDIT CARD VALIDITY
                          OR EXPIRATION DATE, YOU MAY EDIT YOUR PAYMENT METHOD
                          INFORMATION BY LOGGING IN AND MAKING THE CHANGE. WHEN
                          YOU PROVIDE A PAYMENT METHOD TO ACCESS A SUBSCRIPTION
                          OR SINGLE GAME, INCLUDING IN CONNECTION WITH A FREE
                          TRIAL OFFER, OUR SYSTEM WILL ATTEMPT TO VERIFY THE
                          INFORMATION YOU ENTERED. WE DO THIS BY PROCESSING AN
                          AUTHORIZATION HOLD, WHICH IS A STANDARD PRACTICE. WE
                          DO NOT CHARGE YOU IN CONNECTION WITH THIS
                          AUTHORIZATION HOLD. IF YOUR PAYMENT METHOD EXPIRES AND
                          YOU DO NOT EDIT YOUR PAYMENT METHOD INFORMATION OR
                          CANCEL YOUR ACCOUNT, YOU AUTHORIZE US TO CONTINUE
                          BILLING, AND YOU WILL REMAIN RESPONSIBLE FOR ANY
                          UNCOLLECTED AMOUNTS. AS USED IN THIS TERMS OF USE,
                          "BILLING" SHALL INDICATE EITHER A CHARGE OR DEBIT, AS
                          APPLICABLE, AGAINST YOUR PAYMENT METHOD. THE
                          SUBSCRIPTION FEE WILL BE BILLED AT THE BEGINNING OF
                          YOUR SUBSCRIPTION OR EXPIRATION OF YOUR FREE TRIAL
                          PERIOD, IF ANY, WHICHEVER IS EARLIER, AND ON EACH
                          MONTHLY OR YEARLY RENEWAL DATE (DEPENDING ON THE
                          NATURE OF THE SUBSCRIPTION YOU SELECT) THEREAFTER
                          UNLESS AND UNTIL YOU CANCEL YOUR SUBSCRIPTION OR THE
                          ACCOUNT OR SERVICE IS OTHERWISE SUSPENDED OR
                          DISCONTINUED PURSUANT TO THIS TERMS OF USE. TO SEE THE
                          COMMENCEMENT DATE FOR YOUR NEXT RENEWAL PERIOD, LOG
                          IN.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "115%",
                          fontFamily: "Roboto",
                        }}
                      >
                        AUTOMATIC RENEWALS AND CANCELLATIONS
                      </span>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WE AUTOMATICALLY BILL THE SUBSCRIPTION FEE TO YOUR
                          PAYMENT METHOD EACH MONTH OR YEAR ON THE CALENDAR DAY
                          CORRESPONDING TO THE COMMENCEMENT OF YOUR SUBSCRIPTION
                          (EACH SUCH MONTH, A "MONTHLY PERIOD", OR YEAR, A
                          "YEARLY PERIOD"). IN THE EVENT YOUR SUBSCRIPTION BEGAN
                          ON A DAY NOT CONTAINED IN A GIVEN MONTH, WE BILL YOUR
                          PAYMENT METHOD ON THE LAST DAY OF SUCH MONTH. FOR
                          EXAMPLE, IF YOU BECAME A PAYING MONTHLY SUBSCRIBER ON
                          JANUARY 31, YOUR PAYMENT METHOD WOULD NEXT BE BILLED
                          ON FEBRUARY 28. IF YOU CHANGE YOUR PAYMENT METHOD,
                          THIS COULD RESULT IN CHANGING THE CALENDAR DAY UPON
                          WHICH YOU ARE BILLED. YOU ACKNOWLEDGE THAT THE AMOUNT
                          BILLED EACH MONTHLY PERIOD OR YEARLY PERIOD MAY VARY
                          DUE TO PROMOTIONAL OFFERS, CHANGES IN YOUR
                          SUBSCRIPTION, AND CHANGES IN APPLICABLE TAXES, AND YOU
                          AUTHORIZE US TO CHARGE YOUR PAYMENT METHOD FOR THE
                          CORRESPONDING AMOUNTS. IF THE SITE OWNER CHANGES THE
                          SUBSCRIPTION FEE OR OTHER CHARGES FOR YOUR
                          SUBSCRIPTION, WE WILL GIVE YOU ADVANCE NOTICE OF THESE
                          CHANGES BY EMAIL. HOWEVER, WE WILL NOT BE ABLE TO
                          NOTIFY YOU OF CHANGES IN ANY APPLICABLE TAXES.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOUR SUBSCRIPTION WILL CONTINUE IN EFFECT ON A
                          MONTH-TO-MONTH OR YEAR-TO-YEAR BASIS UNLESS AND UNTIL
                          YOU CANCEL YOUR SUBSCRIPTION OR THE ACCOUNT OR SERVICE
                          IS OTHERWISE SUSPENDED OR DISCONTINUED PURSUANT TO
                          THIS TERMS OF USE. YOU MUST CANCEL YOUR SUBSCRIPTION
                          BEFORE YOUR MONTHLY OR YEARLY RENEWAL DATE IN ORDER TO
                          AVOID CHARGES FOR THE NEXT BILLING (EACH MONTHLY
                          PERIOD OR YEARLY PERIOD). WE WILL BILL THE MONTHLY OR
                          YEARLY SUBSCRIPTION FEE PLUS ANY APPLICABLE TAXES TO
                          THE PAYMENT METHOD YOU PROVIDE TO US DURING
                          REGISTRATION (OR TO A DIFFERENT PAYMENT METHOD IF YOU
                          CHANGE YOUR ACCOUNT INFORMATION). IF YOU CANCEL YOUR
                          SUBSCRIPTION, CANCELLATION WILL BE EFFECTIVE AT THE
                          END OF THE CURRENT MONTHLY PERIOD OR YEARLY PERIOD.
                          THIS MEANS THAT YOU WILL HAVE CONTINUED ACCESS TO YOUR
                          SUBSCRIPTION FOR THE REMAINDER OF THAT PERIOD, BUT YOU
                          WILL NOT RECEIVE A REFUND.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          ALL SALES ARE FINAL. THERE ARE NO REFUNDS UNDER ANY
                          CIRCUMSTANCES.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU CAN CANCEL YOUR ACCOUNT AT ANY TIME THROUGH YOUR
                          ACCOUNT SETTINGS. TO CANCEL YOUR SUBSCRIPTION, PLEASE
                          LOG IN TO BE DIRECTED TO YOUR ACCOUNT PAGE
                          SUBSCRIPTION DETAILS. CLICK THE BUTTON TO COMPLETE THE
                          CANCELLATION. IF THERE ARE SPECIAL CIRCUMSTANCES WHERE
                          THE SITE OWNER DETERMINES IT IS APPROPRIATE (E.G.,
                          YOUR SUBSCRIPTION SERVICE IS UNAVAILABLE FOR DAYS DUE
                          TO TECHNICAL DIFFICULTIES), WE MAY PROVIDE CREDITS TO
                          AFFECTED SUBSCRIBERS. THE AMOUNT AND FORM OF SUCH
                          CREDITS, AND THE DECISION TO PROVIDE THEM, ARE AT SITE
                          OWNER’S SOLE AND ABSOLUTE DISCRETION, AND THE
                          PROVISION OF CREDITS IN ONE INSTANCE DOES NOT ENTITLE
                          ANYONE TO CREDITS IN THE FUTURE UNDER SIMILAR OR
                          DIFFERENT CIRCUMSTANCES.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        LIMITATION OF LIABILITY
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS WE,
                          ON BEHALF OF OUR MEMBERS, MANAGERS, DIRECTORS,
                          OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, LICENSORS AND
                          SERVICE PROVIDERS, EXCLUDE AND DISCLAIM LIABILITY FOR
                          ANY LOSSES AND EXPENSES OF WHATEVER NATURE AND
                          HOWSOEVER ARISING INCLUDING, WITHOUT LIMITATION, ANY
                          DIRECT, INDIRECT, GENERAL, SPECIAL, PUNITIVE,
                          INCIDENTAL OR CONSEQUENTIAL DAMAGES; LOSS OF USE; LOSS
                          OF DATA; LOSS CAUSED BY A VIRUS; LOSS OF INCOME OR
                          PROFIT; LOSS OF OR DAMAGE TO PROPERTY; CLAIMS OF THIRD
                          PARTIES; OR OTHER LOSSES OF ANY KIND OR CHARACTER,
                          EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                          SUCH DAMAGES OR LOSSES, ARISING OUT OF OR IN
                          CONNECTION WITH THE USE OF THIS SITE. YOU ASSUME TOTAL
                          RESPONSIBILITY FOR ESTABLISHING SUCH PROCEDURES FOR
                          DATA BACK UP AND VIRUS CHECKING AS YOU CONSIDER
                          NECESSARY. THIS LIMITATION OF LIABILITY APPLIES
                          WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT,
                          TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR ANY
                          OTHER BASIS.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IF ANY PART OF THIS LIMITATION ON LIABILITY IS FOUND
                          TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN
                          THE AGGREGATE LIABILITY OF THE RELEASED PARTIES FOR
                          LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED
                          SHALL NOT EXCEED TEN DOLLARS ($10.00).
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THIS SITE GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY
                          ALSO HAVE OTHER RIGHTS WHICH VARY FROM COUNTRY TO
                          COUNTRY. SOME JURISDICTIONS DO NOT ALLOW CERTAIN KINDS
                          OF LIMITATIONS OR EXCLUSIONS OF LIABILITY, SO THE
                          LIMITATIONS AND EXCLUSIONS SET OUT IN THESE TERMS OF
                          USE MAY NOT APPLY TO YOU. OTHER JURISDICTIONS ALLOW
                          LIMITATIONS AND EXCLUSIONS SUBJECT TO CERTAIN
                          CONDITIONS. IN SUCH A CASE THE LIMITATIONS AND
                          EXCLUSIONS SET OUT IN THESE TERMS OF USE SHALL APPLY
                          TO THE FULLEST EXTENT PERMITTED BY THE LAWS OF SUCH
                          APPLICABLE JURISDICTIONS. YOUR STATUTORY RIGHTS AS A
                          CONSUMER, IF ANY, ARE NOT AFFECTED BY THESE
                          PROVISIONS, AND WE DO NOT SEEK TO EXCLUDE OR LIMIT
                          LIABILITY FOR FRAUDULENT MISREPRESENTATION.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Links to Third-Party Websites
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THIS SITE MAY PROVIDE LINKS TO OTHER WEBSITES OPERATED
                          BY THIRD PARTIES. BECAUSE WE HAVE NO CONTROL OVER
                          THIRD-PARTY WEBSITES, WE ARE NOT RESPONSIBLE FOR THE
                          AVAILABILITY OF THOSE WEBSITES AND DO NOT ENDORSE AND
                          ARE NOT RESPONSIBLE OR LIABLE FOR ANY CONTENT,
                          ADVERTISING, SERVICES, PRODUCTS, OR OTHER MATERIALS ON
                          OR AVAILABLE FROM SUCH WEBSITES.THE SITE OWNER SHALL
                          NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY,
                          FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED
                          BY OR IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY
                          CONTENT, ADVERTISING, SERVICES, PRODUCTS, OR OTHER
                          MATERIALS ON OR AVAILABLE FROM SUCH WEBSITES. THESE
                          TERMS OF USE DO NOT APPLY TO YOUR USE OF THIRD-PARTY
                          WEBSITES; YOUR USE OF SUCH WEBSITES IS SUBJECT TO THE
                          TERMS AND POLICIES OF THE OWNER OF SUCH WEBSITES.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Modification and Discontinuation
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          WE RESERVE THE RIGHT AT ANY TIME AND FROM TIME-TO-TIME
                          TO MODIFY, EDIT, DELETE, SUSPEND OR DISCONTINUE,
                          TEMPORARILY OR PERMANENTLY THIS SITE (OR ANY PORTION
                          THEREOF) AND/OR THE INFORMATION, MATERIALS, PRODUCTS
                          AND/OR SERVICES AVAILABLE THROUGH THIS SITE (OR ANY
                          PART THEREOF) WITH OR WITHOUT NOTICE. YOU AGREE THAT
                          WE SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY
                          FOR ANY SUCH MODIFICATION, EDITING, DELETION,
                          SUSPENSION OR DISCONTINUANCE OF THIS SITE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Waiver
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          OUR FAILURE AT ANY TIME TO REQUIRE PERFORMANCE OF ANY
                          PROVISION OF THESE TERMS OF USE OR TO EXERCISE ANY
                          RIGHT PROVIDED FOR HEREIN WILL NOT BE DEEMED A WAIVER
                          OF SUCH PROVISION OR SUCH RIGHT. ALL WAIVERS MUST BE
                          IN WRITING. UNLESS THE WRITTEN WAIVER CONTAINS AN
                          EXPRESS STATEMENT TO THE CONTRARY, NO WAIVER BY THE
                          SITE OWNER OF ANY BREACH OF ANY PROVISION OF THESE
                          TERMS OF USE OR OF ANY RIGHT PROVIDED FOR HEREIN WILL
                          BE CONSTRUED AS A WAIVER OF ANY CONTINUING OR
                          SUCCEEDING BREACH OF SUCH PROVISION, A WAIVER OF THE
                          PROVISION ITSELF, OR A WAIVER OF ANY RIGHT UNDER THESE
                          TERMS OF USE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Severability
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          IF ANY PROVISION OF THESE TERMS OF USE IS HELD BY A
                          COURT OF COMPETENT JURISDICTION TO BE CONTRARY TO LAW,
                          SUCH PROVISION WILL BE CHANGED AND INTERPRETED SO AS
                          TO BEST ACCOMPLISH THE OBJECTIVES OF THE ORIGINAL
                          PROVISION TO THE FULLEST EXTENT ALLOWED BY LAW AND THE
                          REMAINING PROVISIONS OF THESE TERMS OF USE WILL REMAIN
                          IN FULL FORCE AND EFFECT.{" "}
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Governing Law, Jurisdiction and Venue
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THESE TERMS OF USE WILL BE GOVERNED UNDER THE LAWS OF
                          THE COMMONWEALTH OF MASSACHUSETTS WITHOUT REGARD TO
                          ITS CONFLICTS OF LAW PROVISIONS. ALL ACTIONS OR
                          PROCEEDINGS ARISING OUT OF OR RELATING TO THESE TERMS
                          OF USE WILL BE VENUED EXCLUSIVELY IN STATE OR FEDERAL
                          COURT IN THE CITY AND COUNTY OF SUFFOLK,
                          MASSACHUSETTS. YOU HEREBY IRREVOCABLY CONSENT AND
                          SUBMIT TO THE EXCLUSIVE PERSONAL JURISDICTION OF SAID
                          COURTS FOR ALL SUCH PURPOSES. HOWEVER, WE RETAIN THE
                          RIGHT TO BRING LEGAL PROCEEDINGS IN ANY JURISDICTION
                          WHERE WE BELIEVE THAT INFRINGEMENT OF THESE TERMS OF
                          USE IS TAKING PLACE OR ORIGINATING.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Indemnity
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          YOU AGREE TO INDEMNIFY, DEFEND AND HOLD THE SITE
                          OWNER, ITS SUBSIDIARIES, AND AFFILIATES, AND THEIR
                          RESPECTIVE MEMBERS, MANAGERS, DIRECTORS, OFFICERS,
                          AGENTS, PARTNERS AND EMPLOYEES, HARMLESS FROM ANY
                          LOSS, LIABILITY, CLAIM, OR DEMAND, INCLUDING
                          REASONABLE ATTORNEYS’ FEES, MADE BY ANY THIRD PARTY
                          DUE TO OR ARISING OUT OF YOUR USE OF THIS SITE IN
                          VIOLATION OF THESE TERMS OF USE AND/OR ARISING FROM A
                          BREACH OF THESE TERMS OF USE AND/OR ANY BREACH OF YOUR
                          REPRESENTATIONS AND WARRANTIES SET FORTH ABOVE AND/OR
                          IF ANY MATERIAL THAT YOU POST USING THIS SITE CAUSES
                          US TO BE LIABLE TO ANOTHER.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        These Terms of Use May Change
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THESE TERMS OF USE ARE CURRENT AS OF THE EFFECTIVE
                          DATE SET FORTH ABOVE. THE SITE OWNER&nbsp; RESERVES
                          THE RIGHT TO CHANGE THESE TERMS OF USE FROM TIME TO
                          TIME CONSISTENT WITH APPLICABLE LAWS AND PRINCIPLES.
                          THESE CHANGES WILL BE EFFECTIVE AS OF THE DATE WE POST
                          THE REVISED VERSION ON THIS SITE. YOUR CONTINUED USE
                          OF THIS SITE AFTER WE HAVE POSTED THE REVISED TERMS OF
                          USE CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THE
                          REVISED TERMS OF USE. IF AT ANY TIME YOU CHOOSE NOT TO
                          ACCEPT THESE TERMS OF USE, YOU SHOULD NOT USE THIS
                          SITE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Entire Agreement
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THESE TERMS OF USE (TOGETHER WITH OUR PRIVACY POLICY
                          AND ANY PRIVACY NOTICES OR CLICK-THROUGH AGREEMENTS
                          APPLICABLE TO YOU) CONTAIN THE ENTIRE UNDERSTANDING
                          AND AGREEMENT BETWEEN YOU AND THE SITE OWNER WITH
                          RESPECT TO THIS SITE AND SUPERSEDE ALL PREVIOUS
                          COMMUNICATIONS, NEGOTIATIONS, AND AGREEMENTS, WHETHER
                          ORAL, WRITTEN, OR ELECTRONIC, BETWEEN YOU AND THE SITE
                          OWNER WITH RESPECT TO THIS SITE AND YOUR USE OF THIS
                          SITE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <h3
                    style={{
                      marginTop: "8.0pt",
                      marginRight: "0in",
                      marginBottom: "8.0pt",
                      marginLeft: "0in",
                      lineHeight: "120%",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <a />
                    <b>
                      <span
                        lang="EN"
                        style={{
                          fontSize: "18.0pt",
                          lineHeight: "120%",
                          fontFamily: "Roboto",
                          color: "#555555",
                        }}
                      >
                        Definitions
                      </span>
                    </b>
                  </h3>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERM “CONTENT” REFERS TO ALL OF THE SOFTWARE AND
                          CODE COMPRISING OR USED TO OPERATE THIS SITE, AND ALL
                          OF THE TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS,
                          GRAPHICS, SOUND RECORDINGS, VIDEO AND AUDIO-VIDEO
                          CLIPS, AND OTHER MATERIALS AVAILABLE ON THIS SITE,
                          INCLUDING USER-GENERATED CONTENT AND FEEDBACK.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERM “FEEDBACK” REFERS TO THE CONTENT YOU POST ON
                          OR THROUGH THIS SITE THAT IS SPECIFICALLY ABOUT HOW WE
                          CAN IMPROVE THIS SITE AND THE PRODUCTS AND SERVICES WE
                          MAKE AVAILABLE THROUGH THIS SITE.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERM “INCLUDING” MEANS “INCLUDING, BUT NOT LIMITED
                          TO.”
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERM “SITE” REFERS TO ANY WEBSITE OWNED BY THE
                          SITE OWNER , ON WHICH THESE TERMS OF USE ARE POSTED.
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERMS “THE SITE OWNER ” “WE,” “US,” AND “OUR”
                          REFER TO SITE’S FEATURED LEAGUE, ASSOCIATION AND/OR
                          ITS AFFILIATES .
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          THE TERM “USER-GENERATED CONTENT” OR “UGC” MEANS ALL
                          OF THE TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS,
                          GRAPHICS, SOUNDS, VIDEO AND AUDIO-VIDEO CLIPS, AND
                          OTHER CONTENT YOU POST USING THE SOCIAL NETWORKING
                          TOOLS WE MAKE AVAILABLE TO YOU AND THAT DOES NOT
                          CONSTITUTE “FEEDBACK.”
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                    <b>
                      <i>
                        <span lang="EN" style={{ fontFamily: "Roboto" }}>
                          &nbsp;
                        </span>
                      </i>
                    </b>
                  </p>
                  <p className="MsoNormal">
                    <span lang="EN">&nbsp;</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermAndCondition;

import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';

import {post} from './Http';
import {joinUrls, noOp} from './Utils';

export class AnalyticsCall {
  private analyticsServerUrl: string;
  private adAnalyticsServerUrl: string;

  constructor(baseUrl: string) {
    this.analyticsServerUrl = joinUrls(baseUrl, 'analytics');
    this.adAnalyticsServerUrl = joinUrls(baseUrl, 'analytics/a');
  }

  sendRequest(sample: Sample, callback: () => void) {
    post(this.analyticsServerUrl, sample, callback);
  }

  sendUnloadRequest(sample: Sample) {
    this.trySendBeacon((data: Sample) => this.sendRequestSynchronous(data, noOp), this.getAnalyticsServerUrl(), sample);
  }

  trySendBeacon(fallbackFunction: (data: any) => void, url: string, data: any) {
    if (typeof navigator.sendBeacon === 'undefined') {
      fallbackFunction(data);
    } else {
      const success = navigator.sendBeacon(url, JSON.stringify(data));
      if (!success) {
        fallbackFunction(data);
      }
    }
  }

  sendRequestSynchronous(sample: Sample, callback: () => void) {
    post(this.analyticsServerUrl, sample, callback, false);
  }

  sendAdRequest(sample: AdSample) {
    this.trySendBeacon(
      (data: AdSample) => post(this.getAdAnalyticsServerUrl(), data, noOp),
      this.getAdAnalyticsServerUrl(),
      sample,
    );
  }

  getAnalyticsServerUrl() {
    return this.analyticsServerUrl;
  }

  getAdAnalyticsServerUrl() {
    return this.adAnalyticsServerUrl;
  }
}

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@mui/material";
import React from "react";
import useConfig from "../../../hooks/useConfig";
import PageRoutes from "../../../utils/constants";

const DialogBox = ({
  handleClose,
  open,
  content,
  buttonText,
  butttonTextSecondary,
  buttonAction,
  buttonActionSecondary,
  disabled = false,
}: any) => {
  const configData = useConfig();
  return (
    <Dialog
      open={open}
      fullWidth
      sx={{ width: 1 }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText
          sx={{ fontStyle: "normal", fontWeight: 600 }}
          id="alert-dialog-description"
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonText && (
          <Box sx={{ display: "block" }}>
            <button
              style={{ display: "block", fontSize: 14 }}
              className={configData.buttonCommonClass}
              onClick={() => buttonAction()}
              autoFocus
            >
              {buttonText}
            </button>
          </Box>
        )}

        {butttonTextSecondary && (
          <Box sx={{ display: "block" }}>
            <button
              disabled={disabled}
              style={{ display: "block", fontSize: 14 }}
              className={configData.buttonCommonClass}
              onClick={() => buttonActionSecondary()}
              autoFocus
            >
              {butttonTextSecondary}
            </button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default DialogBox;

import Hls from "hls.js";
import { PlayerDecorator } from "@sscale/syncsdk";
import { isSafariOriOS } from "../helper/device";
declare global {
  interface Window {
    sdkSeek: boolean;
  }
}
export class DemoHtmlPlayer extends PlayerDecorator {
  private static DEFAULT_PTS_FREQUENCY = 90000;

  private streamOffset = 0;
  private rememberedPosition = 0;
  private usingProgramDateTime = false;

  public load() {
    if (!Hls.isSupported() || !this.isLive()) {
      return;
    }

    const initPTS = this.player.hls.streamController.initPTS;
    if (initPTS[0]) {
      const streamOffset =
        (Math.abs(initPTS[0]) / DemoHtmlPlayer.DEFAULT_PTS_FREQUENCY) * 1000;
      this.streamOffset = Math.round(streamOffset);
    } else {
      this.player.hls.on(
        Hls.Events.INIT_PTS_FOUND,
        (event: any, eventData: any) => {
          if (eventData.initPTS > 0) {
            const streamOffset =
              (Math.abs(eventData.initPTS) /
                DemoHtmlPlayer.DEFAULT_PTS_FREQUENCY) *
              1000;
            this.streamOffset = Math.round(streamOffset);
          }
        }
      );
    }

    const fragPlaying = this.player.hls.streamController.fragPlaying;
    if (fragPlaying?.programDateTime) {
      this.usingProgramDateTime = true;

      this.rememberedPosition = fragPlaying.start;
      this.streamOffset = Math.round(fragPlaying?.programDateTime);
    }

    this.player.hls.on(
      Hls.Events.FRAG_CHANGED,
      (event: any, eventData: any) => {
        if (eventData.frag.programDateTime) {
          this.usingProgramDateTime = true;

          this.rememberedPosition = this.player.video.currentTime;
          this.streamOffset = Math.round(eventData.frag.programDateTime);
        }
      }
    );
  }

  public unload() {
    const hls = this.player.hls;
    hls.destroy();
  }

  private isLive() {
    try {
      const hls = this.player.hls;

      if (!hls.levels) {
        return false;
      }

      const level = hls.levels[hls.currentLevel];
      return !!level && level.details.live;
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  public getPrecisionThreshold() {
    // Improve experience with stuttering issue on iOS and Safari
    if (isSafariOriOS()) {
      return 350;
    }

    if (this.usingProgramDateTime) {
      return 50;
    }

    return 50;
  }

  public isStalled(): boolean {
    return this.player.video.readyState < this.player.video.HAVE_FUTURE_DATA;
  }

  public isSeekable(): boolean {
    return !this.isLive();
  }

  public play(): void {
    try {
      this.player.video.play();
    } catch (e) {
      console.error("Play error:", e);
    }
  }

  public pause(): void {
    try {
      this.player.video.pause();
    } catch (e) {
      console.error("Pause error:", e);
    }
  }

  public mute(): void {
    try {
      this.player.video.muted = true;
    } catch (e) {
      console.log("Mute error:", e);
    }
  }

  public unmute(): void {
    try {
      this.player.video.muted = false;
    } catch (e) {
      console.log("Unmute error:", e);
    }
  }

  public getCurrentPosition(): number {
    try {
      return Math.round(
        this.streamOffset +
          (this.player.video.currentTime - this.rememberedPosition) * 1000
      );
    } catch (e) {
      console.error("Get current position error:", e);
    }

    return 0;
  }

  public fastSeekToPosition(position: number) {
    window.sdkSeek = true;
    if (position != null) {
      const time =
        (position - this.streamOffset + this.rememberedPosition * 1000) / 1000;

      try {
        const start = this.player.video.seekable.start(0);
        const end = this.player.video.seekable.end(0);
        if (time <= end && time >= start) {
          this.player.video.currentTime = time;
        }
      } catch (e) {
        console.error("Fast seek error:", e);
      }
    }
  }

  public isPlaying(): boolean {
    try {
      return !this.player.video.paused;
    } catch (e) {
      console.error("Is playing error:", e);
    }

    return false;
  }

  public changePlaybackRate(rate: number): void {
    try {
      // Improve experience with stuttering issue on iOS and Safari
      if (isSafariOriOS()) {
        if (rate > 1) {
          rate = Math.ceil(rate * 10) / 10 + 0.3;
        }

        if (
          Math.ceil(rate * 100) / 100 ===
          Math.ceil(this.getPlaybackRate() * 100) / 100
        ) {
          return;
        }
      }

      this.player.video.playbackRate = rate;
    } catch (e) {
      console.error("Set playback rate error:", e);
    }
  }

  public getPlaybackRate(): number {
    try {
      return this.player.video.playbackRate;
    } catch (e) {
      console.error("Get playback rate error:", e);
    }

    return 0;
  }

  public setVolume(volume: number) {
    try {
      this.player.video.volume = volume;
    } catch (e) {
      console.error("Set volume error:", e);
    }
  }
}

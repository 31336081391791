import {VERSION} from './Version';
import {AmazonIVSAdapter} from './adapters/amazonIVS/AmazonIVSAdapter';
import {Bitmovin8Adapter} from './adapters/bitmovin8/Bitmovin8Adapter';
import {CAFv3Adapter} from './adapters/cafv3/CAFv3Adapter';
import {DashjsAdapter} from './adapters/dashjs/DashjsAdapter';
import {HlsAdapter} from './adapters/hlsjs/HlsAdapter';
import {HTMLVideoElementAdapter} from './adapters/html5/HTMLVideoElementAdapter';
import {ShakaAdapter} from './adapters/shaka/ShakaAdapter';
import {VideojsAdapter} from './adapters/videojs/VideoJsAdapter';
import {CdnProvider} from './enums/CDNProvider';
import {Player} from './enums/Player';
import {PlayerModule} from './playerModule';

export {VERSION as version};

export type {AnalyticsConfig} from './types/AnalyticsConfig';

export {Player as Players};
export {CdnProvider as CdnProviders};

export {AmazonIVSAdapter};
export {Bitmovin8Adapter};
export {CAFv3Adapter};
export {DashjsAdapter};
export {HTMLVideoElementAdapter};
export {HlsAdapter};
export {ShakaAdapter};
export {VideojsAdapter};

export const adapters = {
  AmazonIVSAdapter,
  Bitmovin8Adapter,
  CAFv3Adapter,
  DashjsAdapter,
  HTMLVideoElementAdapter,
  HlsAdapter,
  ShakaAdapter,
  VideojsAdapter,
};

export {PlayerModule};

export default {
  PlayerModule: PlayerModule,
};

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";
import useConfig from "../../hooks/useConfig";

const TSTable = (props: any) => {
  const configData = useConfig();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={{...CommonStyle.tableHeader,background:configData.primary}}
                  className="statview-header-color"
                  colSpan={2}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontStyle: "normal", color: "#ffffff" }}
                  >
                    {headItem.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData.length ? (
              props.listData?.map((row: any, index: number) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell
                      sx={{ py: 1, width: "50px" }}
                      align={props?.align ? props.align : "left"}
                    >
                      <Avatar
                        src={row.player_image}
                        style={{
                          width: "36px",
                          height: "36px",
                          textAlign: "center",
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ float: "left", px: 0, width: "82%" }}>
                      <Typography
                        sx={{ cursor: "pointer", fontSize: "12px" }}
                        variant="body2"
                      >
                        #{row.jersey} {row.player_name?.toUpperCase()}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "grey", fontSize: "12px" }}
                      >
                        ({row.goals}-{row?.assists}-{row?.points})
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <Typography variant="body2">No data Found</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TSTable;

import { TabList } from "@mui/lab";
import {
  Button,
  Container,
  Pagination,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import AlertBox from "../../Common/Component/AlertBox";
import DialogBox from "../../Common/Component/DialogBox";
import LoadingContext from "../../Common/Contex/LoadingContex";
import HttpService from "../../hooks/Https-services";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import AlertStyle from "./AlertPage.style";
import useConfig from "../../hooks/useConfig";
import AlertPopup from "./AlertPopup";
import { Link } from "react-router-dom";
import Loader from "../../Common/Component/Loader";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Alert = () => {
  const [logs, setLogs] = useState<any>([]);
  const [value, setValue] = useState<any>(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isRead, setIsRead] = useState(false);
  const configData = useConfig();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { setIsRefresh, isRefresh }: any = useContext(LoadingContext);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setIsLoading(true);
    setPage(value);
  };
  const handleLinkClick = (event: any) => {
    // Prevent the default behavior to stop the link from navigating in the same tab.
    event.preventDefault();
    // Open the link in a new tab using window.open.
    window.open(event.target.href, "_blank");
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setIsRead(false);
    } else {
      setIsRead(true);
    }
    setPage(1);
    setIsLoading(true);
    setValue(newValue);
  };
  const GetLogs = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetLogs}?page=${page}&isRead=${isRead}`
      );
      if (res) {
        setLogs(res.data.data?.logs);
        setTotalPage(res.data.data?.total_page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, isRead]);
  const handleUpdateStaus = async (el: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UpdateLogs.replace(":id", el.ID),
        {
          isRead: !el.IsRead,
        }
      );
      if (res) {
        GetLogs();
        setIsRefresh(!isRefresh);
      }
    } catch (error) {}
  };
  const handleUpdateStausAnnouceMent = async (
    el: any,
    isReadNotification: boolean
  ) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UpdateLogsAnnoucement.replace(":id", el?.AlertID || el?.ID),
        {
          isRead: isReadNotification,
        }
      );
      if (res) {
        GetLogs();
        setIsRefresh(!isRefresh);
      }
    } catch (error) {}
  };
  const handleMarkReadAll = async (el: any) => {
    try {
      const res = await HttpService.post(APIRoutes.MarkAllRead, {});
      // const res2 = await HttpService.post(APIRoutes.MarkAllReadAnnoucement, {});
      if (res) {
        GetLogs();
      }
      setIsRefresh(!isRefresh);
    } catch (error) {}
  };
  useEffect(() => {
    GetLogs();
  }, [GetLogs]);
  return (
    <Container
      maxWidth="xl"
      sx={{ minHeight: "calc( 100vh - 188px)", height: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          pt: 4,
          maxWidth: "700px",
          m: "auto",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "900px",
            flexDirection: "column",
            height: "100%",
            gap: 1,
            width: 1,
            pt: 4,
          }}
        >
          <Box>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Unread" {...a11yProps(0)} />
                  <Tab label="Read" {...a11yProps(1)} />
                </Tabs>
              </Box>
              {isLoading ? (
                <Loader />
              ) : (
                <Box>
                  <CustomTabPanel value={value} index={0}>
                    {logs.filter((el: any) => el.IsRead == false).length >
                      0 && (
                      <Box sx={{ textAlign: "end", my: 1 }}>
                        <Box>
                          <Button onClick={handleMarkReadAll} variant="text">
                            Mark all as read
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {logs?.length > 0 ? (
                      logs.map((el: any) =>
                        el?.NotificationType == 1 ? (
                          <Box
                            sx={{
                              ...AlertStyle.AlertBox,
                              bgcolor: "#000",
                              cursor: "pointer",
                              my: 1,
                            }}
                          >
                            <AlertPopup
                              el={el}
                              isReadNotification={true}
                              handleUpdateStausAnnouceMent={() =>
                                handleUpdateStausAnnouceMent(el, true)
                              }
                              handleLinkClick={(event: any) =>
                                handleLinkClick(event)
                              }
                            />
                            <Box>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  height: "14px",
                                  p: 0.25,
                                  width: "14px",
                                  border: "1px solid #DADADA",
                                  borderRadius: "50%",
                                }}
                              >
                                <Tooltip title={`Mark as Read`} placement="top">
                                  <Box
                                    onClick={() => {
                                      handleUpdateStausAnnouceMent(el, true);
                                    }}
                                    sx={{
                                      height: 1,
                                      width: 1,
                                      borderRadius: "50%",
                                      backgroundColor: true
                                        ? "#436AF3"
                                        : "transparent",
                                    }}
                                  ></Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={AlertStyle.AlertBox}>
                            <Box sx={{ maxWidth: "600px" }}>
                              <Typography variant="body2">
                                {el.AlertMessage} at{" "}
                                {moment(el?.createdAt).format("hh:mm A")} on{" "}
                                {moment(el?.createdAt).format("YYYY-MM-DD")}
                                {el?.GameID && (
                                  <Link
                                    to={PageRoutes.Watch.replace(
                                      ":gameId",
                                      el?.GameID
                                    )}
                                  >
                                    {" "}
                                    Watch Here
                                  </Link>
                                )}
                              </Typography>
                            </Box>

                            <Box>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  height: "14px",
                                  p: 0.25,
                                  width: "14px",
                                  border: "1px solid #DADADA",
                                  borderRadius: "50%",
                                }}
                              >
                                <Tooltip
                                  title={`Mark as ${
                                    el.IsRead ? "Unread" : "Read"
                                  }`}
                                  placement="top"
                                >
                                  <Box
                                    onClick={() => {
                                      handleUpdateStaus(el);
                                    }}
                                    sx={{
                                      height: 1,
                                      width: 1,
                                      borderRadius: "50%",
                                      backgroundColor: !el.IsRead
                                        ? "#436AF3"
                                        : "transparent",
                                    }}
                                  ></Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        )
                      )
                    ) : (
                      <Typography>
                        You do not currently have any alerts.
                      </Typography>
                    )}
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    {logs.map((el: any) =>
                      el?.NotificationType == 1 ? (
                        <Box
                          sx={{
                            ...AlertStyle.AlertBox,
                            bgcolor: "#000",
                            cursor: "pointer",
                            my: 1,
                          }}
                        >
                          <AlertPopup
                            isReadNotification={false}
                            handleUpdateStausAnnouceMent={() =>
                              handleUpdateStausAnnouceMent(el, false)
                            }
                            el={el}
                            handleLinkClick={(event: any) =>
                              handleLinkClick(event)
                            }
                          />
                          <Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                height: "14px",
                                p: 0.25,
                                width: "14px",
                                border: "1px solid #DADADA",
                                borderRadius: "50%",
                              }}
                            >
                              <Tooltip title={`Mark as Unread`} placement="top">
                                <Box
                                  onClick={() => {
                                    handleUpdateStausAnnouceMent(el, false);
                                  }}
                                  sx={{
                                    height: 1,
                                    width: 1,
                                    borderRadius: "50%",
                                    backgroundColor: false
                                      ? "#436AF3"
                                      : "transparent",
                                  }}
                                ></Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={AlertStyle.AlertBox}>
                          <Box sx={{ maxWidth: "600px" }}>
                            <Typography variant="body2">
                              {el.AlertMessage} at{" "}
                              {moment(el?.createdAt).format("hh:mm A")} on{" "}
                              {moment(el?.createdAt).format("YYYY-MM-DD")}
                            </Typography>
                          </Box>
                          <Box>
                            <Box
                              sx={{
                                cursor: "pointer",
                                height: "14px",
                                p: 0.25,
                                width: "14px",
                                border: "1px solid #DADADA",
                                borderRadius: "50%",
                              }}
                            >
                              <Tooltip
                                title={`Mark as ${
                                  el.IsRead ? "Unread" : "Read"
                                }`}
                                placement="top"
                              >
                                <Box
                                  onClick={() => {
                                    handleUpdateStaus(el);
                                  }}
                                  sx={{
                                    height: 1,
                                    width: 1,
                                    borderRadius: "50%",
                                    backgroundColor: !el.IsRead
                                      ? "#436AF3"
                                      : "transparent",
                                  }}
                                ></Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      )
                    )}
                  </CustomTabPanel>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        {totalPage !== 0 && (
          <Pagination
            count={totalPage}
            onChange={handleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
        )}
      </Box>
    </Container>
  );
};
export default Alert;

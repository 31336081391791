import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../../../../Common/Contex/LoadingContex";
import HttpService from "../../../../../hooks/Https-services";
import useConfig from "../../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import EditIcon from "@mui/icons-material/Edit";
const StatusBoxMedia = ({ data }: any) => {
  const configData = useConfig();
  const navigate = useNavigate();
  const { isRefresh, setIsRefresh }: any = useContext(LoadingContext);
  const handleUnpublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnpublishMedia.replace(":id", id),
        {}
      );
      setIsRefresh(!isRefresh);
    } catch (error) {}
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.PublishMedia.replace(":id", id),
        {}
      );
      setIsRefresh(!isRefresh);
    } catch (error) {}
  };
  const handelDeleteSlider = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteMedia.replace(":id", id),
        {}
      );
      setIsRefresh(!isRefresh);
    } catch (error) {}
  };
  return (
    <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-start" }}>
      <button
        disabled={data?.MediaStatus !== "COMPLETED"}
        onClick={() => {
          if (data?.IsActive) {
            handleUnpublish(data.ID);
          } else {
            handlePublish(data?.ID);
          }
        }}
        style={{
          cursor: "pointer",
          width: "200px",
        }}
        className={configData.buttonCommonClass}
      >
        {data?.IsActive ? "Move to Trash" : "Make Available"}
      </button>{" "}
      {/* <button
        onClick={() =>
          navigate(
            PageRoutes.EditSliderSetting.replace(":id", data?.row?.original?.ID)
          )
        }
        style={{
          cursor: "pointer",
          width: "50px",
        }}
        className={configData.buttonCommonClass}
      >
        <EditIcon />
      </button>
      <button
        onClick={() => handelDeleteSlider(data?.row?.original?.ID)}
        style={{
          cursor: "pointer",
          width: "50px",
        }}
        className={configData.buttonCommonClass}
      >
        <Delete />
      </button> */}
      <button
        onClick={() => handelDeleteSlider(data?.ID)}
        style={{
          cursor: "pointer",
          width: "50px",
        }}
        className={configData.buttonCommonClass}
      >
        <Delete />
      </button>
    </Box>
  );
};
export default StatusBoxMedia;

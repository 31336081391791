import { Delete } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import moment from "moment";
import React from "react";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import HttpService from "../../../../hooks/Https-services";
import useConfig from "../../../../hooks/useConfig";
import { useNavigate } from "react-router-dom";

const AlertTable = ({
  listOfNotification,
  GetListOfAnnoucement,
}: {
  listOfNotification: any[];
  GetListOfAnnoucement: () => void;
}) => {
  const configData = useConfig();
  const navigate = useNavigate();
  const handleUnpublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishNotification.replace(":id", id),
        {}
      );
      GetListOfAnnoucement();
    } catch (error) {}
  };
  const handlePublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.PublishNotification.replace(":id", id),
        {}
      );
      GetListOfAnnoucement();
    } catch (error) {}
  };
  const handelDeleteNotification = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteNotification.replace(":id", id),
        {}
      );
      GetListOfAnnoucement();
    } catch (error) {}
  };
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Message</TableCell>
              <TableCell>Published Date</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfNotification.map((row: any, index: any) => (
              <TableRow
                key={index + 1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell align="left">{row.NotificationMessage}</TableCell>
                <TableCell align="left">
                  {row?.AnnouncementDate
                    ? moment(row?.AnnouncementDate).format(
                        "DD MMM YYYY hh:mm A"
                      )
                    : "Not Available"}
                </TableCell>
                <TableCell align="right">
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      onClick={() => {
                        if (row?.Status) {
                          handleUnpublish(row?.ID);
                        } else {
                          handlePublish(row?.ID);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        width: "200px",
                      }}
                      className={configData.buttonCommonClass}
                    >
                      {row?.Status ? "Move to Trash" : "Make Available"}
                    </button>{" "}
                    <button
                      onClick={() =>
                        navigate(
                          PageRoutes.EditAnnouncement.replace(":id", row?.ID)
                        )
                      }
                      style={{
                        cursor: "pointer",
                        width: "50px",
                      }}
                      className={configData.buttonCommonClass}
                    >
                      <EditIcon />
                    </button>
                    <button
                      onClick={() => handelDeleteNotification(row?.ID)}
                      style={{
                        cursor: "pointer",
                        width: "50px",
                      }}
                      className={configData.buttonCommonClass}
                    >
                      <Delete />
                    </button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default AlertTable;

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import HttpService from "../../../hooks/Https-services";

import { APIRoutes } from "../../../utils/constants";

const DownloadPdfComponent = ({ row }: any) => {
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const downloadReceipt = async (receiptUrl: any, receiptName: any) => {
    try {
      setIsLoadingDownload(true);
      const response = await HttpService.postdoc(APIRoutes.DownloadPaymentPdf, {
        receiptUrl,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${receiptName ? receiptName : "Receipt"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoadingDownload(false);
    } catch (error: any) {
      setIsLoadingDownload(false);
      toast.error(error.response.data.message);
    }
  };
  return row?.invoice?.invoice_pdf ? (
    <Button
      href={row?.invoice?.invoice_pdf}
      target="_blank"
      sx={{ textDecoration: "none" }}
    >
      {row?.invoice?.receipt_number}{" "}
      {moment.unix(row?.payment?.created).format("DD MMM YYYY hh:mm A")}
    </Button>
  ) : (
    <LoadingButton
      loading={isLoadingDownload}
      sx={{ textAlign: "start" }}
      onClick={() => {
        downloadReceipt(
          row?.invoice?.receipt_url,
          row?.invoice?.receipt_number
        );
      }}
    >
      {row?.invoice?.receipt_number}{" "}
      {moment.unix(row?.payment?.created).utc().format("DD MMM YYYY hh:mm A")}
    </LoadingButton>
  );
};
export default DownloadPdfComponent;

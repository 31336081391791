import React, { useState } from "react";
import AlertBox from "../../../Common/Component/AlertBox";
import { Typography } from "@mui/material";
import useConfig from "../../../hooks/useConfig";
import HttpService from "../../../hooks/Https-services";

function AlertPopup({
  handleLinkClick,
  isReadNotification,
  el,
  handleUpdateStausAnnouceMent,
}: any) {
  const configData = useConfig();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isReadNotification) {
      handleUpdateStausAnnouceMent();
    }
  };
  return (
    <>
      <Typography
        onClick={() => {
          setContent(el?.NotificationContent);
          handleClickOpen();
        }}
        variant="body2"
        sx={{
          color: "#fff",
          width: 1,
        }}
      >
        {el?.NotificationMessage}
      </Typography>
      <AlertBox
        open={open}
        handleClose={handleClose}
        buttonAction={() => handleClose()}
        buttonText={"Close"}
        content={<div dangerouslySetInnerHTML={{ __html: content }} />}
      />
    </>
  );
}

export default AlertPopup;

import {Sample} from '../types/Sample';

import {Backend} from './Backend';

export class LongPlaytimeFilteringBackend implements Backend {
  private backend: Backend;

  constructor(next: Backend) {
    this.backend = next;
  }

  sendRequest(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendRequest(sample);
  }

  sendUnloadRequest(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendUnloadRequest(sample);
  }

  sendRequestSynchronous(sample: Sample) {
    if (this.isPlayedAboveThreshold(sample)) {
      return;
    }
    this.backend.sendRequestSynchronous(sample);
  }

  sendAdRequest(sample: any) {
    this.backend.sendAdRequest(sample);
  }

  private isPlayedAboveThreshold(sample: Sample): boolean {
    return (sample.played == null ? 0 : sample.played) >= 90000;
  }
}

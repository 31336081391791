import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import AdsTable from "./AdsTable";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ListOfAds = () => {
  const [listOfAds, setListOfAds] = useState<any>([]);
  const [listOfInactiveAds, setListOfInactiveAds] = useState<any>([]);
  const { isRefresh, setIsRefresh }: any = useContext(LoadingContext);

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const configData = useConfig();

  const handleUnpublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishNotification.replace(":id", id),
        {}
      );
    } catch (error) {}
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },

      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Visits",
        accessor: "visits",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Profile Progress",
        accessor: "progress",
      },
    ],
    []
  );
  const GetActiveAds = useCallback(async () => {
    try {
      const res = await HttpService.get(`${APIRoutes.GetAllAds}?isActive=true`);
      setListOfAds(res?.data?.data);
    } catch (error) {}
  }, []);
  const GetInActiveAds = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetAllAds}?isActive=false`
      );
      setListOfInactiveAds(res?.data?.data);
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetActiveAds();
    GetInActiveAds();
  }, [GetActiveAds, GetInActiveAds]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Prerolls</Typography>
        <Box>
          <button
            onClick={() => navigate(PageRoutes.EditAds.replace(":id", "0"))}
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Add Preroll
          </button>{" "}
        </Box>
      </Box>
      <Box>
        <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
          <Tab label="Available" {...a11yProps(0)} />
          <Tab label="Trash" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AdsTable
            listOfAds={listOfAds}
            GetActiveAds={GetActiveAds}
            GetInActiveAds={GetInActiveAds}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdsTable
            listOfActiveAd={listOfAds}
            listOfAds={listOfInactiveAds}
            GetActiveAds={GetActiveAds}
            GetInActiveAds={GetInActiveAds}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default ListOfAds;

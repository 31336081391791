const AlertStyle = {
  AlertBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: 1,
    py: 1,

    width: 1,
  },
};
export default AlertStyle;

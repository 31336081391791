import { DemoBitmovinPlayer } from "../decorators/DemoBitmovinPlayer";
import axios from "axios";
import axiosRetry from "axios-retry";
import { SyncSDK, Callbacks, LogLevels } from "@sscale/syncsdk";

import { publish } from "./PubSub";
import { DemoHtmlPlayer } from "../decorators/DemoHtmlPlayer";

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    return error.response?.status === 500;
  },
});

let syncInstance: any;

export const initializeSyncSdk = () => {
  if (syncInstance) {
    syncInstance.removeListener(Callbacks.delta_change);
    syncInstance.removeListener(Callbacks.sync_accuracy_change);
    syncInstance.removeListener(Callbacks.speed_change);
    syncInstance.removeListener(Callbacks.client_list_update);
    syncInstance.removeListener(Callbacks.error);
    syncInstance.removeListener(Callbacks.chat_update);
    syncInstance.removeListener(Callbacks.remote_event);
  }

  syncInstance = new SyncSDK();

  syncInstance.setLogLevel(LogLevels.DEBUG);

  syncInstance.attachListener(
    publish.bind(null, "delta_change"),
    Callbacks.delta_change
  );
  syncInstance.attachListener(
    publish.bind(null, "sync_accuracy_change"),
    Callbacks.sync_accuracy_change
  );
  syncInstance.attachListener(
    publish.bind(null, "speed_change"),
    Callbacks.speed_change
  );
  syncInstance.attachListener(
    publish.bind(null, "client_list_update"),
    Callbacks.client_list_update
  );
  syncInstance.attachListener(publish.bind(null, "error"), Callbacks.error);
  syncInstance.attachListener(
    publish.bind(null, "chat_update"),
    Callbacks.chat_update
  );
  syncInstance.attachListener(
    publish.bind(null, "remote_event"),
    Callbacks.remote_event
  );
};

// @ts-ignore
export const createGroup = async (token, clientName) => {
  try {
    const res = await syncInstance.createGroup(token, clientName);
  } catch (error) {
    console.log(error);
  }
};

export const setBitmovinToSdk = (player: any) => {
  // @ts-ignore
  window.bitmovin = player;

  syncInstance.addPlayerClient(new DemoBitmovinPlayer(player));
  setTimeout(() => {
    startSynchronize();
  }, 1000);
};

export const setHtmlPlayerToSdk = (player: any) => {
  // @ts-ignore
  window.html = player;
  syncInstance.addPlayerClient(new DemoHtmlPlayer(player));
  // startSynchronize();
};
export const startSynchronize = () => {
  syncInstance.startSynchronize().catch((e: any) => console.log([e]));
};
export const stopSynchronize = () => {
  syncInstance?.stopSynchronize();
};

export const playGroup = () => {
  syncInstance?.groupPlay();
};
export const pauseGroup = () => {
  syncInstance?.groupPause();
};
export const setVolume = (volume: number) => {
  syncInstance?.groupChangeVolume(volume);
};
export const muteGroup = () => {
  syncInstance?.muteGroup();
};
export const unmuteGroup = () => {
  syncInstance?.unmuteGroup();
};
export const sendMessageToChat = (message: string) => {
  return syncInstance?.sendMessageToGroup(message);
};
export const groupSeek = (delta?: number) => {
  return syncInstance?.setGroupPosition(delta);
};

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import HttpService from "../../../../hooks/Https-services";
import { Download } from "@mui/icons-material";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { toast } from "react-toastify";
import useConfig from "../../../../hooks/useConfig";
import DialogBox from "../../../../Common/Component/DialogBox";
import { Link } from "react-router-dom";

const DownloadClips = ({ el, isInsider }: any) => {
  const [isDownload, setIsDownload] = useState(false);
  const configData = useConfig();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelDownload = async () => {
    try {
      const a = document.createElement("a");
      a.href =
        el?.type === "linius"
          ? el?.downloadUrl
          : `https://images-us-east.htptv.net/videoclips/${el?.jobS3FileName}`;
      a.target = "_blank";
      a.download = `${el.jobId}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button
        disabled={
          el?.jobStatus === "SUBMITTED" ||
          el?.jobStatus == "ERROR" ||
          el?.jobStatus == "PENDING"
        }
        color={el.jobStatus !== "COMPLETE" ? "primary" : "success"}
        onClick={isInsider ? handelDownload : handleClickOpen}
        variant="text"
      >
        <Download /> Download
      </Button>
      <DialogBox
        buttonText={""}
        handleClose={handleClose}
        open={open}
        content={
          <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
            It looks like you're trying to initiate a download. Downloads are
            limited to subscribers of our Insider Plan. Head over to the{" "}
            <Link to={PageRoutes.Packages}>Plans</Link> tab to upgrade to the
            Insider Plan and then try your download again.
          </Typography>
        }
        buttonActionSecondary={handleClose}
        butttonTextSecondary={"Cancel"}
      />
    </>
  );
};
export default DownloadClips;

import React, { useEffect, useRef } from "react";
import "./Video.css";
import "material-design-iconic-font/dist/css/material-design-iconic-font.min.css";

const VideoContainer = ({
  stream,
  participant,
  isLocal,
  isMuted,
  isVideoEnabled,
  toggleAudio,
  toggleVideo,
}: any) => {
  const video: any = useRef(null);

  useEffect(() => {
    if (stream && video) {
      video.current.srcObject = stream;
      video.current.onclick = () => false;
      video.current.ondblclick = toggleFullscreen;
    }
  }, []);

  const toggleFullscreen = (e: any) => {
    if (!document.fullscreenElement) {
      const fullScreenFunc =
        e.target.requestFullscreen ||
        e.target.mozRequestFullScreen ||
        e.target.webkitRequestFullscreen ||
        e.target.msRequestFullscreen;

      if (fullScreenFunc) {
        fullScreenFunc();
      }

      e.target.controls = false;
    } else {
      document.exitFullscreen();
      e.target.controls = true;
    }
  };

  return (
    <div
      id={`video-${participant}`}
      className={`video ${isLocal ? "local" : ""}`}
    >
      <div className="participant" id={participant}>
        {isLocal ? (
          <>
            <button
              className={`toggleAudio ${isMuted ? "muted" : "unmuted"}`}
              onClick={toggleAudio}
            >
              {isMuted ? (
                <i
                  className="zmdi zmdi-mic-off size-normal"
                  style={{ color: "white" }}
                />
              ) : (
                <i
                  className="zmdi zmdi-mic size-normal"
                  style={{ color: "white" }}
                />
              )}
            </button>
            <button
              className={`toggleVideo ${
                isVideoEnabled ? "videoOff" : "videoOn"
              }`}
              onClick={toggleVideo}
            >
              {isVideoEnabled ? (
                <i
                  className="zmdi zmdi-videocam-off size-normal"
                  style={{ color: "white" }}
                />
              ) : (
                <i
                  className="zmdi zmdi-videocam size-normal"
                  style={{ color: "white" }}
                />
              )}
            </button>
          </>
        ) : (
          ""
        )}
        <video
          id={`native-video-${participant}`}
          ref={video}
          width={"200px"}
          height={"200px"}
          autoPlay
          muted={isLocal}
          disablePictureInPicture
          playsInline
        />
      </div>
    </div>
  );
};

export default VideoContainer;

const WatchStyle = {
  ItemStyle: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    py: 2,
    flexDirection: "column",
  },
};
export default WatchStyle;

import { useEffect } from "react";
import useRefreshToken, { setAuth } from "./useRefreshToken";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Navigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../utils/constants";
import ConfigData from "../Config/config.json";
const data: any = ConfigData;
const website: any = process.env.REACT_APP_ENV;
const api_url = data[website]?.api_url;
export type authDetailsType = {
  AccessToken: string;
  RefreshToken: string;
  UserID: string | number;

  FirstName: string;
  LastName: string;

  Email: string;
};

export const getCurrentAuth = () => {
  const localAuth = localStorage.getItem("auth");
  const auth: authDetailsType = localAuth
    ? JSON.parse(localStorage.getItem("auth") || "")
    : {};
  return auth;
};

export const Logout = () => {
  localStorage.removeItem("auth");
  localStorage.clear();
};
const axiosInstance = axios.create();
const getIp = async () => {
  try {
    const ip: any = await fetch("https://api.ipify.org?format=json");
    const ip2 = await ip.json();

    return ip2?.ip;
  } catch (error) {
    return "0.0.0.0";
  }
};
const refresh = async () => {
  const auth = getCurrentAuth();
  const ip = await getIp();
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${auth.AccessToken}`,
    };
    const response = await axiosInstance.post(
      `${api_url}${APIRoutes.RefreshToken}`,
      {
        RefreshToken: auth.RefreshToken,
        ip_address: ip,
      },
      { headers: headers }
    );
    if (response.data) {
      const body: authDetailsType = response.data.data;
      setAuth(body);
      return body.AccessToken;
    }
  } catch (error: any) {
    // dispatch(logout());

    Logout();
    window.location.href = `${PageRoutes.Login}?user=true`;
  }
};
// var auth = useTypedSelector(getAuthState, shallowEqual);
// const dispatch = useDispatch();
let flag = false;
const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: any = undefined;
  return () => {
    if (isCalled && flag) {
      return runningPromise;
    } else {
      isCalled = true;
      flag = true;
      runningPromise = refresh();

      return runningPromise;
    }
  };
};

// stores the function returned by refreshExpiredTokenClosure
function shouldRetry(config: any) {
  return config.retries.count < 3;
}
const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use(
  // @ts-ignore: Unreachable code error
  (config: AxiosRequestConfig) => {
    const auth = getCurrentAuth();
    if (!config.headers!["Authorization"]) {
      config.headers!["Authorization"] = `bearer ${auth?.AccessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    error.config.retries = error.config.retries || {
      count: 0,
    };
    const originalRequest = error.config;

    // logout user's session if refresh token api responds 401 UNAUTHORIZED

    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    if (error.response.status === 401 && shouldRetry(error.config)) {
      error.config.retries.count += 1;
      const updatedToken = await refreshExpiredToken();
      if (!updatedToken) {
        Logout();
        window.location.href = `${PageRoutes.Login}?user=true`;
      } else {
        originalRequest.headers["Authorization"] = `bearer ${updatedToken}`;
        flag = false;
        return axiosInstance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

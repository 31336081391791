import {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {logger} from '../../utils/Logger';
import {Adapter} from '../Adapter';

import {DashjsInternalAdapter} from './DashjsInternalAdapter';

export class DashjsAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);
    this.internalAdapter = new DashjsInternalAdapter(player, opts);
    this.analytics = Analytics.create(config, this.internalAdapter);
  }
}

import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import "./Payment.css";
import { useParams } from "react-router-dom";
import { APIRoutes } from "../../utils/constants";
import { toast } from "react-toastify";
import useConfig from "../../hooks/useConfig";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { LoadingButton } from "@mui/lab";
import HttpService from "../../hooks/Https-services";
import moment from "moment";
import EllipsisContent from "../../Common/Component/EllipsisContent";
export default function CheckoutForm({ plandetails }: any) {
  const stripe = useStripe();
  const elements = useElements();
  const user = useCommonDetails();
  const [email, setEmail] = useState(user.Email);
  const [message, setMessage] = useState("");
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState({
    firstName: user.FirstName,
    lastName: user.LastName,
  });
  const returnUrl: any = process.env.REACT_APP_paymentRedirectUrl;
  const { clientSecret, plan_id, paymentId }: any = useParams();
  const handleChange = (e: any) => {
    const item: any = { ...value };
    item[e.target.name] = e.target.value;

    setValue(item);
  };
  const [couponCode, setCouponCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [giftCode, setGiftCode] = useState("");
  const [errorMessageGift, setErrorMessageGift] = useState("");
  const [discount, setDiscount] = useState<any>(null);
  const [giftCardDetais, setGiftCardDetails] = useState({} as any);
  const handleApplyCoupon = () => {
    // Basic validation
    if (!couponCode) {
      setErrorMessage("Please enter a coupon code.");
      return;
    }

    // Clear any previous error message
    setErrorMessage("");

    // Call the onApplyCoupon callback with the coupon code
    handleApplyCouponBackend(couponCode);
  };
  const handleApplyGiftCard = async () => {
    if (!giftCode) {
      setErrorMessage("Please enter a coupon code.");
      return;
    }

    // Clear any previous error message
    setErrorMessageGift("");
    setIsSkeleton(true);
    try {
      const res = await HttpService.post(APIRoutes.ApplyGiftCode, {
        giftCode: giftCode,
      });
      if (res) {
        toast.success("Gift Code Applied Successfull");
        setGiftCardDetails(res?.data?.data);
        setGiftCode("");
        setIsSkeleton(false);
      }
    } catch (error: any) {
      setIsSkeleton(false);
      toast.error(error.response.data.message);
    }
  };
  const handleApplyCouponBackend = async (couponCode: any) => {
    // You can now use the couponCode to interact with your backend
    setIsSkeleton(true);
    try {
      const res = await HttpService.post(APIRoutes.AppyCode, {
        couponCode: couponCode,
        plan_id: plan_id,
      });
      res?.data?.data?.isValid && toast.success("Code Applied Successfull");

      setDiscount(res?.data?.data);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
      toast.error("Please Enter Valid Code");
    }

    // Implement your backend logic here
  };
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!plan_id) {
      return;
    }
  }, [stripe]);

  const configData = useConfig();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);
      if (
        plandetails?.plan_type_id == 3 ||
        plan_id == 0 ||
        clientSecret !== "0"
      ) {
        const { error }: any = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: configData?.returnUrl,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          toast.error(error.message);
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
      } else {
        const { error: submitError }: any = await elements.submit();
        if (submitError) {
          toast.error(submitError);
          setIsLoading(false);
          return;
        }
        const subscription = await HttpService.post(
          APIRoutes.CreateSubscription,
          {
            id: paymentId,
            couponCode: discount?.isValid ? couponCode : "",
            priceId: plandetails?.plan_id,
          }
        );
        const { type, clientSecret, pending_setup_intent }: any =
          await subscription?.data?.data;
        if (clientSecret) {
          const { error }: any = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: configData?.returnUrl,
            },
          });

          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            toast.error(error.message);
            setMessage(error.message);
          } else {
            setMessage("An unexpected error occurred.");
          }

          setIsLoading(false);
        } else if (pending_setup_intent) {
          const { error }: any = await stripe.confirmSetup({
            elements,
            clientSecret: pending_setup_intent,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: configData?.returnUrl,
            },
          });
          console.log(error);
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            toast.error(error.message);
            setMessage(error.message);
          } else {
            setMessage("An unexpected error occurred.");
          }

          window.location.href = configData?.returnUrl;
        }
      }
      // Confirm the Intent using the details collected by the Payment Element
    } catch (error: any) {
      console.log(error);
      toast(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const paymentElementOptions: any = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        name: `${value.firstName} ${value.lastName}`,
        email: user.Email,
      },
    },
  };

  return (
    <Box sx={{ p: 3, mt: 10, position: "relative" }}>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            bottom: "50%",
            top: "50%",
            right: 0,
            zIndex: 1301,
            left: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                gap: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Your order is being processed.
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Please do not refresh the page
              </Typography>

              <CircularProgress
                size={48}
                sx={{
                  color: "#bf311a",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Grid
        sx={
          isLoading
            ? {
                "-webkit-filter": "blur(5px)",
                filter: " blur(5px)",
              }
            : {}
        }
        container
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              py: 2,
              width: 1,
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontStyle: "normal",
                textTransform: "none",
                color: "#757a80",
                fontWeight: "600",
              }}
            >
              {" "}
              Checkout with card
            </Typography>
            <Divider
              sx={{ display: "flex", flexGrow: 1, borderColor: "black" }}
            />
          </Box>

          <form id="payment-form" onSubmit={handleSubmit}>
            {/* <LinkAuthenticationElement
              id="link-authentication-element"
              options={{
                defaultValues: { email: user.Email },
              }}
            /> */}
            {/* <AddressElement options={{ mode: "billing" }} /> */}
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={12}>
                <div className="form-group">
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      fontStyle: "normal",
                      py: 0.5,
                    }}
                  >
                    Email address
                  </Typography>
                  <input
                    value={user.Email}
                    disabled
                    type="email"
                    className="form-control name-feild"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-group">
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      fontStyle: "normal",
                      py: 0.5,
                    }}
                  >
                    First Name
                  </Typography>
                  <input
                    value={value.firstName}
                    onChange={handleChange}
                    style={{ padding: "10px auto" }}
                    name="firstName"
                    defaultValue={user.FirstName}
                    type="text"
                    className="form-control name-feild"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter First Name"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-group">
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      fontStyle: "normal",
                      py: 0.5,
                    }}
                  >
                    Last Name
                  </Typography>
                  <input
                    value={value.lastName}
                    onChange={handleChange}
                    defaultValue={user.LastName}
                    name="lastName"
                    style={{ padding: "10px auto" }}
                    type="text"
                    className="form-control name-feild"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Last Name"
                  />
                </div>
              </Grid>
            </Grid>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <LoadingButton
              variant="contained"
              className={configData.buttonCommonClass}
              style={{ marginTop: "20px" }}
              loading={isLoading}
              type="submit"
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">Pay now</span>
            </LoadingButton>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <div className="order-review_wrapper">
              <Box
                className="order-review_header"
                sx={{ bgcolor: configData.primary }}
              >
                Order Review
              </Box>
              <div className="order-review_body">
                <div className="order-review_title">
                  <div className="logo">
                    {plan_id == 0 ? (
                      <Box>
                        <Box>
                          <img
                            width={"200px"}
                            src={configData?.giftPageLogo}
                            alt="Main logo"
                          />
                        </Box>
                      </Box>
                    ) : (
                      <img
                        width={"100px"}
                        src={configData?.paymentPageLogo}
                        alt="Main logo"
                      />
                    )}
                  </div>
                  <div className="name">
                    <span>{plandetails?.title}</span>
                  </div>
                  <div className="price">{plandetails?.price}</div>
                </div>
                {clientSecret === "0" && (
                  <>
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Apply Coupon
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            disabled={discount?.isValid}
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={couponCode}
                            onChange={(e) => {
                              setDiscount(null);
                              setCouponCode(e.target.value);
                            }}
                            error={
                              !!errorMessage || (discount && !discount?.isValid)
                            }
                            helperText={
                              errorMessage
                                ? errorMessage
                                : discount && !discount?.isValid
                                ? "Please Enter Valid Code"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <LoadingButton
                            disabled={discount?.isValid}
                            variant="contained"
                            loading={isSkeleton}
                            sx={{
                              whiteSpace: "nowrap",
                              bgcolor: configData.primary,
                              ":hover": {
                                bgcolor: configData.primary,
                              },
                            }}
                            className={configData.buttonCommonClass}
                            onClick={handleApplyCoupon}
                          >
                            Apply
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Apply Gift Code
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            error={errorMessageGift ? true : false}
                            helperText={errorMessageGift}
                            value={giftCode}
                            onChange={(e) => {
                              setGiftCode(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <LoadingButton
                            variant="contained"
                            loading={isSkeleton}
                            sx={{
                              whiteSpace: "nowrap",
                              bgcolor: configData.primary,
                              ":hover": {
                                bgcolor: configData.primary,
                              },
                            }}
                            className={configData.buttonCommonClass}
                            onClick={handleApplyGiftCard}
                          >
                            Apply
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {plan_id == 0 && (
                  <div className="price_grid">
                    <span>Gift Recipient</span>
                    <span>{plandetails?.name}</span>
                    <span className="slim">Recipient email</span>
                    <span>
                      <Box sx={{ width: 1 }}>
                        <EllipsisContent
                          maxWidth={"200px"}
                          sx={{ width: 1 }}
                          text={plandetails?.email}
                        />
                      </Box>
                    </span>
                    <span className="slim"> From</span>
                    <span>
                      {user.FirstName} {user.LastName}
                    </span>
                    <span className="slim">Gift Message</span>
                    <span>
                      <Box sx={{ width: 1 }}>
                        <EllipsisContent
                          maxWidth={"200px"}
                          sx={{ width: 1 }}
                          text={plandetails?.message}
                        />
                      </Box>
                    </span>
                    <span className="slim">Gift Delivery Date</span>
                    <span>
                      {moment(plandetails?.date).format("MM/DD/YYYY")}
                    </span>
                  </div>
                )}
                {giftCardDetais?.GiftAmount && (
                  <Typography color={"success"}>Gift Code Applied</Typography>
                )}
                <div className="price_grid">
                  <span>Subtotal</span>
                  <span>{plandetails?.price}</span>
                  <span className="slim">Estimated taxes</span>
                  <span>$0</span>

                  {discount?.isValid && <span className="slim">Discount</span>}
                  {discount?.isValid && <span>-${discount?.discount}</span>}
                  <span className="total">Total</span>

                  {giftCardDetais?.GiftAmount ? (
                    <span>
                      $
                      {plandetails?.price_in_currency -
                        parseInt(giftCardDetais?.GiftAmount) * 100 >
                      0
                        ? (plandetails?.price_in_currency -
                            parseInt(giftCardDetais?.GiftAmount) * 100) /
                          100
                        : 0}
                    </span>
                  ) : (
                    <span>
                      {discount?.isValid
                        ? `$${discount?.finalAmount}`
                        : plandetails?.price}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="policy">
              <div>
                <p>
                  <span className="links">Terms &amp; Conditions</span> /{" "}
                  <span className="links">Privacy Policy</span>
                </p>
              </div>
              <div className="warning-text">
                <p>
                  By making this purchase, you expressly acknowledge that you
                  are purchasing a subscription to {configData?.subtitle}.
                </p>
                <p>
                  This subscription has an auto-renewal feature to ensure you do
                  not lose access to the platform.
                  <br />
                  You can cancel your auto-renewal at any time by visiting the
                  Account page.
                  <br />
                  When you cancel auto-renewal, that does not cancel your
                  subscription—which will stay active until the end of its
                  current term. Auto-renewal must be canceled at least 24 hours
                  prior to the renewal date to avoid renewal charges.
                  <br />
                  All sales are final. There are no refunds or allowances.
                </p>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

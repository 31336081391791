// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfuHudyRFh6ySM-UxIv7BUvGv2r2rc3Cw",
  authDomain: "htp-firebase.firebaseapp.com",
  projectId: "htp-firebase",
  storageBucket: "htp-firebase.appspot.com",
  messagingSenderId: "18987031224",
  databaseURL: "https://htp-firebase-default-rtdb.firebaseio.com",
  appId: "1:18987031224:web:1047ef2e7bcaf85c01324d",
  measurementId: "G-NM0DDN25FP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "socket");
const db = getDatabase(app);

export { db };

import { createSlice } from "@reduxjs/toolkit";

const initialStateObj = {
  commonDetails: {},
};

export const commonDetailSlice = createSlice({
  name: "commonDetails",
  initialState: initialStateObj,
  reducers: {
    setcommonDetails: (state: any, { payload }) => {
      state.commonDetails = payload;
    },
    removecommonDetails: (state: any) => {
      state.commonDetails = {};
    },
  },
});

//const { setcommonDetails, removecommonDetails } = commonDetailSlice.actions;

//it behave like connector (old redux)

export default commonDetailSlice;

import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import "./index.css";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes, INSIDER_PLAN_ID } from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import { toast } from "react-toastify";
import DialogBox from "../../Common/Component/DialogBox";
import HttpService from "../../hooks/Https-services";
import { useStripe } from "@stripe/react-stripe-js";
import CustomToggle from "./CustomToggle";
import UpgradeModel from "./UpgradeModel";

const Passes = ({ isHomePage, handleRefresh }: any) => {
  const auth = getCurrentAuth();
  const [openColleps, setopenColleps] = useState(true);
  const navigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({} as any);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const configData = useConfig();
  const [alignment, setAlignment] = React.useState<boolean | null>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const gameId = searchParams.get("id");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GetUserSubscriptionData = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data?.plan_type_id == 3) {
        setAlignment(false);
      } else {
        setAlignment(res?.data?.data.name === "Monthly" ? false : true);
      }
      setCurrentPlan(res?.data?.data);
    } catch (error) {}
  };

  const CheckUserSubscribe = async () => {
    setIsSkeleton(true);

    try {
      const res = await HttpService.get(APIRoutes.CheckSubscribe);
      if (res?.data?.data?.isSubscribe) {
        setIsSubscribe(true);
        await GetUserSubscriptionData();
        handleRefresh();
        setIsSkeleton(false);
      } else {
        setIsSubscribe(false);
        setIsSkeleton(false);
      }
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const handleSubmit = async (el: any) => {
    setIsSkeleton(true);
    try {
      const response: any = await HttpService.post(APIRoutes.SubscribePlan, {
        priceId: el?.PlanID,
        planTypeId: el?.PlanTypeID,
        gameId: gameId,
      });
      if (response) {
        const encodedStripeLink = encodeURIComponent(
          response.data.data.clientSecret
        );
        const encodedPaymentId = encodeURIComponent(response.data.data.plan_id);
        const encodedSubscriptionId = encodeURIComponent(
          response.data.data.paymentId
        );
        navigate(
          PageRoutes.paymentPage
            .replace(":clientSecret", encodedStripeLink)
            .replace(":plan_id", encodedPaymentId)
            .replace(":paymentId", encodedSubscriptionId)
        );
      }
      setIsSkeleton(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsSkeleton(false);
    }
  };
  const handleMangePlan = async () => {
    if (!auth.AccessToken) {
      navigate(PageRoutes.Login);
    }
    setIsLoading(true);
    const cancel_at_period_end =
      currentPlan?.data &&
      currentPlan?.data[0].isRecurring &&
      !currentPlan?.data[0].cancel_date
        ? true
        : false;
    try {
      const response: any = await HttpService.post(
        APIRoutes.ChangePlanDetails,
        {
          subscriptionId: currentPlan?.subscriptionId,
          cancel_at_period_end: cancel_at_period_end,
        }
      );

      setIsLoading(false);
      CheckUserSubscribe();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const stripe: any = useStripe();
  const handleUpgrade = async (el: any, coupenCode: string) => {
    if (!auth.AccessToken) {
      navigate(PageRoutes.Login);
    }

    setIsLoading(true);

    try {
      const response: any = await HttpService.post(APIRoutes.CustomerPortal, {
        subscriptionId: currentPlan?.subscriptionId,
        priceId: el?.PlanID,
        customerId: currentPlan?.costomer_id,
        planTypeId: el?.PlanTypeID,
        coupenCode: coupenCode,
      });
      if (response?.data?.data?.clientSecret) {
        const data = await stripe.handleNextAction({
          clientSecret: response?.data?.data?.clientSecret,
        });
        if (data?.error) {
          toast.error(data?.error?.message);
          setIsLoading(false);
        }
      } else if (
        response?.data?.data?.latest_invoice?.payment_intent?.last_payment_error
          ?.message
      ) {
        toast.error(
          response?.data?.data?.latest_invoice?.payment_intent
            ?.last_payment_error?.message
        );
        setIsLoading(false);
      }
      setIsLoading(false);
      CheckUserSubscribe();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handelRestartPause = async () => {
    try {
      setIsLoading(true);

      const res = await HttpService.post(
        `${APIRoutes.PauseSubscription.replace(
          ":id",
          currentPlan?.subscription.id
        )}?isPause=false`,
        {
          date: "",
        }
      );
      await GetUserSubscriptionData();
      handleRefresh();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      // handleChange(false);
      toast.error(error.response.data.message);
    }
  };

  const GetPlans = async () => {
    try {
      setIsSkeleton(true);
      const res = await HttpService.get(APIRoutes.GetPlans);
      setPlans(res?.data?.data);

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    if (auth.AccessToken) {
      CheckUserSubscribe();
    }
    GetPlans();
  }, []);
  return (
    <Box>
      <div className="container-fluid">
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              bottom: "50%",
              top: "50%",
              right: 0,
              zIndex: 1301,
              left: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600, fontStyle: "normal" }}
                >
                  Your order is being processed.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600, fontStyle: "normal" }}
                >
                  Please do not refresh the page
                </Typography>

                <CircularProgress
                  size={48}
                  sx={{
                    color: "#bf311a",
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={
            isLoading
              ? {
                  "-webkit-filter": "blur(5px)",
                  filter: " blur(5px)",
                }
              : {}
          }
        >
          {!isHomePage && (
            <Collapse in={openColleps}>
              <div
                style={{
                  maxWidth: "1110px",
                  margin: "0px auto",
                  padding: "10px",
                }}
              >
                <div>
                  <div
                    role="alert"
                    className="alert alert-secondary alert-dismissible fade show"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: configData.planHeader,
                      }}
                    ></div>
                    <button
                      onClick={() => setopenColleps(false)}
                      type="button"
                      data-dismiss="alert"
                      aria-label="Close"
                      className="close"
                    >
                      <span>×</span>
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          )}
          <div
            className="container-fluid"
            style={{
              maxWidth: isHomePage ? "100%" : "1110px",
              margin: "0px auto",
            }}
          >
            {currentPlan?.subscription?.pause_collection && (
              <Alert severity="warning">
                You currently have a subscription on pause. Click on your plan
                below to resume your subscription.
              </Alert>
            )}
            <Stack alignItems={"center"} sx={{ pb: 3 }}>
              <CustomToggle checked={alignment} setChecked={setAlignment} />
            </Stack>
            {isSkeleton ? (
              <Box sx={{ display: "flex", justifyContent: "center", py: 10 }}>
                <CircularProgress
                  size={48}
                  sx={{
                    color: "#bf311a",
                  }}
                />
              </Box>
            ) : (
              <div className="main-package-wrapper main-container">
                {plans
                  ?.filter((el: any) => {
                    if (el?.PlanTypeID != 3) {
                      if (
                        alignment
                          ? el?.Name === "Yearly"
                          : el?.Name === "Monthly"
                      ) {
                        return el;
                      }
                    } else {
                      return el;
                    }
                  })
                  ?.map((el: any) => (
                    <div
                      style={{
                        background:
                          el?.PlanTypeID == INSIDER_PLAN_ID &&
                          process.env.REACT_APP_INSIDER === "true"
                            ? `linear-gradient(90deg, ${configData.primary} 4%,  ${configData.secondary} 0)
      top / 100% 34% no-repeat,
    linear-gradient(90deg,  ${configData.secondary} 50%,   ${configData.primary} 0) top / 100%
      82% no-repeat,
    linear-gradient(90deg,    ${configData.primary} 10%,  ${configData.secondary} 0) top / 100%
      100%`
                            : "",
                      }}
                      className={`pricing-box d-flex w-10 ${
                        el?.PlanTypeID == INSIDER_PLAN_ID &&
                        process.env.REACT_APP_INSIDER === "true"
                          ? "box-price-insider"
                          : ""
                      }`}
                    >
                      {el?.PlanTypeID == INSIDER_PLAN_ID &&
                        process.env.REACT_APP_INSIDER === "true" && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: -15,
                              m: "auto",
                              right: 0,
                              zIndex: 10,
                              left: 0,
                            }}
                          >
                            <Chip
                              label="Best Value"
                              variant="outlined"
                              icon={<ThumbUpOffAltIcon sx={{ fill: "#fff" }} />}
                              sx={{
                                borderRadius: "4px",
                                bgcolor: configData.primary,
                                color: "#fff",
                                border: "none",
                              }}
                            />
                          </Box>
                        )}

                      <div className="pricing-title">
                        <h3>{el?.PlanType}</h3>
                      </div>
                      <div
                        className="middel-part"
                        style={{ backgroundColor: "#f5f5f5" }}
                      >
                        <div className="pricing-feature">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: el?.PlanContent,
                            }}
                            className="price-desc"
                          ></div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          <div className="pricing-price">{el?.Price}</div>
                          {isSubscribe &&
                          currentPlan?.data &&
                          currentPlan?.data[0].plan_id == el?.PlanID ? (
                            <div className="pricing-action">
                              {currentPlan?.subscription?.pause_collection ? (
                                <button
                                  onClick={handelRestartPause}
                                  disabled={isSkeleton}
                                  style={{ cursor: "pointer" }}
                                  className={configData.buttonCommonClass}
                                >
                                  Resume
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleMangePlan()}
                                  disabled={isSkeleton}
                                  style={{ cursor: "pointer" }}
                                  className={configData.buttonCommonClass}
                                >
                                  {currentPlan?.data[0].isRecurring &&
                                  !currentPlan?.data[0].cancel_date
                                    ? "Cancel"
                                    : "Renew plan"}
                                </button>
                              )}
                            </div>
                          ) : currentPlan?.data && el?.PlanTypeID != 3 ? (
                            <UpgradeModel
                              el={el}
                              isLoading={isLoading}
                              currentPlan={currentPlan}
                              handleUpgrade={handleUpgrade}
                              isSkeleton={isSkeleton}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                if (
                                  isSkeleton ||
                                  !el?.Status ||
                                  (currentPlan?.data &&
                                    currentPlan?.data[0].plan_id != el?.PlanID)
                                ) {
                                  return;
                                } else {
                                  if (!auth.AccessToken) {
                                    navigate(PageRoutes.Login);
                                  } else {
                                    !gameId && el?.PlanTypeID == 3
                                      ? handleClickOpen()
                                      : handleSubmit(el);
                                  }
                                }
                              }}
                              className="pricing-action"
                            >
                              <button
                                disabled={
                                  isSkeleton ||
                                  !el?.Status ||
                                  (currentPlan?.data &&
                                    currentPlan?.data[0].plan_id != el?.PlanID)
                                }
                                style={{
                                  cursor: "pointer",
                                  visibility:
                                    currentPlan?.data &&
                                    currentPlan?.data[0].plan_id != el?.PlanID
                                      ? "hidden"
                                      : "visible",
                                }}
                                className={configData.buttonCommonClass}
                              >
                                BUY
                              </button>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <br />
          </div>
          <DialogBox
            open={open}
            handleClose={handleClose}
            buttonAction={() => navigate(PageRoutes.UpcomingEvent)}
            buttonText={"Events"}
            content={
              "In order to buy a single game, you must select the game first"
            }
          />
        </Box>
      </div>
    </Box>
  );
};

export default Passes;

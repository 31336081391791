const LoginButton = {
  width: 1,
  color: "common.white",
  background: "linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)",
  boxShadow: "0px 10px 40px rgba(255, 255, 255, 0.12)",
  borderRadius: "8px",
};
const HederMenu = {
  fontWeight: 700,
  fontStyle: "italic !important",
  color: "#6d7278",
  cursor: "pointer",
};
const DrawerBlock = {
  p: 2,
  bgcolor: "#edf2f7",
  height: 1,
};

const HeaderTab = {
  cursor: "pointer",
  fontWeight: "700",
  fontFamily: "SF UI Text",
  fontSize: "14px",
  lineHeight: "22px",
  borderBottom: "1px solid transparent",

  ":after": {
    display: "block",
    content: `""`,
    borderBottom: "solid 3px #003876",
    transform: "scaleX(0)",
    transition: " transform 250ms ease-in-out",
  },

  ":hover:after": { transform: " scaleX(1)" },
};
const ProfileStyle = {
  MenuDropDown: {
    overflow: "visible",

    minWidth: "200px",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "white",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  menuSelect: {
    ".MuiSelect-select": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
  },
  ProfileStyle: {
    MenuDropDown: {
      overflow: "visible",
      filter: "drop-shadow(0px 1px 3px rgba(0,0,0,0.32))",
      bgcolor: "#072B41",
      minWidth: "200px",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "#072B41",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
    menuSelect: {
      ".MuiSelect-select": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      },
    },
  },
};
export { DrawerBlock, LoginButton, HeaderTab, ProfileStyle, HederMenu };

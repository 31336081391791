import React, { useEffect, useState } from "react";
import useConfig from "../../../../hooks/useConfig";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import moment from "moment";
import HttpService from "../../../../hooks/Https-services";
import { APIRoutes } from "../../../../utils/constants";
import dayjs from "dayjs";
import { Edit } from "@mui/icons-material";
import addYears from "date-fns/addYears";
const PauseSub = ({
  subscriptionDetails,
  handleChangeRefresh,
  handleChange,
}: any) => {
  const configData = useConfig();
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const today = new Date();
  const oneYearFromNow = addYears(today, 1);
  const handleClose = () => {
    setOpen(false);
  };
  const formik: any = useFormik({
    initialValues: {
      date: subscriptionDetails?.pause_collection
        ? dayjs.unix(subscriptionDetails?.pause_collection?.resumes_at)
        : "",
      radio: 30,
    },
    onSubmit: (values) => {},
  });
  const handelSubmit = async (value: any) => {
    const isDateIsBeforToday = dayjs(value.date)?.isBefore();
    handleChange(true);
    try {
      if (!value.date && !value.radio) {
        toast.error("Please Select Valid Value");
        return;
      } else if (isDateIsBeforToday) {
        toast.error("Please Enter Valid Date");
        return;
      }
      let submitDate: any = "";
      if (value.date) {
        submitDate = dayjs(value.date).unix();
      } else if (value.radio) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        submitDate = dayjs().add(value.radio, "days").unix();
      }
      handleClose();

      const res = await HttpService.post(
        `${APIRoutes.PauseSubscription.replace(
          ":id",
          subscriptionDetails.id
        )}?isPause=true`,
        {
          date: submitDate,
        }
      );
      toast.success("Successfully Paused Subscription!");
      handleChangeRefresh();
      handleChange(false);
    } catch (error: any) {
      handleChange(false);
      toast.error(error.response.data.message);
    }
  };
  const handelRestartPause = async () => {
    handleChange(true);
    try {
      handleClose();

      const res = await HttpService.post(
        `${APIRoutes.PauseSubscription.replace(
          ":id",
          subscriptionDetails.id
        )}?isPause=false`,
        {
          date: "",
        }
      );
      toast.success("Successfully Resumed Subscription!");
      handleChangeRefresh();
      handleChange(false);
    } catch (error: any) {
      handleChange(false);
      toast.error(error.response.data.message);
    }
  };
  const twoDaysFromNow = dayjs().add(1, "year");

  const shouldDisableDate = (date: any) => {
    return date.isBefore(today, "day") || date.isAfter(twoDaysFromNow, "day");
  };
  const shouldDisableYear = (date: any) => {
    return date.isBefore(today, "day") || date.isAfter(twoDaysFromNow, "day");
  };

  useEffect(() => {
    if (subscriptionDetails?.pause_collection) {
      setIsEdit(false);
    }
  }, [subscriptionDetails]);
  return (
    <Box sx={{ mt: 1 }}>
      <button
        style={{ width: "auto", fontSize: "16px", marginRight: "8px" }}
        className={configData.buttonCommonClass}
        onClick={handleClickOpen}
      >
        {subscriptionDetails?.pause_collection
          ? "Edit Pause"
          : "Pause Subscription"}
      </button>
      {subscriptionDetails?.pause_collection && (
        <button
          style={{ width: "auto", fontSize: "16px" }}
          className={configData.buttonCommonClass}
          onClick={handelRestartPause}
        >
          Restart Now
        </button>
      )}

      <form>
        <Dialog
          sx={{
            ".MuiPaper-root": {
              width: 1,
              maxWidth: "700px",
            },
          }}
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ textAlign: "center" }}
            >
              <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
                {subscriptionDetails?.pause_collection
                  ? "Your subscription is paused."
                  : `You are about to pause your subscription. Your subscription will continue to be active until the end of your current billing cycle, at which point you will lose access to all features on the platform until your subscription is reactivated. You may pause your subscription for up to 1 year. When would
                you like to reactivate your subscription?`}
              </Typography>
              <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
                {subscriptionDetails?.pause_collection &&
                  "Your subscription will restart on"}
              </Typography>
            </DialogContentText>
            <FormControl
              sx={{
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                mt: 2,
              }}
            >
              {!subscriptionDetails?.pause_collection || isEdit ? (
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="radio"
                  value={formik.values.radio}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    formik.setFieldValue("date", "");
                  }}
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="30 Days"
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label="60 Days"
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label="90 Days"
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="date"
                      disablePast
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          error: formik.errors.date ? true : false,
                          helperText: formik?.errors?.date,
                        },
                        field: {
                          readOnly: true,
                        },
                      }}
                      views={["day"]}
                      shouldDisableDate={shouldDisableDate}
                      shouldDisableYear={shouldDisableYear}
                      label="Custom Date"
                      value={formik.values.date}
                      onChange={(newValue: any) => {
                        formik.setFieldValue("date", newValue);
                        formik.setFieldValue("radio", "");
                        formik.errors.date = "";
                      }}
                    />
                  </LocalizationProvider>
                </RadioGroup>
              ) : (
                <Typography
                  sx={{ textTransform: "none", fontStyle: "normal", mt: 1 }}
                >
                  {subscriptionDetails?.pause_collection &&
                    moment
                      .unix(subscriptionDetails?.pause_collection?.resumes_at)
                      .format("MMMM DD, YYYY")}
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setIsEdit(true);
                      formik.setFieldValue("radio", "");
                      formik.setFieldValue(
                        "date",
                        dayjs.unix(
                          subscriptionDetails?.pause_collection?.resumes_at
                        )
                      );
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Typography>
              )}
            </FormControl>
          </DialogContent>

          <DialogActions>
            <button
              style={{
                width: "auto",
                fontSize: "16px",
                backgroundColor: "transparent",
                border: "1px solid",
                color: configData.primary,
                borderColor: configData.primary,
              }}
              className={configData.buttonCommonClass}
              onClick={handleClose}
            >
              Cancel
            </button>

            <button
              onClick={() => handelSubmit(formik.values)}
              style={{ width: "auto", fontSize: "16px" }}
              className={configData.buttonCommonClass}
            >
              Submit
            </button>
          </DialogActions>
        </Dialog>
      </form>
    </Box>
  );
};

export default PauseSub;

import type {DownloadFinishedEvent, PlayerAPI, PlayerExports} from 'bitmovin-player';

import {EventDispatcher, Subscribable} from '../../../core/EventDispatcher';
import {HttpRequestType} from '../../../features/httprequesttracking/HttpRequestType';
import {OnDownloadFinishedEventObject} from '../../../features/httprequesttracking/OnDownloadFinishedEventObject';
import {calculateTimeWithUndefined, getCurrentTimestamp} from '../../../utils/Utils';

export class HttpRequestTrackingAdapter implements Subscribable<OnDownloadFinishedEventObject> {
  private eventDispatcher = new EventDispatcher<OnDownloadFinishedEventObject>();
  private playerExports: PlayerExports;

  constructor(player: PlayerAPI) {
    this.playerExports = player.exports;
    player.on(this.playerExports.PlayerEvent.DownloadFinished, (event) =>
      this.onDownloadFinished(event as DownloadFinishedEvent),
    );
  }

  subscribe(callback: (args: OnDownloadFinishedEventObject) => void): () => void {
    return this.eventDispatcher.subscribe(callback);
  }

  unsubscribe(callback: (args: OnDownloadFinishedEventObject) => void) {
    this.eventDispatcher.unsubscribe(callback);
  }

  private onDownloadFinished(event?: DownloadFinishedEvent) {
    this.eventDispatcher.dispatch({
      httpRequest: {
        downloadTime: calculateTimeWithUndefined(event?.downloadTime) ?? 0,
        httpStatus: event?.httpStatus ?? 0,
        success: event?.success ?? false,
        timestamp: getCurrentTimestamp(),
        url: event?.url,
        size: event?.size,
        timeToFirstByte: calculateTimeWithUndefined(event?.timeToFirstByte),
        type: this.mapHttpRequestType(event?.downloadType),
      },
    });
  }

  private mapHttpRequestType(downloadType?: string): HttpRequestType {
    switch (downloadType) {
      case this.playerExports.HttpRequestType.MANIFEST_DASH:
        return HttpRequestType.MANIFEST_DASH;
      case this.playerExports.HttpRequestType.MANIFEST_HLS_MASTER:
        return HttpRequestType.MANIFEST_HLS_MASTER;
      case this.playerExports.HttpRequestType.MANIFEST_HLS_VARIANT:
        return HttpRequestType.MANIFEST_HLS_VARIANT;
      case this.playerExports.HttpRequestType.MANIFEST_SMOOTH:
        return HttpRequestType.MANIFEST_SMOOTH;
      case this.playerExports.HttpRequestType.MANIFEST_ADS:
        return HttpRequestType.MANIFEST_ADS;
      case this.playerExports.HttpRequestType.MEDIA_AUDIO:
        return HttpRequestType.MEDIA_AUDIO;
      case this.playerExports.HttpRequestType.MEDIA_VIDEO:
        return HttpRequestType.MEDIA_VIDEO;
      case this.playerExports.HttpRequestType.MEDIA_SUBTITLES:
        return HttpRequestType.MEDIA_SUBTITLES;
      case this.playerExports.HttpRequestType.MEDIA_THUMBNAILS:
        return HttpRequestType.MEDIA_THUMBNAILS;
      case this.playerExports.HttpRequestType.DRM_LICENSE_WIDEVINE:
        return HttpRequestType.DRM_LICENSE_WIDEVINE;
      case this.playerExports.HttpRequestType.DRM_LICENSE_PLAYREADY:
        return HttpRequestType.DRM_LICENSE_PLAYREADY;
      case this.playerExports.HttpRequestType.DRM_LICENSE_FAIRPLAY:
        return HttpRequestType.DRM_LICENSE_FAIRPLAY;
      case this.playerExports.HttpRequestType.DRM_LICENSE_PRIMETIME:
        return HttpRequestType.DRM_LICENSE_PRIMETIME;
      case this.playerExports.HttpRequestType.DRM_LICENSE_CLEARKEY:
        return HttpRequestType.DRM_LICENSE_CLEARKEY;
      case this.playerExports.HttpRequestType.DRM_CERTIFICATE_FAIRPLAY:
        return HttpRequestType.DRM_CERTIFICATE_FAIRPLAY;
      case this.playerExports.HttpRequestType.KEY_HLS_AES:
        return HttpRequestType.KEY_HLS_AES;
      case this.playerExports.HttpRequestType.TIME_SYNC:
        return HttpRequestType.TIME_SYNC;
      default:
        return HttpRequestType.UNKNOWN;
    }
  }
}

import {
  TextField,
  Box,
  Dialog,
  Grid,
  Button,
  FormControl,
  Select,
  MenuList,
  MenuItem,
  InputLabel,
  Typography,
  Stack,
  Autocomplete,
  Slider,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import { v4 as uuid } from "uuid";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import HttpService from "../../../../../hooks/Https-services";
import useConfig from "../../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import moment from "moment";
import _, { uniqueId } from "lodash";
import * as Yup from "yup";

import "./index.css";

import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import SelectImageForUpload from "./SelectImageForUpload";
import { Add, Remove } from "@mui/icons-material";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CreatePoll = () => {
  const configData = useConfig();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [openPoll, setOpenPoll] = React.useState(false);
  const [fileUrlPoll, setFileUrlpoll] = useState("");
  const [channelOption, setChannelOption] = useState([]);
  const [hidePoster, setHidePoster] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenPoll = () => {
    setOpenPoll(true);
  };

  const [intialvalue, setIntialValue] = useState<any>({
    gameId: null,
    isSchedule: false,
    publishDateTime: null,
    unPublishDateTime: null,
    publishRuleName: "",
    unPublishRuleName: "",
    poll_type: "single_select",
    title: "",
    question: "",
    options: [
      {
        option: "",
        id: uuid(),
        vote: 0,
      },
    ],
  });
  const [searchFilter, setFilter] = useState<any>(null);
  const [durationForPublish, setDurationForPublish] = useState(20);
  const [vodGames, setVodGames] = useState([]);
  const [liveGame, setLiveGames] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [selectedType, setSelectedType] = useState(intialvalue?.type || "text");
  const validationSchema = Yup.object().shape({
    gameId: Yup.object().required("This field is Required"),
    title: Yup.string().required("This field is Required"),
    poll_type: Yup.string().required("This field is Required"),
    question: Yup.string().required("Question is required"),
    options: Yup.array()
      .of(
        Yup.object().shape({
          option: Yup.string().required("Option is required"),
        })
      )
      .min(1, "At least one option is required")
      .max(4, "No more than 4 options allowed"),
  });

  const formik: any = useFormik({
    initialValues: intialvalue,
    validationSchema: validationSchema,
    enableReinitialize: true,

    validateOnChange: false,
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        const payload: any = {
          game_id: values.gameId?.game_id,
          customer_id: configData.customer,
          is_active: false,
          game_details: {
            game_id: values.gameId?.game_id,
            away_team: values.gameId?.away_team,
            home_team: values.gameId?.home_team,
            away_team_id: values.gameId?.away_team_id,
            home_team_id: values.gameId?.home_team_id,
          },
        };

        formData.append("game_id", values.gameId?.game_id);
        formData.append("customer_id", configData.customer);

        formData.append("question", values.question);
        formData.append("options", JSON.stringify(values.options));
        formData.append("poll_type", values.poll_type);

        formData.append("is_schedule", values.isSchedule);
        if (values.isSchedule) {
          if (values.publishDateTime) {
            formData.append(
              "publish_date_time",
              values.publishDateTime.toISOString()
            );

            formData.append("publish_rule_name", values.publishRuleName || "");
          }

          if (values.unPublishDateTime) {
            formData.append(
              "unpublish_date_time",
              values.unPublishDateTime.toISOString()
            );
            formData.append(
              "unpublish_rule_name",
              values.unPublishRuleNam || ""
            );
          }
        }

        formData.append("poll_background", fileUrlPoll);
        formData.append("title", values.title);

        formData.append(
          "game_details",
          JSON.stringify({
            game_id: values.gameId?.game_id,
            away_team: values.gameId?.away_team,
            home_team: values.gameId?.home_team,
            away_team_id: values.gameId?.away_team_id,
            home_team_id: values.gameId?.home_team_id,
          })
        );
        if (id && id != 0) {
          delete payload.is_active;
          const res = await HttpService.post(
            APIRoutes.UpdatePollByID.replace(":id", id),
            formData
          );
        } else {
          const res = await HttpService.post(APIRoutes.CreatePoll, formData);
        }

        navigate(PageRoutes.Polls);
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    },
  });
  const intervalRef: any = useRef(null);
  const timeRef: any = useRef(null);
  useEffect(() => {
    const clearExistingInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // Clear ref
      }
    };

    if (formik?.values?.isPause) {
      clearExistingInterval();

      intervalRef.current = setInterval(() => {
        setIsVisible((prevVisible) => !prevVisible);
      }, (formik?.values?.time + 10) * 1000);
    } else {
      clearExistingInterval();
    }

    return () => clearExistingInterval();
  }, [formik?.values?.isPause, formik?.values?.time]);

  useEffect(() => {
    if (!isVisible && formik?.values?.isPause) {
      if (timeRef.current) {
        clearInterval(timeRef.current);
        timeRef.current = null;
      }
      timeRef.current = setTimeout(() => {
        setIsVisible(true); // Show the image again after 10 seconds
      }, 10000); // Hide for 10 seconds

      return () => clearTimeout(timeRef.current); // Clear the timeout when component unmounts or visibility changes
    }
  }, [isVisible, formik?.values?.isPause]);
  const renderOptions = useCallback(() => {
    const optionsVod = vodGames.map((option: any) => {
      const firstLetter = "On Demand";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    const optionsLive = liveGame.map((option: any) => {
      const firstLetter = "Live and Upcoming";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    return [...optionsVod, ...optionsLive];
  }, [vodGames, liveGame]);
  const FetchTeamLIVE = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "live",

        page: 1,
        search: searchFilter?.search,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setLiveGames(data);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };

  const FetchTeamVOD = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "vod",
        page: 1,
        search: searchFilter?.search,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setVodGames(data);
      //   setPage(page + 1);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePoll = () => {
    setOpenPoll(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamVod = useCallback(
    _.debounce(FetchTeamVOD, 500),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamLive = useCallback(
    _.debounce(FetchTeamLIVE, 500),
    []
  );
  const GetPollByID = useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetPollByID.replace(":id", id)
      );
      const data = res?.data?.data;
      setSelectedType(data?.Type);
      setIntialValue({
        ...intialvalue,
        gameId: data?.GameDetails,
        isSchedule: data?.IsSchedule,
        publishRuleName: data?.PublishRuleName,
        unPublishRuleName: data?.UnPublishRuleName,
        publishDateTime: dayjs(data?.PublishDateTime),
        unPublishDateTime: dayjs(data?.UnPublishDateTime),
        poll_type: data?.PollType,
        question: data?.Question,
        poll_background: data?.PollBackgroundImage,
        title: data?.Title,
        options: data?.Options,
      });
      const seconds = Math.abs(
        dayjs(data?.PublishDateTime).diff(data?.UnPublishDateTime, "seconds")
      );
      setDurationForPublish(isNaN(seconds) ? 20 : seconds);
      setFileUrl(data?.Image);
      setFileUrlpoll(data?.PollBackgroundImage);
    } catch (error) {}
  }, []);
  useEffect(() => {
    if (id != 0) GetPollByID();
  }, [GetPollByID, id]);
  useEffect(() => {
    debouncedFetchUserFavouriteTeamVod(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamVod, searchFilter]);
  useEffect(() => {
    debouncedFetchUserFavouriteTeamLive(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamLive, searchFilter]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
        <button
          onClick={() => navigate(PageRoutes.Polls)}
          style={{
            cursor: "pointer",
            width: "100px",
            padding: "8px",
            margin: "10px 0px",
            fontSize: "14px",
          }}
          className={configData.buttonCommonClass}
        >
          Go Back
        </button>
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Typography variant="subtitle1">
            {id == 0 ? "Create" : "Update"} Poll
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} spacing={1.5}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Title"
                  value={formik.values.title}
                  name="title"
                  id="title"
                  onChange={formik.handleChange}
                />
                <FormHelperText sx={{ color: "error.main" }}>
                  {" "}
                  {formik?.errors?.title}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container item xs={12} sm={6} spacing={1.5}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    id="grouped-demo"
                    onChange={(e: any, value: any) => {
                      formik.setFieldValue("gameId", value);
                    }}
                    freeSolo
                    options={renderOptions().sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) =>
                      `${option.away_team} @ ${option.home_team} `
                    }
                    value={formik.values.gameId}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e: any) =>
                          setFilter({ ...searchFilter, search: e.target.value })
                        }
                        {...params}
                        label="Select Target Game"
                      />
                    )}
                  />
                  <FormHelperText sx={{ color: "error.main" }}>
                    {" "}
                    {formik?.errors?.gameId}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} spacing={1.5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Poll Type</InputLabel>
                <Select
                  label={"Poll Type"}
                  placeholder="Poll Type"
                  id="poll_type"
                  name="poll_type"
                  value={formik.values.poll_type}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value="multi_select">Multi Select</MenuItem>
                  <MenuItem value="single_select">Single Select</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "error.main" }}>
                  {" "}
                  {formik?.errors?.poll_type}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectImageForUpload
                type={"poll"}
                setFileUrl={setFileUrlpoll}
                fileUrl={fileUrlPoll}
              />
              {fileUrlPoll && (
                <Box sx={{ width: "200px", py: 2 }}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img
                    onClick={handleClickOpenPoll}
                    src={fileUrlPoll}
                    width={"100%"}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={6} spacing={1.5}>
              <Box sx={{ display: "flex", width: 1, alignItems: "center" }}>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.isSchedule}
                        onChange={(e) =>
                          formik.setFieldValue("isSchedule", e.target.checked)
                        }
                      />
                    }
                    label="Schedule"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} spacing={1.5}></Grid>
            {!!formik.values.isSchedule && (
              <Grid item xs={12} sm={6} spacing={1.5}>
                <DateTimePicker
                  sx={{ width: 1 }}
                  disablePast
                  timeSteps={{ minutes: 1 }}
                  value={formik?.values?.publishDateTime || null}
                  format=""
                  onChange={(newValue) => {
                    const newTime = dayjs(newValue).add(
                      durationForPublish,
                      "seconds"
                    );
                    formik.setValues({
                      ...formik.values,
                      publishDateTime: newValue,
                      unPublishDateTime: newTime,
                    });
                  }}
                  views={[
                    "year",
                    "month",
                    "day",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                  label="Available Date and Time"
                />
              </Grid>
            )}
            {!!formik.values.isSchedule && (
              <Grid item xs={12} sm={6} spacing={1.5}>
                <Box sx={{ flexGrow: 1 }}>
                  <FormLabel>Duration</FormLabel>
                  <FormControl fullWidth>
                    <Slider
                      min={10}
                      defaultValue={20}
                      max={120}
                      aria-label="Default"
                      value={durationForPublish}
                      onChange={(e: any, newValue: any) => {
                        setDurationForPublish(newValue);

                        const newTime = dayjs(
                          formik.values.publishDateTime
                        ).add(newValue, "seconds");
                        formik.setFieldValue("unPublishDateTime", newTime);
                      }}
                      valueLabelDisplay="on"
                    />
                    {/* <FormHelperText> {formik?.errors?.seasonId}</FormHelperText> */}
                  </FormControl>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                multiline
                rows={1}
                placeholder="Question"
                label="Question"
                id="question"
                name="question"
                value={formik.values.question}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
              />
              {formik?.errors?.question && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {" "}
                  {formik?.errors?.question}
                </FormHelperText>
              )}
            </Grid>
            {formik.values.options?.map((el: any, index: number) => {
              return (
                <Grid
                  key={index + 1}
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", flexGrow: 1, width: 1 }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={1}
                      placeholder={`Options-${index + 1}`}
                      label={`Options-${index + 1}`}
                      id={`options${[index]}`}
                      name={`options${[index]}`}
                      value={formik.values.options?.[index].option}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          `options[${index}].option`,
                          e.target.value
                        );
                      }}
                    />
                    {formik?.errors?.options?.[index]?.option && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {formik?.errors?.options[index]?.option}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        const options = formik.values.options;
                        options.splice(index, 1);
                        formik.setFieldValue("options", options);
                      }}
                      color="error"
                    >
                      <Remove />
                    </IconButton>
                  </Box>
                </Grid>
              );
            })}
            <Grid item xs={12} sm={12}>
              <Button
                disabled={formik.values.options.length === 4}
                onClick={() => {
                  const options = formik.values.options;
                  options.push({
                    option: "",
                    id: uuid(),
                    vote: 0,
                  });
                  formik.setFieldValue("options", options);
                }}
                variant="contained"
              >
                <Add />
              </Button>
            </Grid>
          </Grid>

          <Dialog
            open={openPoll}
            maxWidth={"md"}
            onClose={handleClosePoll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/*  eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={fileUrlPoll} width={"100%"} />
          </Dialog>

          <Box
            sx={{
              display: "flex",
              width: 1,

              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ maxWidth: "200px" }}>
              <button
                type="submit"
                //   onClick={() => handelDeleteSlider(row?.ID)}
                style={{
                  margin: "20px 0px",
                  cursor: "pointer",
                }}
                className={configData.buttonCommonClass}
              >
                Submit
              </button>
            </Box>
          </Box>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
export default CreatePoll;

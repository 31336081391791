import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  TablePagination,
  IconButton,
  Box,
  useTheme,
  Pagination,
  TableFooter,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import DownloadPdfComponent from "../../../../../Common/Component/DownloadPdfComponent";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import HttpService from "../../../../../hooks/Https-services";
import { useParams } from "react-router-dom";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Loader from "../../../../../Common/Component/Loader";
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const UserEmailHistory = () => {
  const [emailHistory, setEmailHistory] = useState<any>([]);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { id }: any = useParams();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const GetUsersEmailDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetUsersEmailDetails.replace(":id", id)}?page=${page}`
      );
      setEmailHistory(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, id]);
  useEffect(() => {
    GetUsersEmailDetails();
  }, [GetUsersEmailDetails]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Event Type</TableCell>
            <TableCell align="left">Subject</TableCell>
            <TableCell align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell colSpan={6} component="th" scope="row">
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={200}
                >
                  <CircularProgress />
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : !emailHistory?.data || !emailHistory?.data?.length ? (
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell colSpan={6} component="th" scope="row">
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={200}
                >
                  No history to show
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {emailHistory?.data?.map((row: any, index: any) => (
              <TableRow
                key={index + 1}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row?.eventType}
                </TableCell>
                <TableCell align="left">{row?.subject}</TableCell>
                <TableCell align="left">
                  {moment(row?.timestamp).utc().format("DD MMM YYYY hh:mm a")}{" "}
                  UTC
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <TableFooter
        sx={{ display: "flex", justifyContent: "center", py: 2, px: 2 }}
      >
        <Pagination
          count={emailHistory?.totalPages}
          showFirstButton
          showLastButton
          page={page}
          onChange={handleChangePage}
        />
      </TableFooter>

      {/* <TablePagination
        component="div"
        colSpan={4}
        count={emailHistory.totalCount}
        page={page}
        rowsPerPage={10}
        ActionsComponent={TablePaginationActions}
      /> */}
    </TableContainer>
  );
};
export default UserEmailHistory;

import {
  Box,
  Button,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import HttpService from "../../../../hooks/Https-services";
import useConfig from "../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import { a11yProps, TabPanel } from "../../Ads";
import AlertTable from "../AlertTable";

const ListOfAlerts = () => {
  const [listOfNotification, setListOfNotification] = useState<any>([]);
  const [isActive, setIsActive] = useState<any>(true);
  const navigate = useNavigate();
  const configData = useConfig();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  const GetListOfAnnoucement = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetListOfNotification}?isActive=${isActive}`
      );
      setListOfNotification(res?.data?.data);
    } catch (error) {}
  }, [isActive]);

  useEffect(() => {
    GetListOfAnnoucement();
  }, [GetListOfAnnoucement]);
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Announcement</Typography>
        <Box>
          <button
            onClick={() =>
              navigate(PageRoutes.EditAnnouncement.replace(":id", "0"))
            }
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Create Announcement
          </button>{" "}
        </Box>
      </Box>
      <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
        <Tab label="Available" {...a11yProps(0)} />
        <Tab label="Trash" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AlertTable
          listOfNotification={listOfNotification}
          GetListOfAnnoucement={GetListOfAnnoucement}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AlertTable
          listOfNotification={listOfNotification}
          GetListOfAnnoucement={GetListOfAnnoucement}
        />
      </TabPanel>
    </Box>
  );
};
export default ListOfAlerts;

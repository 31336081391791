import ConfigData from "../Config/config.json";
import axios from "axios";
const data: any = ConfigData;
const website: any = process.env.REACT_APP_ENV;
const api_url = data[website]?.api_url;

export default axios.create({
  baseURL: api_url,
});

export const TeamClient = axios.create({
  baseURL: "https://api.htptv.net",
});

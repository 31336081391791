import {
  Box,
  Button,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HttpService from "../../../../hooks/Https-services";
import useConfig from "../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import SliderRow from "../SliderRow";
import ListOfAlerts from "../../AlertDesign/ListOfAlerts";
import { styled } from "styled-components";
import Example from "../ReorderTable";
import LoadingContext from "../../../../Common/Contex/LoadingContex";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ListOfSlider = () => {
  const [listOfSlider, setListOfSlider] = useState<any>([]);
  const { isRefresh, setIsRefresh }: any = useContext(LoadingContext);

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const configData = useConfig();
  const FetchChannels = async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );

      return result.data.data;
    } catch (error) {}
  };
  const GetListOfSliders = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetAllSlider);
      const channels: any = await FetchChannels();
      const filterData = res?.data?.data
        ?.sort((a: any, b: any) => parseInt(a.Priority) - parseInt(b.Priority))
        ?.map((el: any, i: any) => {
          const index = channels?.findIndex(
            (element: any) => element?.channel_id == el?.WatchUrl
          );
          return {
            ...el,
            channel_name: channels[index]?.channel_name,
          };
        });
      setIsSkeleton(false);
      setListOfSlider(filterData);
    } catch (error) {}
  };

  const handleUnpublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishNotification.replace(":id", id),
        {}
      );
      GetListOfSliders();
    } catch (error) {}
  };

  useEffect(() => {
    GetListOfSliders();
  }, [isRefresh]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },

      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Visits",
        accessor: "visits",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Profile Progress",
        accessor: "progress",
      },
    ],
    []
  );
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Sliders</Typography>
        <Box>
          <button
            onClick={() =>
              navigate(PageRoutes.EditSliderSetting.replace(":id", "0"))
            }
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Add Slider
          </button>{" "}
        </Box>
      </Box>
      <Box>
        <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
          <Tab label="Available" {...a11yProps(0)} />
          <Tab label="Trash" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {!isSkeleton && (
            <Example
              initData={listOfSlider?.filter((el: any) => el?.IsActive)}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!isSkeleton && (
            <Example
              initData={listOfSlider
                ?.filter((el: any) => !el?.IsActive)
                ?.sort((a: any, b: any) =>
                  a?.createdAt < b.createdAt ? 1 : -1
                )}
            />
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};
export default ListOfSlider;

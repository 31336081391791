import { combineReducers } from "@reduxjs/toolkit";

import commonDetailReducer from "./commonReducer";
import roomDetails from "./roomReducer";

const RootReducer = combineReducers({
  commonDetails: commonDetailReducer.reducer,
  roomDetails: roomDetails.reducer,
});

export default RootReducer;

import {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {logger} from '../../utils/Logger';
import {Adapter} from '../Adapter';

import {HTMLVideoElementInternalAdapter} from './HTMLVideoElementInternalAdapter';

export class HTMLVideoElementAdapter extends Adapter implements AdapterAPI {
  constructor(
    config: AnalyticsConfig,
    player: HTMLVideoElement,
    opts?: AnalyticsStateMachineOptions,
    // anonymous type for source metadata to not create any public named interface, until we stabilize the adapter API and provided typings
    sourceMetadata?: {
      streamFormat?: string;
      audioCodec?: string;
      videoCodec?: string;
      audioLanguage?: string;
    },
  ) {
    super();
    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);
    this.internalAdapter = new HTMLVideoElementInternalAdapter(player, opts, sourceMetadata);
    this.analytics = Analytics.create(config, this.internalAdapter);
  }

  get sessionMetadata() {
    const internalAdapter = this.internalAdapter as HTMLVideoElementInternalAdapter;

    return {
      setAudioLanguage(audioLanguage: string) {
        internalAdapter.sessionData.audioLanguage = audioLanguage;
      },
      setSubtitleEnabled(enabled: boolean) {
        internalAdapter.sessionData.subtitleEnabled = enabled;
      },
      setSubtitleLanguage(subtitleLanguage: string) {
        internalAdapter.sessionData.subtitleLanguage = subtitleLanguage;
      },
    } as const;
  }

  override sourceChange(
    config: AnalyticsConfig,
    sourceMetadata?: {
      streamFormat?: string;
      audioCodec?: string;
      videoCodec?: string;
      audioLanguage?: string;
    },
  ) {
    if (!this.guardAgainstNotInitializedAnalytics()) {
      return;
    }

    super.sourceChange(config);

    const internalAdapter = this.internalAdapter as HTMLVideoElementInternalAdapter;
    internalAdapter.sessionData.streamFormat = sourceMetadata?.streamFormat;
    internalAdapter.sessionData.audioCodec = sourceMetadata?.audioCodec;
    internalAdapter.sessionData.videoCodec = sourceMetadata?.videoCodec;
    internalAdapter.sessionData.audioLanguage = sourceMetadata?.audioLanguage;
  }
}

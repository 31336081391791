import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";
import useConfig from "../../hooks/useConfig";

const HBHTableTwo = (props: any) => {
  const configData = useConfig();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{...CommonStyle.tableHeader,background:configData.primary}}
                className="statview-header-color"                colSpan={4}
              >
                <Typography variant="body2" sx={{fontStyle:"normal",color:"white"}}>{props.header}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.listData?.length? (props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className="wtd-statview-table-row-color"
                  key={index + 1}
                >
                  <TableCell
                    sx={{ width: "50px", py: 0.5 }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={
                          row.team1_src
                            ? row.team1_src
                            : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                          fontSize:"12px"
                        }}
                      >
                        {row.team1}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: "8px" }}>
                    <Typography variant="body2" sx={{fontSize:"12px"}}>{row.score_board}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, width: "50px" }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={
                          row.team2_src
                            ? row.team2_src
                            : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                          fontSize:"12px"
                        }}
                      >
                        {row.team2}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      // paddingLeft: "10px",
                      // width: "82%",
                    }}
                  >
                    <Typography sx={{fontSize:"12px" }} variant="body2">
                      {row.date} {row.mode}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              );
            })):(
              <Typography variant="body2">
                No Available
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HBHTableTwo;

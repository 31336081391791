import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Slider from "react-slick";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useConfig from "../../../hooks/useConfig";
//@ts-ignore
import styles from "./GameListHomePage.module.css";
import HighLightCard from "./HighLightCard";
import HttpService from "../../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../../utils/constants";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import { useNavigate } from "react-router-dom";
import { getCurrentAuth } from "../../../hooks/http-server";
const HighlightList = ({ planDetails, subscriptionDetails }: any) => {
  const configData = useConfig();
  const auth = getCurrentAuth();
  const userDetails = useCommonDetails();
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const hasPermition = () => {
    if (
      (planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !subscriptionDetails?.pause_collection) ||
      [UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN].includes(
        userDetails?.UserRoleID
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const GetHighLights = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetWhizzards);
      if (res) {
        setHighlights(res.data?.data?.wizzards);
      }
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetHighLights();
  }, [GetHighLights]);
  const PreviousBtn = (props: any) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            bgcolor: configData.primary,
            border: "1px solid #E7E7E7",
            p: 0.5,
            zIndex: 10,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const NextBtn = (props: any) => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            border: "1px solid #E7E7E7",
            rotate: "180deg",
            p: 0.5,
            bgcolor: configData.primary,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1440")
  );
  const [sliderRef, setSliderRef] = useState<any>(null);
  //  xs: 0,
  //       sm: 567,
  //       md: 768,
  //       lg: 992,
  //       xl: 1440,
  const sliderSettings = {
    // removes default buttons
    slidesToShow: 4,

    autoplay: false,
    autoplaySpeed: 2500,
    slidesToScroll: 2,
    infinite: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: highlights?.length > 3 ? 3 : highlights?.length,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container maxWidth="xl" sx={{ position: "relative" }}>
      <Box
        sx={{
          opacity: !hasPermition() ? 0.3 : 1,
          backgroundColor: !hasPermition() ? configData.primary : "",
          filter: !hasPermition() ? "blur(1px)" : "",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textTransform: "none",
            fontStyle: "normal",
            fontWeight: 600,
            px: 3,
            py: 1,
          }}
        >
          Highlights
        </Typography>
        {!!highlights?.length ? (
          <Box sx={{ mb: 2, px: { xs: 0, sm: 2 } }}>
            <Box className={styles.slickContainer}>
              <Slider
                ref={setSliderRef}
                {...sliderSettings}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
                dotsClass="slick-dots custom-indicator"
              >
                {highlights?.map((highlight: any) => (
                  <Box
                    onClick={() =>
                      hasPermition() &&
                      navigate(
                        {
                          pathname: PageRoutes.HighLightPlayer,
                        },
                        {
                          state: {
                            highlight,
                          },
                        }
                      )
                    }
                  >
                    <HighLightCard highlight={highlight} />
                  </Box>
                ))}
                {highlights?.length === 3 && !isMobile && <Box></Box>}
                {highlights?.length === 2 &&
                  !isMobile &&
                  new Array(2).fill(0).map((el) => <Box></Box>)}
                {highlights?.length === 1 &&
                  !isMobile &&
                  new Array(3).fill(0).map((el) => <Box></Box>)}
              </Slider>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "start", px: 3 }}>
            <Typography
              sx={{
                textTransform: "none",
                fontStyle: "normal",
              }}
            >
              There are no highlights right now
            </Typography>
          </Box>
        )}
      </Box>

      {!hasPermition() && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            width: 1,
            bottom: "50%",
            left: 0,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: configData.primary,
              fontSize: 18,
              fontWeight: 600,
            }}
            variant="body1"
          >
            {!auth.AccessToken ? (
              <Button
                variant="contained"
                onClick={() => navigate(PageRoutes.Login)}
              >
                Log in to see the highlights
              </Button>
            ) : (
              "This feature is only available to insiders"
            )}
          </Typography>
        </Box>
      )}
    </Container>
  );
};
export default HighlightList;

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";
import useConfig from "../../hooks/useConfig";

const HBHTableOne = (props: any) => {
  const configData = useConfig();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0}}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{...CommonStyle.tableHeader,background:configData.primary}}
                className="statview-header-color"                colSpan={4}
              >
                <Typography variant="body2" sx={{fontStyle:"normal",color:"white"}}>{props.header}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className="wtd-statview-table-row-color"
                >
                  <TableCell sx={{ py: 1, minWidth: "150px" }} align={"left"}>
                    <Box
                      sx={{
                        display: "flex",

                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Box>
                        <Avatar
                          src={
                            row.team1_src
                              ? row.team1_src
                              : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                          }
                          sx={{
                            borderRadius: "0px",
                            width: "36px",
                            height: "36px",
                            textAlign: "center",
                          }}
                        />
                        </Box>
                        <Typography variant="body2" sx={{fontSize:"12px"}}>
                          {row.team1_scores}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ textTransform: "capitalize",fontSize:"12px",ml:0.5 }}
                      >
                        {row.team1}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        mt:1,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Avatar
                          src={
                            row.team2_src
                              ? row.team2_src
                              : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                          }
                          sx={{
                            borderRadius: "0px",
                            width: "36px",
                            height: "36px",
                            textAlign: "center",
                          }}
                        />
                        <Typography variant="body2" sx={{fontSize:"12px"}}>
                          {row.team2_scores}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ textTransform: "capitalize",fontSize:"12px",ml:0.5 }}
                      >
                        {row.team2}
                      </Typography>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HBHTableOne;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Watch from ".";
import { Alert, Box, Collapse, IconButton, Typography } from "@mui/material";
import LoadingContext from "../../Common/Contex/LoadingContex";
import { Close } from "@mui/icons-material";

import HttpService from "../../hooks/Https-services";
import { APIRoutes } from "../../utils/constants";
export const WatchContex = createContext<any>(null);
const WatchGame = () => {
  const [participants, setParticipants] = useState([]);
  const [adsDetails, setAdDetails] = useState(null as any);
  const [isWatchAdd, setIsWatchAdd] = useState(false);
  const GetCurrentActiveAd = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetCurrentActiveAd);
      setAdDetails(res?.data?.data);
    } catch (error) {}
  }, []);
  const handleChange = () => {
    setIsWatchAdd(true);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    GetCurrentActiveAd();
  }, [GetCurrentActiveAd]);
  return (
    <WatchContex.Provider
      value={{
        participants,
        setParticipants,
        adsDetails,
        handleChange,
        isWatchAdd,
      }}
    >
      <Watch />
    </WatchContex.Provider>
  );
};
export default WatchGame;

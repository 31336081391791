import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoPageFound = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{ minHeight: "calc( 100vh - 188px)", height: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          py: 4,
          alignItems: "center",
        }}
      >
        <Typography>404 no page found</Typography>
      </Box>
    </Container>
  );
};
export default NoPageFound;

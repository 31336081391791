import { createGroup } from "./SynchronizeService";

const CHANNELS = {};

export const on = (event: string, clb: any) => {
  // @ts-ignore
  CHANNELS[event] = [...(CHANNELS[event] || []), clb];
};
export const handleSubmit = async (token: any, clientName: any) => {
  const res = await createGroup(token, clientName);
};
export const off = (event: string, clb: any) => {
  // @ts-ignore
  const handlers = [...(CHANNELS[event] || [])];

  const index = handlers.indexOf(clb);
  if (index > -1) handlers.splice(index, 1);

  // @ts-ignore
  CHANNELS[event] = handlers;
};

export const publish = (event: string, data?: any) => {
  // @ts-ignore
  const handlers = CHANNELS[event] || [];
  // @ts-ignore
  handlers.forEach((handler) => handler(data));
};

import * as Style from "./Header.style";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import {
  Container,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  MenuItem,
  SwipeableDrawer,
  Typography,
  Menu,
  Avatar,
  Badge,
} from "@mui/material";
import { Box } from "@mui/system";
import { Close, Notifications, Menu as MenuIcon } from "@mui/icons-material";
import { getCurrentAuth, Logout } from "../../../hooks/useAxiosPrivate";
import PageRoutes, { UserRoles } from "../../../utils/constants";
import "./header.css";
import React from "react";
import useCommonDetails from "../../Hooks/useCommonDetails";
import useConfig from "../../../hooks/useConfig";
import LoadingContext from "../../Contex/LoadingContex";
import HeaderSearch from "./HeaderSearch";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userData = useCommonDetails();
  const { logsCount, setIsRefresh, isRefresh }: any =
    useContext(LoadingContext);
  const [state, setState] = useState(false);
  const auth = getCurrentAuth();
  const configData = useConfig();
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = (open: boolean) => {
    setState(open);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,

        backgroundColor: "#edf2f7",
        color: "#6d7278",
        zIndex: 10,
      }}
    >
      <Container sx={{ pr: 0 }} maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box className="logo">
            <Link to={PageRoutes.HomePage}>
              <Box sx={{ textAlign: "start" }}>
                <Avatar
                  src={configData?.headerLogo}
                  sx={{
                    maxHeight: "70px",
                    width: { xs: 1, md: 1 },
                    maxWidth: "250px",
                    ">img": {
                      objectFit: "contain !important",
                      objectPosition: "left",
                    },
                    textAlign: "start",

                    borderRadius: "0px",
                  }}
                  alt="shape"
                />
              </Box>
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <HeaderSearch />
          </Box>

          <Box
            sx={{
              gap: 5,

              alignItems: "center",
            }}
          >
            <Box
              sx={{
                gap: { sm: 3, md: 4 },
              }}
            >
              <Box
                sx={{
                  // ...Style.HeaderTab,
                  position: "relative",
                }}
              ></Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleDrawer(!state)}
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",
                  },
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <SwipeableDrawer
                anchor="top"
                open={state}
                sx={{
                  ".MuiDrawer-paper": {
                    top: 60,
                  },
                }}
                onOpen={() => toggleDrawer(true)}
                onClose={() => toggleDrawer(false)}
              >
                <Box sx={Style.DrawerBlock}>
                  <IconButton
                    onClick={() => toggleDrawer(false)}
                    sx={{ mb: 1 }}
                  >
                    <Close sx={{ color: "#6d7278" }} />
                  </IconButton>

                  <Divider sx={{ mb: 1 }} />

                  <Box
                    sx={{
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    <HeaderSearch />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <ListItemButton>
                      <ListItemText
                        sx={{ span: { ...Style.HederMenu } }}
                        primary="Events"
                        onClick={() => {
                          navigate(PageRoutes.UpcomingEvent);
                          toggleDrawer(false);
                        }}
                      />
                    </ListItemButton>
                    {configData?.planpage && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Plans"
                          onClick={() => {
                            navigate(PageRoutes.Packages);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}

                    {auth.AccessToken &&
                      process.env.REACT_APP_INSIDER == "true" && (
                        <ListItemButton>
                          <ListItemText
                            sx={{ span: { ...Style.HederMenu } }}
                            primary={`My${configData.personalChannel}`}
                            onClick={() => {
                              process.env.REACT_APP_FEATURE !== "true"
                                ? navigate({
                                    pathname:
                                      PageRoutes.PersonalChannel.replace(
                                        ":id",
                                        "0"
                                      ),
                                    search: "?tab_id=1",
                                  })
                                : userData?.isChannelCreated
                                ? navigate(
                                    PageRoutes.PersonalChannel.replace(
                                      ":id",
                                      "0"
                                    )
                                  )
                                : navigate(
                                    PageRoutes.MyNAHLTV.replace(
                                      ":id",
                                      "0"
                                    ).replace(":channel_id", "0")
                                  );
                              toggleDrawer(false);
                            }}
                          />
                        </ListItemButton>
                      )}
                    {auth.AccessToken && configData?.planpage && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary={"Buy A Gift"}
                          onClick={() => {
                            navigate(PageRoutes.GiftPage);

                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {auth.AccessToken &&
                      (userData?.UserRoleID == UserRoles.HTP_ADMIN ||
                        userData?.UserRoleID == UserRoles.LEAGUE_ADMIN ||
                        userData?.UserRoleID == UserRoles.CUSTOMER_ADMIN) && (
                        <ListItemButton>
                          <ListItemText
                            sx={{ span: { ...Style.HederMenu } }}
                            primary={`ADMIN`}
                            onClick={() => {
                              navigate(
                                userData?.UserRoleID ===
                                  UserRoles.CUSTOMER_ADMIN
                                  ? PageRoutes.SliderSetting
                                  : PageRoutes.Admin
                              );

                              toggleDrawer(false);
                            }}
                          />
                        </ListItemButton>
                      )}
                    <ListItemButton
                      onClick={() => {
                        navigate(PageRoutes.ContactUS);
                      }}
                    >
                      <ListItemText
                        sx={{ span: { ...Style.HederMenu } }}
                        primary="Support"
                        onClick={() => {
                          navigate(PageRoutes.Packages);
                          toggleDrawer(false);
                        }}
                      />
                    </ListItemButton>
                    {!auth.AccessToken && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Register"
                          onClick={() => {
                            navigate(PageRoutes.Register);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {!auth.AccessToken && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Login"
                          onClick={() => {
                            navigate(PageRoutes.Login);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {auth.AccessToken && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Profile"
                          onClick={() => {
                            navigate(PageRoutes.Setting);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {auth.AccessToken && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Alerts"
                          onClick={() => {
                            navigate(PageRoutes.Alert);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {auth.AccessToken && configData?.planpage && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Account"
                          onClick={() => {
                            navigate(PageRoutes.AccountPage);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                    {auth.AccessToken && (
                      <ListItemButton>
                        <ListItemText
                          sx={{ span: { ...Style.HederMenu } }}
                          primary="Logout"
                          onClick={() => {
                            Logout();
                            navigate(PageRoutes.Login);
                            toggleDrawer(false);
                          }}
                        />
                      </ListItemButton>
                    )}
                  </Box>
                </Box>
              </SwipeableDrawer>

              {auth.AccessToken ? (
                <>
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      alignItems: "center",
                      gap: { xs: 0, md: 1 },
                    }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          navigate(PageRoutes.Alert);
                        }}
                      >
                        <Badge
                          badgeContent={logsCount ? logsCount : 0}
                          color="error"
                          max={9}
                        >
                          <Notifications />
                        </Badge>
                      </IconButton>
                    </Box>
                    <Box
                      onClick={handleClick}
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                    >
                      <IconButton>
                        <Avatar alt="Remy Sharp" src={userData?.ProfileImage} />
                      </IconButton>
                    </Box>

                    <Menu
                      id="header-menu"
                      aria-labelledby="menu-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: Style.ProfileStyle.MenuDropDown,
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      {configData?.planpage && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            navigate(PageRoutes.AccountPage);
                          }}
                          sx={{ ...Style.HederMenu }}
                        >
                          MY ACCOUNT
                        </MenuItem>
                      )}

                      <MenuItem
                        sx={{ ...Style.HederMenu }}
                        onClick={() => {
                          navigate(PageRoutes.Setting);
                          handleClose();
                        }}
                      >
                        PROFILE
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          Logout();
                          navigate(PageRoutes.Login);
                          handleClose();
                        }}
                        sx={{ ...Style.HederMenu, color: "error.main" }}
                      >
                        LOGOUT
                      </MenuItem>
                    </Menu>
                  </Box>
                </>
              ) : (
                <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
                  {" "}
                  <Box
                    sx={{
                      ...Style.HeaderTab,
                      "&:after": {
                        transform:
                          location.pathname == PageRoutes.Register
                            ? "scaleX(1)"
                            : "scaleX(0)",
                      },
                    }}
                    onClick={() => navigate(PageRoutes.Register)}
                  >
                    <Typography variant="body2" sx={Style.HederMenu}>
                      Register
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...Style.HeaderTab,
                      "&:after": {
                        transform:
                          location.pathname == PageRoutes.Login
                            ? "scaleX(1)"
                            : "scaleX(0)",
                      },
                    }}
                    onClick={() => navigate(PageRoutes.Login)}
                  >
                    <Typography variant="body2" sx={Style.HederMenu}>
                      Login
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;

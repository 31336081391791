import { Box, Typography } from "@mui/material";
import React from "react";

const TableTitle = (props: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
      <Typography
        onClick={props.onClick}
        variant="subtitle2"
        sx={{
          fontWeight: 800,
          fontSize: 16,
          color: "#000000",
          cursor: props.onClick ? "pointer" : "default",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export default TableTitle;

import { useNavigate, useParams } from "react-router-dom";
import useConfig from "../../../../hooks/useConfig";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import {
  Form,
  Formik,
  FormikContextType,
  FormikHelpers,
  FormikValues,
  useFormik,
  useFormikContext,
} from "formik";
import HttpService from "../../../../hooks/Https-services";
import { Player } from "bitmovin-player";
import { VisuallyHiddenInput } from "../../SliderSettings/CreateSliderForm/SliderForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

const AddMedia = () => {
  const navigate = useNavigate();
  const configData = useConfig();
  const { id }: any = useParams();
  const [initialValues, setIntialValue] = useState<any>({
    files: null,
    displayText: "",
    mediaType: "1",
    height: "",
    width: "",
  });

  const validationSchema = Yup.object().shape({
    displayText: Yup.string().required("Description is required"),

    mediaType: Yup.string().required("File type is required"),

    files: Yup.mixed().required("A file is required"),
  });
  const [videoFiles, setVideoFiles] = useState<any>(null);

  const GetMediaByID = useCallback(async () => {
    if (id !== "0") {
      const res = await HttpService.get(
        APIRoutes.GetAdDetailsByID.replace(":id", id)
      );
      const data = res?.data?.data;
      setIntialValue({
        start_date: new Date(data?.StartDateTime),
        end_date: new Date(data?.EndDateTime),
        channels: data?.ChannelsDetails,
        videos: data?.VideoUrls,
        files: [],
      });
    } else {
      setIntialValue({
        ...initialValues,
      });
    }
  }, [id]);

  useEffect(() => {
    GetMediaByID();
  }, [GetMediaByID]);

  return (
    <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
      <button
        onClick={() => navigate(PageRoutes.Media)}
        style={{
          cursor: "pointer",
          width: "100px",
          padding: "8px",
          margin: "10px 0px",
          fontSize: "14px",
        }}
        className={configData.buttonCommonClass}
      >
        Go Back
      </button>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Typography variant="subtitle1">
          {id == 0 ? "Add" : "Update"} Media
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={async (values: any) => {
          try {
            const formData = new FormData();
            formData.append("type", values.mediaType);
            formData.append("height", values.height);
            formData.append("width", values.width);

            formData.append("file", values.files);
            formData.append("description", values.displayText);

            if (id !== "0") {
              formData.append("videos", JSON.stringify(values.videos));
              const response = await HttpService.postImage(
                APIRoutes.UpdateAd.replace(":id", id),
                formData
              );
              toast.success("PreRoll updated successfully");
            } else {
              const res = await HttpService.postImage(
                APIRoutes.CreateMedia,
                formData
              );
              toast.success("Media created successfully");
            }
            navigate(PageRoutes.Media);
          } catch (error: any) {
            toast.error(error.response?.data?.message);
          }
        }}
      >
        {({
          handleSubmit,
          errors,
          handleChange,
          setFieldValue,
          values,
          isSubmitting,
          setValues,
          isValid,
        }) => (
          <Form>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Description"
                    label="Description"
                    id="displayText"
                    name="displayText"
                    onChange={handleChange}
                    value={values.displayText}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="mediaType">Select Media Type</InputLabel>
                    <Select
                      value={values.mediaType}
                      required
                      fullWidth
                      onChange={handleChange}
                      labelId="mediaType"
                      id="mediaType"
                      name="mediaType"
                      label="Select Media Type"
                    >
                      <MenuItem value="1">Player Overlays Images</MenuItem>
                      <MenuItem value="2">Prerolls Video</MenuItem>
                      <MenuItem value="3">Poll Images</MenuItem>
                      <MenuItem value="4">Thumbnails</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <Box>
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload {values.mediaType == 2 ? "Video" : "Image"}
                        <VisuallyHiddenInput
                          type="file"
                          accept={
                            values.mediaType == 1 ||
                            values.mediaType == 3 ||
                            values.mediaType == 4
                              ? ".png"
                              : ".mp4"
                          }
                          onChange={(e) => {
                            const fileUpload: any = e.target.files;
                            if (
                              values.mediaType == 1 ||
                              values.mediaType == 3 ||
                              values.mediaType == 4
                            ) {
                              var reader = new FileReader();

                              //Read the contents of Image File.
                              reader.readAsDataURL(fileUpload[0]);
                              reader.onload = function (e: any) {
                                //Initiate the JavaScript Image object.
                                var image: any = new Image();

                                //Set the Base64 string return from FileReader as source.
                                image.src = e.target.result;

                                //Validate the File Height and Width.
                                image.onload = function () {
                                  var height = this.height;
                                  var width = this.width;
                                  if (values.mediaType == 1) {
                                    if (height == 200) {
                                      setValues({
                                        ...values,
                                        files: fileUpload[0],
                                        height,
                                        width,
                                      });

                                      setVideoFiles(
                                        URL.createObjectURL(fileUpload[0])
                                      );
                                      return true;
                                    } else {
                                      toast.error("Images must 200px(height)");
                                      return false;
                                    }
                                  } else if (values.mediaType == 3) {
                                    if (height == 200 && width == 300) {
                                      setValues({
                                        ...values,
                                        files: fileUpload[0],
                                        height,
                                        width,
                                      });

                                      setVideoFiles(
                                        URL.createObjectURL(fileUpload[0])
                                      );
                                      return true;
                                    } else {
                                      toast.error(
                                        "Images must 200px(height) and 300px(width)"
                                      );
                                      return false;
                                    }
                                  } else if (values.mediaType == 4) {
                                    if (height == 180 && width == 320) {
                                      setValues({
                                        ...values,
                                        files: fileUpload[0],
                                        height,
                                        width,
                                      });

                                      setVideoFiles(
                                        URL.createObjectURL(fileUpload[0])
                                      );
                                      return true;
                                    } else {
                                      toast.error(
                                        "Images must 180px(height) and 320px(width)"
                                      );
                                      return false;
                                    }
                                  }
                                };
                              };
                            } else {
                              const files: any = fileUpload?.[0];
                              const video: any =
                                document.createElement("video");

                              const fileURL = URL.createObjectURL(files);
                              video.src = fileURL;

                              video.addEventListener(
                                "loadedmetadata",
                                function () {
                                  const width = video.videoWidth;
                                  const height = video.videoHeight;
                                  if (files.size >= 50 * 1024 * 1024) {
                                    toast.error(
                                      "Some files are too large (max 50MB each)"
                                    );
                                    return;
                                  }
                                  setValues({
                                    ...values,
                                    files: fileUpload[0],
                                    height: height,
                                    width: width,
                                  });
                                  setVideoFiles(URL.createObjectURL(files));
                                  URL.revokeObjectURL(fileURL); // Clean up
                                }
                              );
                            }
                          }}
                        />
                      </Button>
                    </Box>
                    <Typography
                      variant="caption"
                      color={"warning"}
                    ></Typography>
                    <Typography variant="caption" color={"warning"}>
                      {values?.mediaType == 1
                        ? "Image must be PNGs and 200px(height)"
                        : values.mediaType == 3
                        ? "Image must be PNGs, 200px(height) and 300px(width)"
                        : values.mediaType == 4
                        ? "Image must be PNGs, 180px(height) and 320px(width)"
                        : "Video must be Mp4, Max 30s long and Max 50 mb in size"}
                    </Typography>
                  </Stack>
                  {!!videoFiles && (
                    <Stack direction={"row"} gap={2} sx={{ mt: 2 }}>
                      {values?.mediaType == 1 ||
                      values?.mediaType == 3 ||
                      values?.mediaType == 4 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <img src={videoFiles} />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <video width={"100%"} controls>
                            <source src={videoFiles} type={"video/mp4"} />
                          </video>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  width: 1,

                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ maxWidth: "200px" }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      ":hover": {
                        bgcolor: configData.primary,
                      },
                    }}
                    loading={isSubmitting}
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                    style={{
                      margin: "20px 0px",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddMedia;

import {StateMachine} from 'javascript-state-machine';

import {Event, EventMap} from '../../enums/Event';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {AnalyticsEventBase, ErrorEvent, VideoStartFailedEvent} from '../../types/EventData';
import {NoExtraProperties} from '../../types/NoExtraProperties';
import {StateMachineCallbacks} from '../../types/StateMachineCallbacks';
import {logger} from '../../utils/Logger';
import * as Utils from '../../utils/Utils';
import {AnalyticsStateMachine} from '../AnalyticsStateMachine';
import {customStateMachineErrorCallback, on} from '../stateMachineUtils';

import {HeartbeatListener, HeartbeatService} from './HeartbeatService';

enum State {
  SETUP = 'SETUP',
  READY = 'READY',
  STARTUP = 'STARTUP',
  PLAYING = 'PLAYING',
  PAUSE = 'PAUSE',
  REBUFFERING = 'REBUFFERING',

  EXIT_BEFORE_VIDEOSTART = 'EXIT_BEFORE_VIDEOSTART',
  ERROR = 'ERROR',
  UNLOADED = 'UNLOADED',
  QUALITY_CHANGE = 'QUALITY_CHANGE',
  SEEKING = 'SEEKING',
}

export class AmazonIVSStateMachine extends AnalyticsStateMachine implements HeartbeatListener {
  constructor(
    stateMachineCallbacks: StateMachineCallbacks,
    private heartbeatService: HeartbeatService,
    opts: AnalyticsStateMachineOptions,
  ) {
    super(stateMachineCallbacks, opts);
  }

  override createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine {
    this.onEnterStateTimestamp = opts.starttime;
    return StateMachine.create({
      initial: State.SETUP,
      error: customStateMachineErrorCallback,
      events: [
        // STARTUP
        {name: Event.READY, from: State.SETUP, to: State.READY},
        {name: Event.PLAY, from: State.READY, to: State.STARTUP},
        {name: Event.PLAYING, from: State.STARTUP, to: State.PLAYING},

        // PLAYBACK
        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PLAYING, from: State.PAUSE, to: State.PLAYING},
        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},
        {name: Event.UNLOAD, from: State.PLAYING, to: State.UNLOADED},
        {name: Event.PLAYING, from: State.ERROR, to: State.PLAYING}, // recover from error and continue playing
        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITY_CHANGE},
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITY_CHANGE, to: State.PLAYING},

        // SEEKING
        {name: Event.SEEK, from: State.PLAYING, to: State.SEEKING},
        {name: Event.SEEKED, from: State.SEEKING, to: State.PLAYING},
        {name: Event.PAUSE, from: State.SEEKING, to: State.PAUSE},

        // BUFFERING
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        {name: Event.PLAYING, from: State.REBUFFERING, to: State.PLAYING},

        // EBVS
        {name: Event.VIDEOSTART_TIMEOUT, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.ERROR, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},
        {name: Event.UNLOAD, from: State.STARTUP, to: State.EXIT_BEFORE_VIDEOSTART},

        // ERROR
        {name: Event.ERROR, from: getAllStatesBut([State.STARTUP, State.EXIT_BEFORE_VIDEOSTART]), to: State.ERROR},

        // EVENTS TO IGNORE
        on(Event.PAUSE).stayIn(State.PAUSE),
        on(Event.PAUSE).stayIn(State.STARTUP),
        on(Event.PAUSE).stayIn(State.REBUFFERING),
        on(Event.PLAY).stayIn(State.PLAYING),
        on(Event.PLAYING).stayIn(State.PLAYING),
        on(Event.START_BUFFERING).stayIn(State.PAUSE),
        on(Event.START_BUFFERING).stayIn(State.REBUFFERING),
        on(Event.VIDEOSTART_TIMEOUT).stayIn(State.PLAYING),
        on(Event.VIDEOSTART_TIMEOUT).stayIn(State.PAUSE),
        on(Event.VIDEOSTART_TIMEOUT).stayIn(State.SEEKING),
        on(Event.UNLOAD).stayIn(State.READY),
        on(Event.UNLOAD).stayIn(State.SEEKING),

        on(Event.VIDEO_CHANGE).stayIn(State.QUALITY_CHANGE),
        on(Event.VIDEO_CHANGE).stayIn(State.STARTUP),
        on(Event.VIDEO_CHANGE).stayIn(State.REBUFFERING),
        on(Event.VIDEO_CHANGE).stayIn(State.PAUSE),
        on(Event.VIDEO_CHANGE).stayIn(State.ERROR),
        on(Event.VIDEO_CHANGE).stayIn(State.EXIT_BEFORE_VIDEOSTART),
        on(Event.VIDEO_CHANGE).stayIn(State.READY),
        on(Event.VIDEO_CHANGE).stayIn(State.SEEKING),

        // Seeking
        on(Event.SEEK).stayIn(State.SEEKING),
        on(Event.SEEK).stayIn(State.PAUSE),
        on(Event.SEEK).stayIn(State.QUALITY_CHANGE),
        on(Event.SEEK).stayIn(State.REBUFFERING),
        on(Event.SEEK).stayIn(State.ERROR),
        on(Event.SEEK).stayIn(State.STARTUP),
        on(Event.SEEK).stayIn(State.READY),
        on(Event.SEEK).stayIn(State.EXIT_BEFORE_VIDEOSTART),
        on(Event.SEEKED).stayIn(State.PAUSE),
        on(Event.SEEKED).stayIn(State.QUALITY_CHANGE),
        on(Event.SEEKED).stayIn(State.REBUFFERING),
        on(Event.SEEKED).stayIn(State.ERROR),
        on(Event.SEEKED).stayIn(State.STARTUP),
        on(Event.SEEKED).stayIn(State.READY),
        on(Event.SEEKED).stayIn(State.EXIT_BEFORE_VIDEOSTART),
      ],
      callbacks: {
        onenterstate: (
          event: string | undefined,
          from: string | undefined,
          to: string | undefined,
          timestamp: number,
          eventObject: AnalyticsEventBase,
        ) => {
          if (from == 'none' || to === undefined || event === undefined) {
            return;
          }

          if (eventObject) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          logger.log(`[ENTER ${timestamp}] EVENT: ${event} \nfrom: ${from}\t to: ${to}`);
          this.onEnterStateTimestamp = timestamp || Utils.getCurrentTimestamp();
          this.getStateHandler()[to].onenterstate(event, eventObject);
        },
        onleavestate: (
          event: string | undefined,
          from: string | undefined,
          to: string | undefined,
          timestamp: number,
          eventObject: AnalyticsEventBase,
        ) => {
          if (!timestamp || from == 'none' || from === undefined || to === undefined) {
            return;
          }

          if (eventObject) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          const stateDuration = timestamp - this.onEnterStateTimestamp;
          this.getStateHandler()[from].onleavestate(stateDuration, to);
        },
      },
    });
  }

  override callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      exec.call(this.stateMachine, timestamp, eventObject);
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  onHeartbeat(eventObject: AnalyticsEventBase) {
    this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

    const timestamp = Utils.getCurrentTimestamp();
    const stateDuration = timestamp - this.onEnterStateTimestamp;
    this.stateMachineCallbacks.heartbeat(stateDuration, 'playing', {played: stateDuration});
    this.onEnterStateTimestamp = timestamp;

    this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
  }

  private getStateHandler = (): StateHandlerList => {
    return {
      [State.SETUP]: {
        onenterstate: () => undefined,
        onleavestate: (duration: number) => {
          this.stateMachineCallbacks.setup(duration, State.SETUP.toLowerCase());
        },
      },
      [State.READY]: {
        onenterstate: () => undefined,
        onleavestate: () => undefined,
      },
      [State.STARTUP]: {
        onenterstate: () => {
          this.setVideoStartTimeout();
        },
        onleavestate: (duration, to) => {
          this.clearVideoStartTimeout();
          if (to == State.PLAYING) {
            this.stateMachineCallbacks.startup(duration, State.STARTUP.toLowerCase());
          }
        },
      },
      [State.PLAYING]: {
        onenterstate: () => {
          this.heartbeatService.startHeartbeat();
        },
        onleavestate: (duration) => {
          this.heartbeatService.stopHeartbeat();
          this.stateMachineCallbacks.playing(duration, State.PLAYING.toLowerCase());
        },
      },
      [State.PAUSE]: {
        onenterstate: () => undefined,
        onleavestate: (duration) => {
          this.stateMachineCallbacks.pause(duration, State.PAUSE.toLowerCase());
        },
      },
      [State.REBUFFERING]: {
        onenterstate: () => {
          this.startRebufferingHeartbeatInterval();
        },
        onleavestate: (duration) => {
          this.resetRebufferingHelpers();
          this.stateMachineCallbacks.rebuffering(duration, State.REBUFFERING.toLowerCase());
        },
      },
      [State.EXIT_BEFORE_VIDEOSTART]: {
        onenterstate: (event: string, eventData: AnalyticsEventBase) => {
          const failedEvent: VideoStartFailedEvent = {
            reason: this.getReasonForVideoStartFailure(event),
          };
          const isErrorEvent = event === Event.ERROR;
          const shouldSendVideoStartupSample = !isErrorEvent;
          this.stateMachineCallbacks.videoStartFailed(failedEvent, shouldSendVideoStartupSample);
          if (isErrorEvent) {
            this.stateMachineCallbacks.error(eventData as ErrorEvent);
          }
        },
        onleavestate: () => undefined,
      },
      [State.ERROR]: {
        onenterstate: (event: string, eventData: AnalyticsEventBase) => {
          this.stateMachineCallbacks.error(eventData as ErrorEvent);
        },
        onleavestate: () => undefined,
      },
      [State.UNLOADED]: {
        onenterstate: () => {
          this.stateMachineCallbacks.unload(0, State.UNLOADED);
        },
        onleavestate: () => undefined,
      },
      [State.QUALITY_CHANGE]: {
        onenterstate: (event, eventData) => {
          this.stateMachine.FINISH_QUALITYCHANGE(this.onEnterStateTimestamp, eventData);
        },
        onleavestate: () => {
          this.stateMachineCallbacks.qualitychange(0, State.QUALITY_CHANGE.toLowerCase());
        },
      },
      [State.SEEKING]: {
        onenterstate: () => undefined,
        onleavestate: (duration) => {
          this.stateMachineCallbacks.end_play_seeking(duration, State.SEEKING.toLowerCase());
        },
      },
    };
  };
}

const getAllStatesBut = (states: string[]) => {
  return getAllStates().filter((i) => states.indexOf(i) < 0);
};

const getAllStates = () => {
  return Object.keys(State).map((key) => State[key]);
};

type StateHandler = {
  onenterstate: (event: string, eventData: AnalyticsEventBase) => void;
  onleavestate: (duration: number, to: string) => void;
};

type StateHandlerList = Record<State, StateHandler>;

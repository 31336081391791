import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Skeleton, Stack, TextField } from "@mui/material";
import useConfig from "../../../../../hooks/useConfig";
import DownloadedClipsTable from "../../../../DownloadTabs/DownloadClipsList/DownloadedClipsTable";
import HttpService from "../../../../../hooks/Https-services";
import { APIRoutes } from "../../../../../utils/constants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DialogBox from "../../../../../Common/Component/DialogBox";

export default function UserDownloadCredit({
  userDownloadCredit,
  GetUserDownloadCreditByID,
}: any) {
  const configData = useConfig();
  const [expand, setExpand] = React.useState(false);
  const { id }: any = useParams();
  const [clipsData, setClipsData] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [downloadCredit, setDownloadCredit] = React.useState(
    userDownloadCredit?.ClipsCredit
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handelUpdateCredit = async () => {
    setIsLoadingButton(true);
    if (!downloadCredit) {
      toast.error("Please enter valid value");
      return;
    }
    if (downloadCredit > 20) {
      toast.error("Download credit should be less than 20");
      return;
    }
    try {
      const res = await HttpService.post(
        APIRoutes.UpdateUserDownloadCredit.replace(":id", id),
        {
          credit: downloadCredit,
        }
      );

      toast.success("Credit Updated Successful");
      GetUserDownloadCreditByID();
      handleClose();
      setIsLoadingButton(false);
    } catch (error: any) {
      setIsLoadingButton(false);
      toast.error(error.response.data.message);
    }
  };
  const GetUserDownLoadClipsByID = async () => {
    try {
      if (isLoading) {
        const res = await HttpService.get(
          APIRoutes.GetUserAllDownloadedVideoClipsByUserID.replace(":id", id)
        );
        setClipsData(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };
  const toggleAccordion = () => {
    setExpand((prev) => (prev = !prev));
    if (!expand) {
      GetUserDownLoadClipsByID();
    }
  };
  return (
    <div style={{ marginTop: "16px" }}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon onClick={toggleAccordion} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack
            sx={{ width: 1 }}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography
                variant="body1"
                sx={{
                  fontStyle: "normal",
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Tokens Remaining : {userDownloadCredit?.ClipsCredit}
              </Typography>
            </Stack>
            <Box>
              <button
                onClick={(e: any) => {
                  e.preventDefault();
                  handleClickOpen();
                }}
                style={{ fontSize: "14px" }}
                className={configData.buttonCommonClass}
              >
                Update Credit
              </button>
            </Box>
            <DialogBox
              open={open}
              handleClose={handleClose}
              buttonAction={() => handleClose()}
              buttonActionSecondary={() => handelUpdateCredit()}
              butttonTextSecondary={"Update"}
              buttonText={"Cancel"}
              content={
                <Box>
                  <TextField
                    type="tel"
                    size="small"
                    value={downloadCredit}
                    onChange={(e: any) => setDownloadCredit(e.target.value)}
                    id="outlined-basic"
                    label="Update Credit"
                    variant="outlined"
                  />
                </Box>
              }
            />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <Skeleton variant="rounded" sx={{ p: 2 }} />
          ) : (
            <DownloadedClipsTable isInsider={true} clipsData={clipsData} />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

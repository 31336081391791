import {AnalyticsStateMachine} from '../../analyticsStateMachines/AnalyticsStateMachine';
import {EventDispatcher} from '../../core/EventDispatcher';
import QualityChangeService from '../../core/QualityChangeService';
import {SourceInfoFallbackService} from '../../core/SourceInfoFallbackService';
import {Event, EventMap} from '../../enums/Event';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {CustomDataValues} from '../../types/CustomDataValues';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {NoExtraProperties} from '../../types/NoExtraProperties';
import {SegmentInfo} from '../../types/SegmentInfo';
import {defaultStateMachineCallbacks, StateMachineCallbacks} from '../../types/StateMachineCallbacks';
import {logger} from '../../utils/Logger';
import {getCurrentTimestamp} from '../../utils/Utils';
import {WindowEventTracker} from '../../utils/WindowEventTracker';

import {EventCallbackDispatcher} from './EventCallbackDispatcher';

/**
 * Audio volume below this delta is considered zero.
 */
const volumeDelta = 0.01;

export abstract class InternalAdapter implements EventCallbackDispatcher {
  stateMachineCallbacks: StateMachineCallbacks = {...defaultStateMachineCallbacks};
  qualityChangeService: QualityChangeService = new QualityChangeService();
  protected stateMachine!: AnalyticsStateMachine;
  protected opts: AnalyticsStateMachineOptions;
  protected _onLicenseKeyReceived: EventDispatcher<{licenseKey: string}> = new EventDispatcher();
  protected _onLicenseCallFailed: EventDispatcher<any> = new EventDispatcher();

  protected sourceInfoFallbackService: SourceInfoFallbackService = new SourceInfoFallbackService();
  protected drmPerformanceInfo: DrmPerformanceInfo | undefined = undefined;
  protected abstract get currentTime(): number;

  private _windowEventTracker: WindowEventTracker = new WindowEventTracker();

  get windowEventTracker(): WindowEventTracker {
    return this._windowEventTracker;
  }

  get onLicenseKeyReceived() {
    return this._onLicenseKeyReceived;
  }

  get onLicenseCallFailed() {
    return this._onLicenseCallFailed;
  }

  get downloadSpeedInfo(): DownloadSpeedInfo {
    return {
      segmentsDownloadCount: 0,
      segmentsDownloadSize: 0,
      segmentsDownloadTime: 0,
      avgDownloadSpeed: 0,
      minDownloadSpeed: 0,
      maxDownloadSpeed: 0,
      avgTimeToFirstByte: 0,
    };
  }

  get segments(): SegmentInfo[] {
    return [];
  }

  constructor(opts?: AnalyticsStateMachineOptions) {
    if (!opts) {
      opts = {
        starttime: undefined,
      };
    }
    if (!opts.starttime) {
      opts.starttime = getCurrentTimestamp();
    }

    this.opts = opts;
  }

  release() {
    this.stateMachine.resetIntervals();
    this.stateMachineCallbacks.release();
    this.resetSourceRelatedState();
    this._windowEventTracker.release();
  }

  eventCallback = <StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
  ) => {
    eventObject = eventObject || {};
    if (!this.stateMachine) {
      logger.log("Bitmovin Analytics: StateMachine isn't ready yet");
    } else {
      this.stateMachine.callEvent(eventType, eventObject, getCurrentTimestamp());
    }
  };

  getCommonPlaybackInfo() {
    let scale = 1;
    if (window.devicePixelRatio > 0) {
      scale = window.devicePixelRatio;
    }

    return {
      screenHeight: window.screen.height * scale,
      screenWidth: window.screen.width * scale,
    };
  }

  clearValues(): void {
    // noop by default
  }

  clearSegments(): void {
    // noop by default
  }

  resetSourceRelatedState(): void {
    this.sourceInfoFallbackService.reset();
    this.drmPerformanceInfo = undefined;
  }

  setCustomData(values: CustomDataValues): void {
    const previousState = this.stateMachine.currentState;

    if (
      this.stateMachine.currentState &&
      (this.stateMachine.currentState === 'PAUSE' || this.stateMachine.currentState === 'PLAYING')
    ) {
      this.eventCallback(Event.CUSTOM_DATA_CHANGE, {
        currentTime: this.currentTime,
      });
      this.eventCallback(Event[previousState], {
        values,
        currentTime: this.currentTime,
      });
    } else {
      this.stateMachineCallbacks.customdatachange(undefined, undefined, {values});
    }
  }

  protected isAudioMuted(playerIsMuted: boolean, currentVolume: number): boolean {
    return playerIsMuted || currentVolume < volumeDelta;
  }
}

import React, { createContext, useEffect, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../style/core/config/theme";
import useConfig from "../../hooks/useConfig";

const ThemeContext: any = createContext(null);

const ThemeProviderReact = ({ children }: any) => {
  const configData = useConfig();

  const [primaryColor, setPrimaryColor] = useState({
    primary: configData.primary,
    secondary: configData.secondary,
  });
  const applyTheme = theme(primaryColor);

  return (
    <ThemeContext.Provider value={{ primaryColor, applyTheme }}>
      <MuiThemeProvider theme={applyTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProviderReact, ThemeContext };

import {DeferredLicenseLoadingAdapterAPI} from '../adapters/internal/DeferredLicenseLoadingAdapterAPI';
import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {AuthenticationCallback} from '../types/AuthenticationCallback';
import {LicenseCallFunc, LicensingRequest, LicensingResponse, LicensingResult} from '../types/LicensingRequest';
import {Sample} from '../types/Sample';
import {logger} from '../utils/Logger';
import {ANALYTICS_LICENSECALL_TIMEOUT} from '../utils/Settings';

import {Backend} from './Backend';
import {NoOpBackend} from './NoOpBackend';
import {QueueBackend} from './QueueBackend';
import {RemoteBackend} from './RemoteBackend';

export class LicenseCheckingBackend implements Backend {
  promise?: Promise<LicensingResponse>;
  private licenseLazyLoadingTimeoutHandle?: number;
  private backend: Backend;
  private info: LicensingRequest;

  constructor(
    info: LicensingRequest,
    private licenseCall: LicenseCallFunc,
    private backendBaseUrl: string,
    private adapter: DeferredLicenseLoadingAdapterAPI,
    private authenticationCallback: AuthenticationCallback,
  ) {
    this.info = {...info};
    this.backend = new QueueBackend();
    this.licenseCall = licenseCall;

    if (info.key !== undefined && info.key !== '') {
      this.promise = this.wrapLicenseCheckPromiseWithCallback(this.performLicenseCheck(), this.authenticationCallback);
    } else if (adapter.supportsDeferredLicenseLoading === true) {
      adapter.onLicenseKeyReceived.subscribe(this.licenseKeyReceived);
      adapter.onLicenseCallFailed.subscribe(this.licenseCallFailed);
      this.licenseLazyLoadingTimeoutHandle = window.setTimeout(
        this.licenseLazyLoadingTimeout,
        ANALYTICS_LICENSECALL_TIMEOUT,
      );
    } else {
      authenticationCallback.authenticationCompleted(false, undefined);
      this.backend = new NoOpBackend();
    }
  }

  performLicenseCheck(): Promise<LicensingResponse> {
    const {key, domain, version} = this.info;
    if (!key || key === '') {
      const error: LicensingResponse = {
        status: LicensingResult.Denied,
        message: 'No license key provided',
      };
      this.backend = new NoOpBackend();
      return Promise.resolve(error);
    }

    return this.licenseCall(key, domain, version, this.backendBaseUrl)
      .then((result) => {
        if (result.status === LicensingResult.Granted) {
          const remoteBackend = new RemoteBackend(true, this.backendBaseUrl, key);
          (this.backend as QueueBackend).flushTo(remoteBackend);
          this.backend = remoteBackend;
        } else {
          throw new Error(result.message);
        }
        return result;
      })
      .catch((err: Error) => {
        if (err.message === 'Ignoring Impression due to DNT Header being set') {
          logger.error('License Check for Bitmovin Analytics failed because of', err);
        } else {
          logger.errorMessageToUser('License Check for Bitmovin Analytics failed because of', err);
        }
        this.backend = new NoOpBackend();
        return {
          status: LicensingResult.Denied,
          message: err.message,
        };
      });
  }

  sendRequest(sample: Sample) {
    this.backend.sendRequest(sample);
  }
  sendUnloadRequest(sample: Sample) {
    this.backend.sendUnloadRequest(sample);
  }
  sendRequestSynchronous(sample: Sample) {
    this.backend.sendRequestSynchronous(sample);
  }
  sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.backend.sendAdRequest(sample);
  }

  private licenseKeyReceived = (eventArgs: {licenseKey: string}) => {
    clearTimeout(this.licenseLazyLoadingTimeoutHandle);
    this.unsubscribeFromAdapter();
    this.info.key = eventArgs.licenseKey;
    this.promise = this.wrapLicenseCheckPromiseWithCallback(this.performLicenseCheck(), this.authenticationCallback);
  };

  private licenseCallFailed = () => {
    clearTimeout(this.licenseLazyLoadingTimeoutHandle);
    this.unsubscribeFromAdapter();
    this.backend = new NoOpBackend();
    this.authenticationCallback.authenticationCompleted(false, undefined);
  };

  private licenseLazyLoadingTimeout = () => {
    this.unsubscribeFromAdapter();
    this.backend = new NoOpBackend();
    this.authenticationCallback.authenticationCompleted(false, undefined);
  };

  private unsubscribeFromAdapter() {
    this.adapter.onLicenseKeyReceived.unsubscribe(this.licenseKeyReceived);
    this.adapter.onLicenseCallFailed.unsubscribe(this.licenseCallFailed);
  }

  private wrapLicenseCheckPromiseWithCallback(
    promise: Promise<LicensingResponse>,
    authenticationCallback: AuthenticationCallback,
  ): Promise<LicensingResponse> {
    return promise.then((response) => {
      authenticationCallback.authenticationCompleted(response.status === LicensingResult.Granted, response.features);
      return response;
    });
  }
}

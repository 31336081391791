import { Edit } from "@mui/icons-material";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingContext from "../../Common/Contex/LoadingContex";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import { Logout } from "../../hooks/useAxiosPrivate";
import commonDetailSlice from "../../store/reducers/commonReducer";
import PageRoutes from "../../utils/constants";
import "./index.css";
const MyAccount = (props: any) => {
  const userData = useCommonDetails();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoadingPayment }: any = useContext(LoadingContext);

  return (
    <div className="container-fluid">
      {isLoadingPayment && (
        <Box
          sx={{
            position: "absolute",
            bottom: "50%",
            top: "50%",
            right: 0,
            zIndex: 1301,
            left: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                gap: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Your order is being processed.
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Please do not refresh the page
              </Typography>

              <CircularProgress
                size={48}
                sx={{
                  color: "#bf311a",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={
          isLoadingPayment
            ? {
                "-webkit-filter": "blur(5px)",
                filter: " blur(5px)",
              }
            : {}
        }
        className="main-content padding-top"
      >
        <div className="container">
          <div className="row">{props.children}</div>
        </div>
      </Box>
    </div>
  );
};
export default MyAccount;

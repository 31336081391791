import { PlayerDecorator } from "@sscale/syncsdk";
import { PlayerEvent } from "bitmovin-player";
import { isSafariOriOS } from "../helper/device";

export class DemoBitmovinPlayer extends PlayerDecorator {
  private currentPosition: number = 0;

  public load() {
    this.player.on(PlayerEvent.Metadata, (e: any) => {
      const timestamp = DemoBitmovinPlayer.getTimestampFromMetadata(e);

      if (timestamp) {
        this.currentPosition = timestamp || 0;
      }
    });
  }

  public getPrecisionThreshold() {
    // Improve experience with stuttering issue on iOS and Safari
    if (isSafariOriOS()) {
      return 350;
    }

    return 5;
  }

  public isStalled(): boolean {
    try {
      return this.player.isStalled();
    } catch (e) {
      console.error("Is stalled error", e);
    }

    return false;
  }

  public isSeekable(): boolean {
    try {
      return !this.player.isLive();
    } catch (e) {
      console.error("Is seekable error", e);
    }

    return false;
  }

  public play(): void {
    try {
      this.player.play();
    } catch (e) {
      console.error("Play error:", e);
    }
  }

  public mute(): void {
    try {
      this.player.mute();
    } catch (e) {
      console.error("Mute error:", e);
    }
  }

  public unmute(): void {
    try {
      this.player.unmute();
    } catch (e) {
      console.error("Unmute error:", e);
    }
  }

  public pause(): void {
    try {
      this.player.pause();
    } catch (e) {
      console.error("Pause error:", e);
    }
  }

  public getCurrentPosition(): number {
    try {
      return (
        this.currentPosition || Math.round(this.player.getCurrentTime() * 1000)
      );
    } catch (e) {
      console.error("Get current position error:", e);
    }

    return 0;
  }

  public fastSeekToPosition(position: number) {
    if (position != null) {
      try {
        window.sdkSeek = true;
        if (this.player.isLive()) {
          this.player.timeShift(
            position / 1000 -
              this.player.getCurrentTime() +
              this.player.getTimeShift()
          );
        } else {
          this.player.seek(position / 1000);
        }
      } catch (e) {
        console.error("Fast seek error:", e);
      }
    }
  }

  public isPlaying(): boolean {
    try {
      return this.player.isPlaying();
    } catch (e) {
      console.error("Is playing error:", e);
    }

    return false;
  }

  public getPlaybackRate(): number {
    try {
      return this.player.getPlaybackSpeed();
    } catch (e) {
      console.error("Get playback rate error:", e);
    }

    return 0;
  }

  public changePlaybackRate(rate: number): void {
    try {
      // Improve experience with stuttering issue on iOS and Safari
      if (isSafariOriOS()) {
        if (rate > 1) {
          rate = Math.ceil(rate * 10) / 10 + 0.3;
        }

        if (
          Math.ceil(rate * 100) / 100 ===
          Math.ceil(this.getPlaybackRate() * 100) / 100
        ) {
          return;
        }
      }

      this.player.setPlaybackSpeed(rate);
    } catch (e) {
      console.error("Set playback rate error:", e);
    }
  }

  public setVolume(volume: number) {
    try {
      this.player.setVolume(volume * 100);
    } catch (e) {
      console.error("Set volume error:", e);
    }
  }

  private static getTimestampFromMetadata(e: any) {
    try {
      let data;
      for (const frame of e.metadata.frames) {
        if (typeof frame.data === "string") {
          data = frame.data;
        } else if (Array.isArray(frame.data)) {
          data = "";

          for (const code of frame.data) {
            data += String.fromCharCode(code);
          }
        }
      }

      if (!data || data.length === 0) {
        return null;
      }

      data = data.substring(data.indexOf("{"), 1 + data.lastIndexOf("}"));
      const json = JSON.parse(data);

      const timestamp = Number(json.ut || json.ut);
      if (isNaN(timestamp)) {
        return null;
      }

      return timestamp;
    } catch (e) {
      return null;
    }
  }
}

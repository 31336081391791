import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { toast } from "react-toastify";
import HttpService from "../../../hooks/Https-services";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useConfig from "../../../hooks/useConfig";
import axios from "../../../utils/axios";
import { APIRoutes } from "../../../utils/constants";

const CardDetails = ({
  customerId,
  subscriptionId,
  handleClose,
  handleChangeRefresh,
}: any) => {
  const [error, setError] = useState("");
  const elements = useElements();
  const stripe: any = useStripe();
  const [loading, setLoading] = useState(false);
  const configData = useConfig();
  const handlePaymentMethodUpdate = async () => {
    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet. Please try again later.");
      return;
    }
    setLoading(true);
    // Get the payment method details from Stripe Elements
    const cardElement = elements.getElement(CardElement);
    try {
      const response = await HttpService.post(APIRoutes.GetClientSecret, {
        customerId: customerId,
      });

      const result = await stripe.confirmCardSetup(
        response.data.data.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              // Add billing details if needed
            },
          },
        }
      );
      if (result?.error) {
        toast.error(result?.error?.message);
        setLoading(false);
        return;
      }
      const updatedPayment = await HttpService.post(
        APIRoutes.UpdatePaymentMethod,
        {
          customerId: customerId,
          paymentId: result?.setupIntent?.payment_method,
          subscriptionId: subscriptionId,
        }
      );
      handleChangeRefresh();
      handleClose();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
    // if (error) {
    //   setError(error.message);
    //   return;
    // }
  };

  return (
    <Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            sx={{
              maxWidth: "700px",
              width: 1,
              minWidth: "300px",
            }}
          >
            <CardElement />
            {error && <Typography variant="caption">{error}</Typography>}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          disabled={loading}
          style={{ width: "auto", fontSize: "14px" }}
          className={configData.buttonCommonClass}
          onClick={handlePaymentMethodUpdate}
        >
          Update Payment Method
        </button>
      </DialogActions>
    </Box>
  );
};
export default CardDetails;

import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import EllipsisContent from "../../EllipsisContent";
import { useNavigate } from "react-router-dom";
import PageRoutes from "../../../../utils/constants";
import momentTimezone from "moment-timezone";
import useConfig from "../../../../hooks/useConfig";

const userTimeZone = momentTimezone.tz.guess();
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ScheduleTable({ data, setFilter }: any) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("567"));
  const navigate = useNavigate();
  const configData = useConfig();
  const handleNavigate = (route: any) => {
    navigate(route);
  };
  const renderImage = (game: any) => {
    return (
      <Box
        // className="teamName"
        sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
      >
        <Box
          component={"img"}
          title="Image"
          alt={game?.game_id}
          sx={{
            objectFit: "contain",
            width: 1,
            height: "50px",
            borderRadius: 2,
          }}
          src={game?.card_image_url_wide}
        />
      </Box>
    );
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableBody>
            {data?.length > 0 && data ? (
              data.map((row: any) => (
                <StyledTableRow
                  onClick={() =>
                    handleNavigate(
                      PageRoutes.Watch.replace(":gameId", row?.game_id)
                    )
                  }
                  key={row.name}
                >
                  <StyledTableCell
                    align="left"
                    sx={{
                      pr: 0,
                      width: "120px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        fontSize: { xs: "10px", sm: "12px" },
                        textTransform: "capitalize",
                      }}
                    >
                      {moment(row.game_date).format("MMM. DD")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        fontSize: { xs: "10px", sm: "12px" },
                        textTransform: "capitalize",
                      }}
                    >
                      {momentTimezone
                        .tz(
                          row.game_date_time,
                          "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                          "America/New_York"
                        )
                        .tz(userTimeZone)
                        .format("h:mm a z")
                        .toUpperCase()}{" "}
                    </Typography>
                  </StyledTableCell>
                  {!!row?.card_image_url_wide && (
                    <StyledTableCell
                      align="left"
                      colSpan={2}
                      sx={{ pr: 0, width: "150px" }}
                    >
                      {" "}
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        {renderImage(row)}
                      </Stack>
                    </StyledTableCell>
                  )}

                  {!row?.card_image_url && (
                    <StyledTableCell
                      align="left"
                      sx={{ pr: 0, width: "150px" }}
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Avatar src={row?.away_team_logo} />
                        <Stack>
                          <Typography
                            variant="body2"
                            sx={{
                              fontStyle: "normal",
                              fontSize: { xs: "10px", sm: "12px" },
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.away_team}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontStyle: "normal",
                              fontSize: { xs: "10px", sm: "12px" },
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.away_team_mascot}
                          </Typography>
                        </Stack>
                      </Stack>

                      {isMobile && (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          sx={{ mt: 1 }}
                        >
                          <Avatar src={row?.home_team_logo} />
                          <Stack>
                            <Typography
                              variant="body2"
                              sx={{
                                fontStyle: "normal",
                                fontSize: { xs: "10px", sm: "12px" },
                                whiteSpace: "nowrap",
                                textTransform: "capitalize",
                              }}
                            >
                              {row?.home_team}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                whiteSpace: "nowrap",
                                fontSize: { xs: "10px", sm: "12px" },
                                fontStyle: "normal",
                                textTransform: "capitalize",
                              }}
                            >
                              {row?.home_team_mascot}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </StyledTableCell>
                  )}

                  {!isMobile && !row?.card_image_url && (
                    <StyledTableCell
                      sx={{ pr: 0, width: "150px" }}
                      align="left"
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Avatar src={row?.home_team_logo} />
                        <Stack>
                          <Typography
                            variant="caption"
                            sx={{
                              fontStyle: "normal",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.home_team}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: "nowrap",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.home_team_mascot}
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                  )}

                  <StyledTableCell sx={{ pr: 0 }} align="left">
                    <Box>
                      <Typography
                        variant="body2"
                        maxWidth={{ xs: "auto", sm: "130px" }}
                        sx={{
                          fontStyle: "normal",
                          fontSize: { xs: "10px", sm: "12px" },
                          textTransform: "capitalize",
                        }}
                      >
                        {row?.facility_name}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontStyle: "normal",
                  textTransform: "capitalize",
                }}
              >
                No games found to releted search
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ScheduleTable;

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import { useFormikContext } from "formik";
import useConfig from "../../../../../hooks/useConfig";
const SelectVideo = () => {
  const [listOfMedia, setListOfMedia] = useState([]);
  const { setFieldValue, values }: any = useFormikContext();
  const configData = useConfig();
  const [selectedMedia, setSelectedMedia] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const GetMediaList = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.getMediaList}?isActive=${true}&type=2`
      );
      setListOfMedia(res?.data?.data);
    } catch (error) {}
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    GetMediaList();
  }, [GetMediaList]);
  useEffect(() => {
    setSelectedMedia(values.videos);
  }, [values]);
  return (
    <Box>
      <Button variant="contained" onClick={handleOpen}>
        Select Library
      </Button>
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        sx={{
          width: 1,
          ".MuiDialog-paper": {
            width: 1,
          },
        }}
        open={open}
      >
        <DialogTitle
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          Library
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            {!!listOfMedia.length ? (
              listOfMedia?.map((el: any, index: number) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index + 1}
                  >
                    <img
                      style={{ maxWidth: "250px" }}
                      src={el?.Thumbnail}
                      alt=""
                    />
                    <Box>
                      <Checkbox
                        disabled={
                          selectedMedia?.length >= 2 &&
                          selectedMedia?.findIndex(
                            (ele: any) => ele.videoUrl === el?.MediaUrl
                          ) === -1
                        }
                        checked={
                          selectedMedia?.findIndex(
                            (ele: any) => ele.videoUrl === el?.MediaUrl
                          ) !== -1
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedMedia([
                              ...selectedMedia,
                              {
                                videoUrl: el?.MediaUrl,
                                duration: 0,
                                thumbnailUrl: el?.Thumbnail,
                              },
                            ]);
                          } else {
                            const data = selectedMedia?.filter(
                              (ele: any) => ele.videoUrl !== el?.MediaUrl
                            );
                            setSelectedMedia(data);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(PageRoutes.MediaCreate.replace(":id", "0"))
                  }
                >
                  Add Media
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ ":hover": { bgcolor: configData.primary } }}
            onClick={() => {
              setFieldValue("videos", selectedMedia);
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default SelectVideo;

import { Box } from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import EllipsisContent from "../../../../Common/Component/EllipsisContent";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import useConfig from "../../../../hooks/useConfig";
import PageRoutes, { getUrl } from "../../../../utils/constants";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
const userTimeZone = momentTimezone.tz.guess();

const HighLightCard = ({ highlight }: { highlight: any }) => {
  const navigate = useNavigate();
  const configData = useConfig();
  const gaEventTracker = useAnalyticsEventTracker("upcoming-events");
  const handleNavigate = (game: any) => {
    gaEventTracker("watch", `watch${game?.game_id}`);
    navigate(PageRoutes.Watch.replace(":gameId", game?.game_id));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const checkGameTimeIsBeetween48Hour = (time: any) => {
    const formatedTime = momentTimezone
      .tz(time, "YYYY-MM-DD hh:mm:ss.SSSSSS a", "America/New_York")
      .tz(userTimeZone);

    const currentTime = moment(moment(), "YYYY-MM-DD hh:mm:ss.SSSSSS a");
    const futureTime = moment(
      moment().add(2, "days"),
      "YYYY-MM-DD hh:mm:ss.SSSSSS a"
    );

    if (formatedTime.isBefore(futureTime)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,

        justifyContent: { xs: "center", xl: "flex-start" },
      }}
      id={highlight?.ID}
    >
      <Box sx={{ flexGrow: 1, maxWidth: 300 }}>
        <Box sx={{ py: 0.5 }}>
          <EllipsisContent
            variant="body2"
            maxWidth={"100%"}
            text={moment(
              highlight.HighlightDate,
              "YYYY-MM-DD hh:mm:ss.SSSSSS a Z"
            ).format("MMM DD YYYY")}
            sx={{
              textTransform: "uppercase",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#636363",
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: 1 },
            paddingTop: "5px",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: { xs: "auto", sm: 330 },

            backgroundSize: "100% 100%",
          }}
          className="card-update"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute" }}>
              <PlayCircleOutlineIcon sx={{ color: "#fff", fontSize: 24 }} />
            </Box>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              src={
                highlight?.IsAutomated && !highlight?.Thumbnail
                  ? getUrl(highlight?.HighlightUrl)
                  : highlight.Thumbnail
              }
              width={"100%"}
            />
          </Box>
        </Box>
        <Box sx={{ py: 0.5 }}>
          <EllipsisContent
            variant="caption"
            maxWidth={"100%"}
            text={highlight?.Title}
            sx={{
              textTransform: "uppercase",

              fontStyle: "normal",
              fontWeight: 500,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <EllipsisContent
            maxWidth={220}
            text={`${highlight.Description}`}
            variant="body2"
            sx={{
              textTransform: "uppercase",

              fontStyle: "normal",
              fontWeight: 500,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HighLightCard;

export const setCookie = (
  name: string,
  value: string,
  attributes?: {domain?: string; maxAge?: number; expires?: string},
): void => {
  let newCookie = name + '=' + value + '; path=/';

  if (attributes?.domain) {
    newCookie += `; domain=${attributes.domain}`;
  }

  if (attributes?.expires) {
    newCookie += `; expires=${attributes.expires}`;
  }

  if (attributes?.maxAge) {
    newCookie += `; max-age=${attributes.maxAge}`;
  }

  document.cookie = newCookie;
};

/** @see https://www.w3schools.com/js/js_cookies.asp */
export const getCookie = (cname: string): string => {
  const name = cname + '=';
  const allCookies = document.cookie.split(';');
  for (const c of allCookies) {
    let cookie = c;
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1); //trim spaces (if any) between ';' and next attribute
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, c.length);
    }
  }
  return '';
};

/** @see https://www.w3schools.com/js/js_cookies.asp */
export const deleteCookie = (cname: string): void => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./http-server";
import ConfigData from "../Config/config.json";
const data: any = ConfigData;
const website: any = process.env.REACT_APP_ENV;
const api_url = data[website]?.api_url;
const adminApiUrl = "https://api.htptv.net";
const baseUrl = api_url;

const get = (url: string, signal?: AbortSignal) => {
  return axiosInstance.get(baseUrl + `${url}`, { signal });
};
const deleteData = (url: string) => {
  return axiosInstance.delete(baseUrl + `${url}`, {});
};

const post = (url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data);
};
const postImage = (url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const postdoc = (url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data, {
    responseType: "blob",
  });
};
const getGame = (url: string) => {
  return axiosInstance.get(adminApiUrl + `${url}`, {
    headers: {
      apiurl: data.api_url,
    },
  });
};
const getapi = (url: string) => {
  return axiosInstance.get(adminApiUrl + `${url}`);
};
const getapiParams = (url: string, params: any) => {
  return axiosInstance.get(adminApiUrl + `${url}`, { ...params });
};
const HttpService = {
  get,
  deleteData,
  getapi,
  getapiParams,
  getGame,
  postImage,
  postdoc,
  post,
};

export default HttpService;

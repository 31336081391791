import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import _, { debounce } from "lodash";
import useConfig from "../../../../hooks/useConfig";
import { a11yProps, TabPanel } from "../../Ads";
import { useState } from "react";
import PlayerAlertTable from "./PollTable";
import PollTable from "./PollTable";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function PollListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [allPolls, setAllPolls] = React.useState([] as any);
  const [keyword, setKeyword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<any>(true);
  // Avoid a layout jump when reaching the last page with empty rows.
  const configData = useConfig();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetAllPolls = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await HttpService.get(
        `${APIRoutes.GetPoll}?isActive=${isActive}`
      );
      setAllPolls(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [isRefresh, isActive]);
  const handleKeywordChange = debounce((value: string) => {
    setKeyword(value);
  }, 300); //
  React.useEffect(() => {
    GetAllPolls();
  }, [GetAllPolls]);
  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Player Polls</Typography>
        <Box>
          <button
            onClick={() => navigate(PageRoutes.EditPolls.replace(":id", "0"))}
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Add Polls
          </button>{" "}
        </Box>
      </Box>
      <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
        <Tab label="Available" {...a11yProps(0)} />
        <Tab label="Trash" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <PollTable
          allPolls={allPolls}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PollTable
          allPolls={allPolls}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
      </TabPanel>
    </Box>
  );
}

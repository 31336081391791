import {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {logger} from '../../utils/Logger';
import {Adapter} from '../Adapter';

import {AmazonIVSInternalAdapter} from './AmazonIVSInternalAdapter';
import {SourceUrlProvider} from './player/SourceUrlProvider';

export class AmazonIVSAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();

    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }

    const sourceUrlProvider = new SourceUrlProvider();
    this.markPlayerInstanceAsAugmented(player);
    this.internalAdapter = new AmazonIVSInternalAdapter(player, sourceUrlProvider, opts);
    this.analytics = Analytics.create(config, this.internalAdapter);
    this.wrapPlayerLoad(player, sourceUrlProvider);
  }

  private wrapPlayerLoad(player: any, sourceUrlProvider: SourceUrlProvider) {
    const originalLoad = player.load;

    player.load = (...args: Parameters<typeof player.load>): ProgressEvent<FileReader> => {
      sourceUrlProvider.m3u8Url = args[0] as string;
      return originalLoad.apply(player, args);
    };
  }
}

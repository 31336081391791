import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import {
  CircularProgress,
  Pagination,
  Skeleton,
  TableHead,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../../../../Common/Component/Loader";
import { useNavigate } from "react-router-dom";
import _, { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StripeIcon from "../../../../assets/icons/StripeIcon";
import { CheckCircle } from "@mui/icons-material";
import moment from "moment";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function UserListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [allUsers, setAllUsers] = React.useState({} as any);
  const [keyword, setKeyword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - allUsers?.total_entries)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetAllUsers = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await HttpService.get(
        `${APIRoutes.GetAllUser}?page=${
          page + 1
        }&per_page=${rowsPerPage}&keyword=${keyword}`
      );
      setAllUsers(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, keyword]);
  const handleKeywordChange = debounce((value: string) => {
    setKeyword(value);
  }, 300); //
  React.useEffect(() => {
    GetAllUsers();
  }, [GetAllUsers]);
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Users</Typography>
        <TextField
          size="small"
          placeholder="Search"
          onChange={(e: any) => {
            handleKeywordChange(e.target.value);
            setPage(0);
          }}
          //   value={keyword}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  First Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Last Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Role
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Email Verified
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Ip Address
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Created at
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              {new Array(rowsPerPage).fill(0).map((el) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {allUsers?.users?.map((row: any) => (
                <TableRow key={row.UserID}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.Email}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.FirstName}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.LastName}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.UserRole?.RoleName}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.IsEmailVerified ? (
                        <CheckCircle color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.IpAddress}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {moment(row?.createdAt).format("DD MMM YYYY hh:mm A")}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {row?.StripePayments?.length > 0 && (
                      <IconButton
                        target="_blank"
                        href={`https://dashboard.stripe.com/customers/${row?.StripePayments[0]?.CostomerID}`}
                      >
                        <StripeIcon />
                      </IconButton>
                    )}

                    <IconButton
                      onClick={() =>
                        navigate(
                          PageRoutes.UserView.replace(":id", row?.UserID)
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="div"
          colSpan={3}
          rowsPerPageOptions={[5, 10, 25]}
          count={allUsers.total_entries}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </Box>
  );
}

import {PlayerSize} from '../enums/PlayerSize';

export interface PlaybackInfo {
  isLive?: boolean;
  playerTech?: string;
  videoDuration?: number;
  streamFormat?: string;
  progUrl?: string;
  m3u8Url?: string;
  mpdUrl?: string;
  videoWindowWidth?: number;
  videoWindowHeight?: number;
  isMuted?: boolean;
  videoBitrate?: number;
  audioBitrate?: number;
  droppedFrames: number;
  isCasting?: boolean;
  castTech?: string;
  screenHeight?: number;
  screenWidth?: number;
  videoPlaybackHeight?: number;
  videoPlaybackWidth?: number;
  videoTitle?: string;
  size?: PlayerSize;
  audioCodec?: string;
  videoCodec?: string;
  audioLanguage?: string;
  subtitleEnabled?: boolean;
  subtitleLanguage?: string;
}

export function normalizeVideoDuration(playerVideoDuration: number | null | undefined): number {
  // This covers Live streams and any case in which the duration is not known
  // which should report 0 as videoDuration
  if (
    playerVideoDuration === null ||
    playerVideoDuration === undefined ||
    Number.isNaN(playerVideoDuration) ||
    playerVideoDuration === Infinity
  ) {
    return 0;
  }
  return playerVideoDuration;
}

import { Share } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DialogBox from "../../../../Common/Component/DialogBox";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { toast } from "react-toastify";
import HttpService from "../../../../hooks/Https-services";
import { v4 as uuid } from "uuid";
import useCommonDetails from "../../../../Common/Hooks/useCommonDetails";
const ShareClips = ({ el }: any) => {
  const { gameId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useCommonDetails();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const shareVideoClips = async () => {
    setIsLoading(true);
    try {
      if (!email) {
        toast.error("Please enter valid email");
        setIsLoading(false);
        return;
      }
      delete el?.jobId;
      delete el?.jobInputUrl;
      delete el?.jobS3FileName;
      delete el?.jobStatus;

      const res = await HttpService.post(APIRoutes.ShareClipDetailas, {
        game_id: gameId,
        email: email,
        video: {
          ...el,
          video_clip_uuid: uuid(),
          type: "SHARED",
          shared_by: {
            UserID: userDetails?.UserID,
            Email: userDetails?.Email,
            FirstName: userDetails?.FirstName,
            LastName: userDetails?.LastName,
          },
        },
      });
      toast.success("Clip Shared Successfully");

      setIsLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };
  return (
    <Box>
      <IconButton onClick={handleClickOpen}>
        <Share />
      </IconButton>
      <DialogBox
        buttonActionSecondary={shareVideoClips}
        butttonTextSecondary={"Send"}
        handleClose={handleClose}
        open={open}
        disabled={isLoading}
        content={
          <Box sx={{ py: 2 }}>
            <TextField
              size="small"
              label="Enter email"
              fullWidth
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
        }
        buttonAction={handleClose}
        buttonText={"Cancel"}
      />
    </Box>
  );
};
export default ShareClips;

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import HttpService from "../../../../hooks/Https-services";
import useConfig from "../../../../hooks/useConfig";
import _ from "lodash";
import ScheduleTable from "./scheduleTable";
import { useNavigate } from "react-router-dom";
import { getCurrentAuth } from "../../../../hooks/http-server";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const HeaderSearch = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("567"));
  const [searchFilter, setFilter] = useState<any>(null);
  const configData = useConfig();
  const [channelOption, setChannelOption] = useState<any>([]);
  const eventOption = [
    {
      name: "Live and Upcoming Events",
      id: 1,
    },
    {
      name: "On Demand",
      id: 2,
    },
  ];
  const auth = getCurrentAuth();
  const [vodGames, setVodGames] = useState([]);
  const [liveGame, setLiveGames] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [isFuncationCalled, setIsFuncationCalled] = useState(false);
  const [selectedOption, setSelectedOptin] = useState<any>({
    team: [],
    channel: [],
    events: [],
  });

  const navigate = useNavigate();
  const checkCheckedFavi = (id: string, name: any) => {
    if (selectedOption?.[name]?.length) {
      const findTeam = selectedOption?.[name]?.find((ele: any) => ele == id);
      if (findTeam) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const [usersFavoriteTeam, setUsersFavoriteTeam] = useState<any>([]);
  const FetchTeamLIVE = async (searchFilter: any) => {
    try {
      //   setIsLoading(true);
      const params: any = {
        customer: configData.customer,
        status: "live",

        page: 1,
        search: searchFilter?.search,
        limit: isMobile ? 3 : 5,
      };
      if (searchFilter?.team) {
        params.team = searchFilter?.team;
      }
      if (searchFilter?.team) {
        params.team = searchFilter?.team;
      }
      if (searchFilter.channel) {
        params.channel = searchFilter.channel;
      }
      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setLiveGames(data);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };
  const handleNavigate = (route: any) => {
    setFocus(false);
    navigate({
      pathname: route,
      search: `search=${
        searchFilter?.search ? searchFilter?.search : ""
      }&team=${searchFilter?.team ? searchFilter?.team : ""}&channel_id=${
        searchFilter?.channel ? searchFilter?.channel : ""
      }`,
    });
  };
  const handleChangeCheckBox = (e: any, el: any) => {
    const isChecked = e?.target?.checked;
    if (isChecked) {
      const array: any = [];
      array.push(el);
      setSelectedOptin({
        ...selectedOption,
        [e.target.name]: [...selectedOption?.[e.target.name], ...array],
      });

      const findIds = [...selectedOption?.[e.target.name], ...array]
        ?.map((el: any) => el)
        .join(",");
      setFilter({ ...searchFilter, [e.target.name]: findIds });
    } else {
      const filterOutTeams = selectedOption?.[e.target.name]?.filter(
        (ele: any) => ele != el
      );
      setSelectedOptin({
        ...selectedOption,
        [e.target.name]: filterOutTeams,
      });
      const findIds = filterOutTeams?.map((el: any) => el).join(",");
      setFilter({ ...searchFilter, [e.target.name]: findIds });
    }
  };
  const FetchTeamVOD = async (searchFilter: any) => {
    try {
      //   setIsLoading(true);
      const params: any = {
        customer: configData.customer,
        status: "vod",
        page: 1,
        search: searchFilter.search,
        limit: isMobile ? 3 : 5,
      };
      if (searchFilter?.team) {
        params.team = searchFilter?.team;
      }
      if (searchFilter.channel) {
        params.channel = searchFilter.channel;
      }
      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setVodGames(data);
      //   setPage(page + 1);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamVod = useCallback(
    _.debounce(FetchTeamVOD, 500),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamLive = useCallback(
    _.debounce(FetchTeamLIVE, 500),
    []
  );

  useEffect(() => {
    debouncedFetchUserFavouriteTeamVod(searchFilter);
    debouncedFetchUserFavouriteTeamLive(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [
    debouncedFetchUserFavouriteTeamLive,
    debouncedFetchUserFavouriteTeamVod,
    searchFilter,
  ]);
  const handleDropdownClick = (event: any) => {
    // setFocus(true);
    event.stopPropagation(); // Prevent clicks inside the box from propagating to the document
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        // Click occurred outside of the dropdown, so close it
        setFocus(false);

        setIsFuncationCalled(false);
      }
    };

    // Attach event listener to detect clicks outside of the dropdown
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup: remove event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const FetchUserFaveouriteTeam = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetUsersFavouriteTeam);
      if (res?.data?.data) {
        setUsersFavoriteTeam(res?.data?.data?.UserFavouriteTeam);
      }
    } catch (error) {}
  };
  const FetchChannels = async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );
      setChannelOption(result.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (searchFilter && !isFuncationCalled) {
      if (auth.AccessToken) {
        FetchUserFaveouriteTeam();
      }
      FetchChannels();
      setIsFuncationCalled(true);
    }
  }, [searchFilter]);
  const [isFoucs, setFocus] = useState(false);
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: { lg: "relative", xs: "unset" },
        textAlign: "end",
        justifyContent: "flex-end",
      }}
    >
      <OutlinedInput
        onFocus={() => {
          setFilter({ ...searchFilter, search: searchFilter?.search });
          setFocus(true);
        }}
        onClick={handleDropdownClick}
        size="small"
        name="search"
        fullWidth={isFoucs || isMobile ? true : false}
        autoComplete="off"
        placeholder="Search"
        inputRef={dropdownRef}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setFocus(false);
                setSelectedOptin({
                  team: [],
                  channel: [],
                  events: [],
                });
                setFilter({ search: "", team: "", channel: "" });
              }}
              edge="end"
            >
              <CloseOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
        value={searchFilter?.search}
        onChange={(e: any) => {
          setFocus(true);
          setFilter({ ...searchFilter, search: e.target.value });
        }}
      />
      <Box
        onClick={handleDropdownClick}
        ref={dropdownRef}
        sx={{
          borderRadius: "8px",
          zIndex: 100,

          width: 1,
          transition: "height 0.3s ease-in-out",
          height: isFoucs ? { xs: "400px", sm: "650px" } : "0px",
          bgcolor: "#fff",
          left: 0,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          mt: 1.5,
          right: 0,
          position: "absolute",
          overflowX: "hidden",
          maxHeight: "650px",
          overflowY: "scroll",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Stack gap={1}>
                <Stack gap={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">Live and Upcoming</Typography>
                    <Box sx={{ textAlign: "end" }}>
                      <Button
                        onClick={() => handleNavigate(PageRoutes.UpcomingEvent)}
                      >
                        See All
                      </Button>
                    </Box>
                  </Stack>
                  <ScheduleTable data={liveGame} setFilter={setFilter} />
                </Stack>

                <Stack gap={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">On Demand</Typography>
                    <Box sx={{ textAlign: "end" }}>
                      <Button
                        onClick={() => handleNavigate(PageRoutes.OnDemand)}
                      >
                        See All
                      </Button>
                    </Box>
                  </Stack>
                  <ScheduleTable data={vodGames} setFilter={setFilter} />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack gap={1}>
                {usersFavoriteTeam?.length > 0 && (
                  <Stack gap={1}>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h6">Favorites</Typography>

                      <Button
                        onClick={() => handleNavigate(PageRoutes.Setting)}
                      >
                        Manage
                      </Button>
                    </Stack>
                    <Stack gap={0.5}>
                      {usersFavoriteTeam?.map((el: any) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Checkbox
                            //   disabled={isDiabled}
                            //   checked={isChecked}
                            name="team"
                            checked={checkCheckedFavi(el?.team_id, "team")}
                            onChange={(e: any) =>
                              handleChangeCheckBox(e, el?.team_id)
                            }
                          />
                          <Avatar src={el?.team_logo_url} />

                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "start",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {el?.team_name} {el?.team_mascot}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                )}

                <Stack gap={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">Channels</Typography>
                    {!searchFilter?.channel ? (
                      <Button
                        onClick={() => handleNavigate(PageRoutes.UpcomingEvent)}
                      >
                        See All
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack gap={0.5} sx={{ pb: 10 }}>
                    <Grid container>
                      {channelOption?.map((el: any) => (
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Checkbox
                              name="channel"
                              checked={checkCheckedFavi(
                                el?.channel_id,
                                "channel"
                              )}
                              onChange={(e: any) =>
                                handleChangeCheckBox(e, el.channel_id)
                              }
                            />

                            <Typography
                              variant="caption"
                              sx={{
                                textAlign: "start",
                                fontStyle: "normal",
                                textTransform: "capitalize",
                              }}
                            >
                              {el?.channel_name}
                            </Typography>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                  {/* <ScheduleTable data={vodGames} /> */}
                </Stack>
                {/* <Stack gap={1}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">Events</Typography>
                    {!searchFilter?.channel ? (
                      <Button
                        onClick={() => handleNavigate(PageRoutes.UpcomingEvent)}
                      >
                        See All
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack gap={0.5}>
                    <Grid container>
                      {eventOption?.map((el: any) => (
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Checkbox
                              name="events"
                              checked={checkCheckedFavi(el?.id, "events")}
                              onChange={(e: any) =>
                                handleChangeCheckBox(e, el.id)
                              }
                            />
                            <Stack direction={"row"} gap={0.5}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontStyle: "normal",
                                  textTransform: "capitalize",
                                }}
                              >
                                {el?.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Stack> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default HeaderSearch;

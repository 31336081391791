import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableMain from "./TableMain";
import TableTitle from "./TableTitle";
import TableFooter from "./TableFooter";
import team1 from "../../assets/img/team1.jpg";
import teamLogo from "../../assets/img/team-logo.png";
import team2 from "../../assets/img/team2.jpg";
import CommonStyle from "../../Common/style/Style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ToggleSwitch from "./ToggleSwitch";
import PBPTable from "./PBPTable";
import HBHTableOne from "./HBHTableOne";
import HBHTableTwo from "./HBHTableTwo";
import TSTable from "./TSTable";
import LastGamesTable from "./LastGamesTable";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { db, makeGetRequest } from "../../service";
import { StyledTableRow } from "../TableComponent/Table";
import ScoringTable from "./ScoringTable/ScroingTable";
import PlayByPlayTable from "./PlayByPlay/PlayByPlayTable";
import { onValue, ref } from "firebase/database";
import moment from "moment";
import useConfig from "../../hooks/useConfig";

const GameCenterBoard = ({ gameData, liveData }: any) => {
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1030")
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const configData = useConfig();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleTabChange = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    setSelectedTab(newValue);
  };
  const [hideData, setHideData] = useState({
    fisrt: false,
    second: false,
    third: false,
    ot: false,
    goals: false,
    penalties: false,
  });
  const [switches, setSwitches] = useState({
    first: true,
    second: true,
    third: true,
    ot: true,
    goals: true,
    panelties: true,
    Save: true,
    Shots: true,
  });
  const covertIntoArry = (object: any) => {
    let data: any = [];
    for (const key in object) {
      data.push(object[key]);
    }
    return data;
  };
  const offiicialArray = () => {
    const array: any = [];

    gameData?.scorekeeper &&
      array.push({
        officials: gameData?.scorekeeper,
        role: "Scorekeeper",
      });
    gameData?.officials[1] &&
      array.push({
        officials: gameData?.officials[1],
        role: "Referee 1",
      });
    gameData?.officials[2] &&
      array.push({
        officials: gameData?.officials[2],
        role: "Linesman 1",
      });
    gameData?.officials[3] &&
      array.push({
        officials: gameData?.officials[3],
        role: "Linesman 2",
      });
    gameData?.officials[4] &&
      array.push({
        officials: gameData?.officials[4],
        role: "Referee 2",
      });
    return array;
  };
  const fiilterArray = (object: any) => {
    let data: any = [];
    for (const key in object) {
      data.push({ key: key, array: object[key] });
    }

    return data;
  };
  const createObject = (shots: any, summary: any, away_ab: any) => {
    const obje = gameData?.period_list?.reduce((obj: any, value: any) => {
      obj[value] = gameData?.[summary]?.[shots]?.[value];
      return obj;
    }, {});
    obje.sog = away_ab;
    if (summary == "goal_summary") {
      obje.total = gameData?.[summary]?.[shots]?.total;
    }
    if (summary == "shot_summary") {
      obje.total = gameData?.[summary]?.[shots]?.total;
    }
    return obje;
  };
  return (
    <>
      {gameData.status ? (
        <Box
          sx={{
            px: 2,
            width: "100%",
            background: "lightGray",
            flexGrow: 1,
            mb: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3.5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "150px",
                  alignItems: "center",
                  verticalAlign: "top",
                }}
              >
                <Box>
                  <img
                    src={
                      gameData?.away_logo
                        ? gameData?.away_logo
                        : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                    }
                    alt="Image"
                    style={{
                      margin: "10px auto",
                      height: "auto",
                      maxWidth: "40px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {gameData?.away_ab}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "700",
                }}
              >
                {gameData?.goal_summary?.away_goals["total"]}-{" "}
                {gameData?.goal_summary?.home_goals["total"]}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "700",
                }}
              >
                {liveData?.period && gameData?.status == "OPEN"
                  ? liveData?.period
                  : gameData?.status == "NOT STARTED"
                  ? gameData?.date
                  : gameData?.status}
              </Typography>
              {liveData?.clock && gameData?.status == "OPEN" && (
                <Typography variant="h3">{liveData?.clock}</Typography>
              )}
              <Typography variant="body1" sx={{ fontSize: "10px" }}>
                Game # :{gameData?.alias}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  py: 1,
                  fontSize: "8px",
                }}
              >
                {gameData?.formatted_date}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "10px" }}>
                Venue :{gameData?.location}
              </Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "150px",
                  alignItems: "center",
                  verticalAlign: "top",
                }}
              >
                <Box>
                  <img
                    src={
                      gameData?.home_logo
                        ? gameData?.home_logo
                        : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                    }
                    alt="Image"
                    style={{
                      margin: "10px auto",
                      height: "auto",
                      maxWidth: "40px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {gameData?.home_ab}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: "white", borderRadius: "5px" }}>
            <ToggleButtonGroup
              value={selectedTab}
              exclusive
              onChange={handleTabChange}
              aria-label="tabs"
              color="secondary"
              sx={{
                whiteSpace: "nowrap",
                width: "100%",
                ".MuiToggleButton-root": {
                  flex: 1,
                  fontSize: "10px",
                  color: "black",
                  textTransform: "none",
                  opacity: 1,
                },
              }}
              size="small"
            >
              <ToggleButton
                value="0"
                aria-label="Tab 1"
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: configData.primary,
                  },
                }}
                selected={selectedTab == 0}
              >
                Preview
              </ToggleButton>
              <ToggleButton
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: configData.primary,
                  },
                }}
                value="1"
                aria-label="Tab 2"
              >
                Play by Play
              </ToggleButton>
              <ToggleButton
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: configData.primary,
                  },
                }}
                value="2"
                aria-label="Tab 3"
              >
                Box score
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ backgroundColor: "white", borderRadius: "5px" }}>
            <ToggleButtonGroup
              value={selectedTab}
              exclusive
              onChange={handleTabChange}
              aria-label="tabs"
              sx={{
                whiteSpace: "nowrap",
                width: "100%",
                flexWrap: "wrap",
                ".MuiToggleButton-root": {
                  flex: 1,
                  fontSize: "10px",
                  color: "black",
                  textTransform: "none",
                },
              }}
              size="small"
            >
              <ToggleButton
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: configData.primary,
                  },
                }}
                value="3"
                aria-label="Tab 4"
              >
                Home Lineup
              </ToggleButton>
              <ToggleButton
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                    backgroundColor: configData.primary,
                  },
                }}
                value="4"
                aria-label="Tab 5"
              >
                Visitor Lineup
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {selectedTab == 0 && (
            <>
              {/* Seventh Tables Grid */}
              <Grid container spacing={1} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <TableTitle title={"HEAD TO HEAD"} />
                </Grid>
                <Grid item xs={12}>
                  <HBHTableOne
                    header={"Current Season"}
                    listData={[
                      {
                        team1_src: gameData?.away_logo,
                        team1_scores: `${gameData?.away_current_season_record?.wins}-${gameData?.away_current_season_record?.losses}-${gameData?.away_current_season_record?.ot_losses}-${gameData?.away_current_season_record?.so_losses}`,
                        team2_src: gameData?.home_logo,
                        team1: gameData?.away_ab,
                        team2: gameData?.home_ab,
                        team2_scores: `${gameData?.home_current_season_record?.wins}-${gameData?.home_current_season_record?.losses}-${gameData?.home_current_season_record?.ot_losses}-${gameData?.home_current_season_record?.so_losses}`,
                      },
                    ]}
                  />
                  <HBHTableOne
                    header={"Previous Season"}
                    listData={[
                      {
                        team1_src: gameData?.away_logo,
                        team1: gameData?.away_ab,
                        team2: gameData?.home_ab,
                        team1_scores: `${gameData?.away_previous_season_record?.wins}-${gameData?.away_previous_season_record?.losses}-${gameData?.away_previous_season_record?.ot_losses}-${gameData?.away_previous_season_record?.so_losses}`,
                        team2_src: gameData?.home_logo,
                        team2_scores: `${gameData?.home_previous_season_record?.wins}-${gameData?.home_previous_season_record?.losses}-${gameData?.home_previous_season_record?.ot_losses}-${gameData?.home_previous_season_record?.so_losses}`,
                      },
                    ]}
                  />
                  <HBHTableOne
                    header={"Last 5 Seasons"}
                    listData={[
                      {
                        team1_src: gameData?.away_logo,
                        team1: gameData?.away_ab,
                        team2: gameData?.home_ab,
                        team1_scores: `${gameData?.away_last_5_seasons_record?.wins}-${gameData?.away_last_5_seasons_record?.losses}-${gameData?.away_last_5_seasons_record?.ot_losses}-${gameData?.away_last_5_seasons_record?.so_losses}`,
                        team2_src: gameData?.home_logo,
                        team2_scores: `${gameData?.home_last_5_seasons_record?.wins}-${gameData?.home_last_5_seasons_record?.losses}-${gameData?.home_last_5_seasons_record?.ot_losses}-${gameData?.home_last_5_seasons_record?.so_losses}`,
                      },
                    ]}
                  />
                  <HBHTableOne
                    header={"All Seasons"}
                    listData={[
                      {
                        team1_src: gameData?.away_logo,
                        team1: gameData?.away_ab,
                        team2: gameData?.home_ab,
                        team1_scores: `${gameData?.away_all_seasons_record?.wins}-${gameData?.away_all_seasons_record?.losses}-${gameData?.away_all_seasons_record?.ot_losses}-${gameData?.away_all_seasons_record?.so_losses}`,
                        team2_src: gameData?.home_logo,
                        team2_scores: `${gameData?.home_all_seasons_record?.wins}-${gameData?.home_all_seasons_record?.losses}-${gameData?.home_all_seasons_record?.ot_losses}-${gameData?.home_all_seasons_record?.so_losses}`,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HBHTableTwo
                    header={"Previous meeting this season"}
                    listData={gameData?.current_season_past_meetings?.map(
                      (el: any, index: any) => {
                        return {
                          team1_src: el?.away_smlogo,
                          score_board: `${el?.away_goals}:${el?.home_goals}`,
                          team2_src: el?.home_smlogo,
                          date: el?.date,
                          team1: gameData?.away_ab,
                          team2: gameData?.home_ab,
                          mode: el?.status,
                        };
                      }
                    )}
                  />
                </Grid>
              </Grid>

              {/* Eighth Tables Grid */}
              <Grid container sx={{ py: 4 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <TableTitle title={"TOP SCORERS"} />
                    <TSTable
                      headers={[
                        {
                          id: "team1",
                          label: gameData?.away_ab,
                          align: "left",
                        },
                      ]}
                      listData={gameData?.away_top_scorers?.filter(
                        (el: any, index: any) => index <= 4 && el
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ visibility: "hidden" }}>
                      <TableTitle title={"TOP SCORERS"} />
                    </Box>
                    <TSTable
                      headers={[
                        {
                          id: "team1",
                          label: gameData?.home_ab,
                          align: "left",
                        },
                      ]}
                      listData={gameData?.home_top_scorers?.filter(
                        (el: any, index: any) => index <= 4 && el
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ pr: 1 }} xs={12}>
                <Grid item xs={12}>
                  <TableTitle title={"LAST 5 GAMES"} />
                  <LastGamesTable
                    headers={[
                      {
                        id: "team1",
                        label: gameData?.away_ab,
                        align: "left",
                      },
                    ]}
                    listData={gameData?.away_past_5?.filter(
                      (el: any, index: any) => index <= 4 && el
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ pr: 1 }} xs={12}>
                <Grid item xs={12}>
                  <Box sx={{ visibility: "hidden" }}>
                    <TableTitle title={"TOP SCORERS"} />
                  </Box>
                  <LastGamesTable
                    headers={[
                      {
                        id: "team1",
                        label: gameData?.home_ab,
                        align: "left",
                      },
                    ]}
                    listData={gameData?.home_past_5?.filter(
                      (el: any, index: any) => index <= 4 && el
                    )}
                  />
                </Grid>
              </Grid>

              {/* Last Tables Grid */}
              <Grid container spacing={1} sx={{ py: 4 }}>
                <Grid item xs={12}>
                  <TableTitle title={"MATCH UP"} />
                  <TableMain
                    headers={[
                      { id: "type", label: gameData?.away_name, align: "left" },
                      { id: "team1", lable: "", align: "left" },
                      // { id: "team2", label: gameData?.home_name, align: "left" },
                    ]}
                    listData={[
                      {
                        type: "Season Record",
                        team1: `${gameData?.away_match_up?.full_season?.wins}-${gameData?.away_match_up?.full_season?.losses}-${gameData?.away_match_up?.full_season?.ot_losses}-${gameData?.away_match_up?.full_season?.so_losses}`,
                        // team2: `${gameData?.home_match_up?.full_season?.wins}-${gameData?.home_match_up?.full_season?.losses}-${gameData?.home_match_up?.full_season?.ot_losses}-${gameData?.home_match_up?.full_season?.so_losses}`,
                      },
                      {
                        type: "Last 10 Games",
                        team1: `${gameData?.away_match_up?.last_10?.wins}-${gameData?.away_match_up?.last_10?.losses}-${gameData?.away_match_up?.last_10?.ot_losses}-${gameData?.away_match_up?.last_10?.so_losses}`,
                        // team2: `${gameData?.home_match_up?.last_10?.wins}-${gameData?.home_match_up?.last_10?.losses}-${gameData?.home_match_up?.last_10?.ot_losses}-${gameData?.home_match_up?.last_10?.so_losses}`,
                      },
                      {
                        type: "Streak",
                        team1: `${gameData?.away_match_up?.streak?.wins}-${gameData?.away_match_up?.streak?.losses}-${gameData?.away_match_up?.streak?.ot_losses}-${gameData?.away_match_up?.streak?.so_losses}`,
                        // team2: `${gameData?.home_match_up?.streak?.wins}-${gameData?.home_match_up?.streak?.losses}-${gameData?.home_match_up?.streak?.ot_losses}-${gameData?.home_match_up?.streak?.so_losses}`,
                      },
                      {
                        type: "Last Game",
                        team1: `${
                          gameData?.away_match_up?.last_game?.away_goals
                            ? gameData?.away_match_up?.last_game?.away_goals
                            : 0
                        }-${
                          gameData?.away_match_up?.last_game?.home_goals
                            ? gameData?.away_match_up?.last_game?.home_goals
                            : 0
                        } ${
                          gameData?.away_match_up?.last_game?.result
                            ? gameData?.away_match_up?.last_game?.result
                            : 0
                        } ${
                          gameData?.away_match_up?.last_game?.opp_seperator
                            ? gameData?.away_match_up?.last_game?.opp_seperator
                            : 0
                        } ${
                          gameData?.away_match_up?.last_game?.opp_name
                            ? gameData?.away_match_up?.last_game?.opp_name
                            : 0
                        } (${
                          gameData?.away_match_up?.last_game?.date
                            ? gameData?.away_match_up?.last_game?.date
                            : 0
                        })`,
                        // team2: `${
                        //   gameData?.home_match_up?.last_game?.away_goals
                        //     ? gameData?.home_match_up?.last_game?.away_goals
                        //     : 0
                        // }-${
                        //   gameData?.home_match_up?.last_game?.home_goals
                        //     ? gameData?.home_match_up?.last_game?.home_goals
                        //     : 0
                        // } ${
                        //   gameData?.home_match_up?.last_game?.result
                        //     ? gameData?.home_match_up?.last_game?.result
                        //     : 0
                        // } ${
                        //   gameData?.home_match_up?.last_game?.opp_seperator
                        //     ? gameData?.home_match_up?.last_game?.opp_seperator
                        //     : 0
                        // } ${
                        //   gameData?.home_match_up?.last_game?.opp_name
                        //     ? gameData?.home_match_up?.last_game?.opp_name
                        //     : 0
                        // } (${
                        //   gameData?.home_match_up?.last_game?.date
                        //     ? gameData?.home_match_up?.last_game?.date
                        //     : 0
                        // })`,
                      },
                      {
                        type: "Home Record",
                        team1: `${gameData?.away_match_up?.home_record?.wins}-${gameData?.away_match_up?.home_record?.losses}-${gameData?.away_match_up?.home_record?.ot_losses}-${gameData?.away_match_up?.home_record?.so_losses}`,
                        // team2: `${gameData?.home_match_up?.home_record?.wins}-${gameData?.home_match_up?.home_record?.losses}-${gameData?.home_match_up?.home_record?.ot_losses}-${gameData?.home_match_up?.home_record?.so_losses}`,
                      },
                      {
                        type: "Away Record",
                        team1: `${gameData?.away_match_up?.away_record?.wins}-${gameData?.away_match_up?.away_record?.losses}-${gameData?.away_match_up?.away_record?.ot_losses}-${gameData?.away_match_up?.away_record?.so_losses}`,
                        // team2: `${gameData?.home_match_up?.away_record?.wins}-${gameData?.home_match_up?.away_record?.losses}-${gameData?.home_match_up?.away_record?.ot_losses}-${gameData?.home_match_up?.away_record?.so_losses}`,
                      },

                      {
                        type: "Goals For",
                        team1: `${gameData?.away_match_up?.goals_for}`,
                        // team2: `${gameData?.home_match_up?.goals_for}`,
                      },
                      {
                        type: "Goals Against",
                        team1: `${gameData?.away_match_up?.goals_against}`,
                        // team2: `${gameData?.home_match_up?.goals_against}`,
                      },
                      {
                        type: "Power Plays",
                        team1: `${gameData?.away_match_up?.pp_pk?.power_play_goals} /${gameData?.away_match_up?.pp_pk?.power_play_opps}(${gameData?.away_match_up?.pp_pk?.pk_pct}%)`,
                        // team2: `${gameData?.home_match_up?.pp_pk?.power_play_goals} /${gameData?.home_match_up?.pp_pk?.power_play_opps}(${gameData?.home_match_up?.pp_pk?.pk_pct}%)`,
                      },
                      {
                        type: "Power Plays (Home)",
                        team1: `${gameData?.away_match_up?.home_pp_pk?.power_play_goals} /${gameData?.away_match_up?.home_pp_pk?.power_play_opps}(${gameData?.away_match_up?.home_pp_pk?.pp_pct}%)`,
                        // team2: `${gameData?.home_match_up?.home_pp_pk?.power_play_goals} /${gameData?.home_match_up?.home_pp_pk?.power_play_opps}(${gameData?.home_match_up?.home_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Power Plays (Away)",
                        team1: `${gameData?.away_match_up?.away_pp_pk?.power_play_goals} /${gameData?.away_match_up?.away_pp_pk?.power_play_opps}(${gameData?.away_match_up?.away_pp_pk?.pp_pct}%)`,
                        // team2: `${gameData?.home_match_up?.away_pp_pk?.power_play_goals} /${gameData?.home_match_up?.away_pp_pk?.power_play_opps}(${gameData?.home_match_up?.away_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Penalty Killing",
                        team1: `${gameData?.away_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.pp_pk?.penalty_kills}(${gameData?.away_match_up?.pp_pk?.pk_pct}%)`,
                        // team2: `${gameData?.home_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.pp_pk?.penalty_kills}(${gameData?.home_match_up?.pp_pk?.pk_pct}%)`,
                      },
                      {
                        type: "Penalty Killing (Home)",
                        team1: `${gameData?.away_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.home_pp_pk?.penalty_kills}(${gameData?.away_match_up?.home_pp_pk?.pp_pct}%)`,
                        // team2: `${gameData?.home_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.home_pp_pk?.penalty_kills}(${gameData?.home_match_up?.home_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Penalty Killing (Away)",
                        team1: `${gameData?.away_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.away_pp_pk?.penalty_kills}(${gameData?.away_match_up?.away_pp_pk?.pp_pct}%)`,
                        // team2: `${gameData?.home_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.away_pp_pk?.penalty_kills}(${gameData?.home_match_up?.away_pp_pk?.pp_pct}%)`,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <TableMain
                    headers={[
                      { id: "type", label: gameData?.home_name, align: "left" },
                      // { id: "team1", label: gameData?.away_name, align: "left" },
                      { id: "team2", label: "", align: "left" },
                    ]}
                    listData={[
                      {
                        type: "Season Record",
                        // team1: `${gameData?.away_match_up?.full_season?.wins}-${gameData?.away_match_up?.full_season?.losses}-${gameData?.away_match_up?.full_season?.ot_losses}-${gameData?.away_match_up?.full_season?.so_losses}`,
                        team2: `${gameData?.home_match_up?.full_season?.wins}-${gameData?.home_match_up?.full_season?.losses}-${gameData?.home_match_up?.full_season?.ot_losses}-${gameData?.home_match_up?.full_season?.so_losses}`,
                      },
                      {
                        type: "Last 10 Games",
                        // team1: `${gameData?.away_match_up?.last_10?.wins}-${gameData?.away_match_up?.last_10?.losses}-${gameData?.away_match_up?.last_10?.ot_losses}-${gameData?.away_match_up?.last_10?.so_losses}`,
                        team2: `${gameData?.home_match_up?.last_10?.wins}-${gameData?.home_match_up?.last_10?.losses}-${gameData?.home_match_up?.last_10?.ot_losses}-${gameData?.home_match_up?.last_10?.so_losses}`,
                      },
                      {
                        type: "Streak",
                        // team1: `${gameData?.away_match_up?.streak?.wins}-${gameData?.away_match_up?.streak?.losses}-${gameData?.away_match_up?.streak?.ot_losses}-${gameData?.away_match_up?.streak?.so_losses}`,
                        team2: `${gameData?.home_match_up?.streak?.wins}-${gameData?.home_match_up?.streak?.losses}-${gameData?.home_match_up?.streak?.ot_losses}-${gameData?.home_match_up?.streak?.so_losses}`,
                      },
                      {
                        type: "Last Game",
                        // team1: `${
                        //   gameData?.away_match_up?.last_game?.away_goals
                        //     ? gameData?.away_match_up?.last_game?.away_goals
                        //     : 0
                        // }-${
                        //   gameData?.away_match_up?.last_game?.home_goals
                        //     ? gameData?.away_match_up?.last_game?.home_goals
                        //     : 0
                        // } ${
                        //   gameData?.away_match_up?.last_game?.result
                        //     ? gameData?.away_match_up?.last_game?.result
                        //     : 0
                        // } ${
                        //   gameData?.away_match_up?.last_game?.opp_seperator
                        //     ? gameData?.away_match_up?.last_game?.opp_seperator
                        //     : 0
                        // } ${
                        //   gameData?.away_match_up?.last_game?.opp_name
                        //     ? gameData?.away_match_up?.last_game?.opp_name
                        //     : 0
                        // } (${
                        //   gameData?.away_match_up?.last_game?.date
                        //     ? gameData?.away_match_up?.last_game?.date
                        //     : 0
                        // })`,
                        team2: `${
                          gameData?.home_match_up?.last_game?.away_goals
                            ? gameData?.home_match_up?.last_game?.away_goals
                            : 0
                        }-${
                          gameData?.home_match_up?.last_game?.home_goals
                            ? gameData?.home_match_up?.last_game?.home_goals
                            : 0
                        } ${
                          gameData?.home_match_up?.last_game?.result
                            ? gameData?.home_match_up?.last_game?.result
                            : 0
                        } ${
                          gameData?.home_match_up?.last_game?.opp_seperator
                            ? gameData?.home_match_up?.last_game?.opp_seperator
                            : 0
                        } ${
                          gameData?.home_match_up?.last_game?.opp_name
                            ? gameData?.home_match_up?.last_game?.opp_name
                            : 0
                        } (${
                          gameData?.home_match_up?.last_game?.date
                            ? gameData?.home_match_up?.last_game?.date
                            : 0
                        })`,
                      },
                      {
                        type: "Home Record",
                        // team1: `${gameData?.away_match_up?.home_record?.wins}-${gameData?.away_match_up?.home_record?.losses}-${gameData?.away_match_up?.home_record?.ot_losses}-${gameData?.away_match_up?.home_record?.so_losses}`,
                        team2: `${gameData?.home_match_up?.home_record?.wins}-${gameData?.home_match_up?.home_record?.losses}-${gameData?.home_match_up?.home_record?.ot_losses}-${gameData?.home_match_up?.home_record?.so_losses}`,
                      },
                      {
                        type: "Away Record",
                        // team1: `${gameData?.away_match_up?.away_record?.wins}-${gameData?.away_match_up?.away_record?.losses}-${gameData?.away_match_up?.away_record?.ot_losses}-${gameData?.away_match_up?.away_record?.so_losses}`,
                        team2: `${gameData?.home_match_up?.away_record?.wins}-${gameData?.home_match_up?.away_record?.losses}-${gameData?.home_match_up?.away_record?.ot_losses}-${gameData?.home_match_up?.away_record?.so_losses}`,
                      },

                      {
                        type: "Goals For",
                        // team1: `${gameData?.away_match_up?.goals_for}`,
                        team2: `${gameData?.home_match_up?.goals_for}`,
                      },
                      {
                        type: "Goals Against",
                        // team1: `${gameData?.away_match_up?.goals_against}`,
                        team2: `${gameData?.home_match_up?.goals_against}`,
                      },
                      {
                        type: "Power Plays",
                        // team1: `${gameData?.away_match_up?.pp_pk?.power_play_goals} /${gameData?.away_match_up?.pp_pk?.power_play_opps}(${gameData?.away_match_up?.pp_pk?.pk_pct}%)`,
                        team2: `${gameData?.home_match_up?.pp_pk?.power_play_goals} /${gameData?.home_match_up?.pp_pk?.power_play_opps}(${gameData?.home_match_up?.pp_pk?.pk_pct}%)`,
                      },
                      {
                        type: "Power Plays (Home)",
                        // team1: `${gameData?.away_match_up?.home_pp_pk?.power_play_goals} /${gameData?.away_match_up?.home_pp_pk?.power_play_opps}(${gameData?.away_match_up?.home_pp_pk?.pp_pct}%)`,
                        team2: `${gameData?.home_match_up?.home_pp_pk?.power_play_goals} /${gameData?.home_match_up?.home_pp_pk?.power_play_opps}(${gameData?.home_match_up?.home_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Power Plays (Away)",
                        // team1: `${gameData?.away_match_up?.away_pp_pk?.power_play_goals} /${gameData?.away_match_up?.away_pp_pk?.power_play_opps}(${gameData?.away_match_up?.away_pp_pk?.pp_pct}%)`,
                        team2: `${gameData?.home_match_up?.away_pp_pk?.power_play_goals} /${gameData?.home_match_up?.away_pp_pk?.power_play_opps}(${gameData?.home_match_up?.away_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Penalty Killing",
                        // team1: `${gameData?.away_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.pp_pk?.penalty_kills}(${gameData?.away_match_up?.pp_pk?.pk_pct}%)`,
                        team2: `${gameData?.home_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.pp_pk?.penalty_kills}(${gameData?.home_match_up?.pp_pk?.pk_pct}%)`,
                      },
                      {
                        type: "Penalty Killing (Home)",
                        // team1: `${gameData?.away_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.home_pp_pk?.penalty_kills}(${gameData?.away_match_up?.home_pp_pk?.pp_pct}%)`,
                        team2: `${gameData?.home_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.home_pp_pk?.penalty_kills}(${gameData?.home_match_up?.home_pp_pk?.pp_pct}%)`,
                      },
                      {
                        type: "Penalty Killing (Away)",
                        // team1: `${gameData?.away_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.away_pp_pk?.penalty_kills}(${gameData?.away_match_up?.away_pp_pk?.pp_pct}%)`,
                        team2: `${gameData?.home_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.away_pp_pk?.penalty_kills}(${gameData?.home_match_up?.away_pp_pk?.pp_pct}%)`,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {selectedTab == 1 && (
            <>
              {/* Hide Show Tables */}
              <Grid container spacing={2.5} sx={{ py: 3 }}>
                <Grid item xs={12}>
                  <Accordion
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: 0,
                    }}
                    expanded={isExpanded}
                  >
                    <AccordionSummary
                      className={`statview-header-color`}
                      sx={{
                        ...CommonStyle.tableHeader,
                        background: configData.primary,
                        maxHeight: "auto",
                        flexDirection: "row-reverse",
                        ".MuiAccordionSummary-content.Mui-expanded": {
                          m: 0,
                          p: 0,
                        },
                      }}
                      expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      <Typography
                        align={"left"}
                        className={"statview-header-color"}
                        sx={{
                          ...CommonStyle.tableHeader,
                          fontWeight: 700,
                          bgcolor: "transparent",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          color: "#ffffff",
                        }}
                      >
                        &nbsp; PLAY BY PLAY
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              px: 1.5,
                              pt: 1.5,
                              flexWrap: "wrap",
                            }}
                          >
                            <ToggleSwitch
                              label={"1st"}
                              switch={switches.first}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  first: !switches.first,
                                })
                              }
                            />
                            <ToggleSwitch
                              label={"2nd"}
                              switch={switches.second}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  second: !switches.second,
                                })
                              }
                            />
                            <ToggleSwitch
                              label={"3rd"}
                              switch={switches.third}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  third: !switches.third,
                                })
                              }
                            />
                            <ToggleSwitch
                              label={"OT"}
                              switch={switches.ot}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  ot: !switches.ot,
                                })
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              px: 1.5,
                              py: 1.5,
                              flexWrap: "wrap",
                            }}
                          >
                            <ToggleSwitch
                              label={"Goals"}
                              switch={switches.goals}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  goals: !switches.goals,
                                })
                              }
                            />
                            <ToggleSwitch
                              label={"Penalties"}
                              switch={switches.panelties}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  panelties: !switches.panelties,
                                })
                              }
                            />
                            <ToggleSwitch
                              label={"Shots"}
                              switch={switches.Shots}
                              setSwitch={() =>
                                setSwitches({
                                  ...switches,
                                  Shots: !switches.Shots,
                                  Save: !switches.Save,
                                })
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Grid item xs={12}>
                        {fiilterArray(gameData?.events)
                          .filter((el: any) => {
                            if (!switches?.third) {
                              return el?.key != 3;
                            }
                            return el;
                          })
                          .filter((el: any) => {
                            if (!switches?.first) {
                              return el?.key != 1;
                            }
                            return el;
                          })
                          .filter((el: any) => {
                            if (!switches?.second) {
                              return el?.key != 2;
                            }
                            return el;
                          })
                          .filter((el: any) => {
                            if (!switches?.ot) {
                              return el?.key != "OT1";
                            }
                            return el;
                          })
                          .map((el: any, index: any) => (
                            <PlayByPlayTable
                              el={el}
                              switches={switches}
                              index={index}
                            />
                          ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </>
          )}
          {selectedTab == 2 && (
            <>
              {/* First Tables Grid */}
              <Grid container spacing={2.5} sx={{ py: 1.5 }}>
                <Grid item xs={12}>
                  <TableMain
                    headers={[
                      { id: "sog", label: "Scoring", align: "left" },
                      ...gameData?.period_list?.map((el: any) => {
                        return {
                          id: el.toString(),
                          label: el.toString(),
                          align: "center",
                        };
                      }),
                      {
                        id: "total",
                        label:
                          liveData.clock && gameData?.status == "OPEN"
                            ? "TOTAL"
                            : "Final",
                        align: "right",
                      },
                    ]}
                    listData={[
                      createObject(
                        "away_goals",
                        "goal_summary",
                        gameData?.away_ab
                      ),
                      createObject(
                        "home_goals",
                        "goal_summary",
                        gameData?.home_ab
                      ),
                    ]}
                  />
                </Grid>
                {/* { id: "1", label: "1" },
            { id: "2", label: "2" },
            { id: "3", label: "3" },
            { id: "ot", label: "OT" },
            { id: "total", label: "T" }, */}
                <Grid item xs={12}>
                  <TableMain
                    headers={[
                      { id: "sog", label: "Shots on Goal", align: "left" },
                      ...gameData?.period_list?.map((el: any) => {
                        return {
                          id: el.toString(),
                          label: el.toString(),
                          align: "center",
                        };
                      }),
                      {
                        id: "total",
                        label:
                          liveData.clock && gameData?.status == "OPEN"
                            ? "Total"
                            : "Final",
                        align: "right",
                      },
                    ]}
                    listData={[
                      createObject(
                        "home_shots",
                        "shot_summary",
                        gameData?.away_ab
                      ),
                      createObject(
                        "away_shots",
                        "shot_summary",
                        gameData?.home_ab
                      ),
                    ]}
                    // listData={[
                    //   {
                    //     sog: gameData?.away_ab,
                    //     "1": gameData?.away_saves?.total?.p1_shots,
                    //     "2": gameData?.away_saves?.total?.p2_shots,
                    //     "3": gameData?.away_saves?.total?.p3_shots,
                    //     ot: gameData?.away_saves?.total?.p4_shots,
                    //     total: gameData?.away_saves?.total?.total_shots,
                    //   },
                    //   {
                    //     sog: gameData?.home_ab,
                    //     "1": gameData?.home_saves?.total?.p1_shots,
                    //     "2": gameData?.home_saves?.total?.p2_shots,
                    //     "3": gameData?.home_saves?.total?.p3_shots,
                    //     ot: gameData?.home_saves?.total?.p4_shots,
                    //     total: gameData?.home_saves?.total?.total_shots,
                    //   },
                    // ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TableMain
                    headers={[
                      { id: "rank", label: "", align: "left" },
                      { id: "pp", label: "PP", align: "right" },
                      { id: "pim", label: "PIM", align: "right" },
                    ]}
                    listData={[
                      {
                        rank: gameData?.away_ab,
                        pp: `${gameData?.misc_summary?.away_ppg}/${gameData?.misc_summary?.away_pp}`,

                        pim: `${gameData?.misc_summary?.away_pim} min/ ${gameData?.misc_summary?.away_penalties} in`,
                      },
                      {
                        rank: gameData?.home_ab,
                        pp: `${gameData?.misc_summary?.home_ppg}/${gameData?.misc_summary?.home_pp}`,
                        pim: `${gameData?.misc_summary?.home_pim} min/ ${gameData?.misc_summary?.home_penalties} in`,
                      },
                    ]}
                  />
                </Grid>
              </Grid>

              {/* Second Tables Grid */}
              <Grid container spacing={2.5} sx={{ py: 1.5 }}>
                <Grid item xs={12}>
                  <TableTitle title={"SCORING"} />
                  {fiilterArray(gameData?.events)
                    .filter((el: any) => el.key != "SO")
                    ?.map((el: any, index: any) => (
                      <ScoringTable
                        data={el}
                        el={el.array.filter((el: any) => el.type == "goal")}
                        index={index}
                      />
                    ))}
                </Grid>
                <Grid item xs={12}>
                  <TableTitle title={"PENALTIES"} />

                  {fiilterArray(gameData?.events)
                    ?.filter((el: any) => el.key != "SO")
                    ?.map((el: any, index: any) => (
                      <TableMain
                        Title="PENALTIES"
                        headers={[
                          { id: "time_in", label: `${el.key}`, align: "left" },
                          {
                            id: "team_logo",
                            label: "",
                            type: "img",
                            align: "left",
                          },
                          {
                            id: "penalty_player_name",
                            label: "",

                            align: "left",
                          },

                          { id: "penalty_name", label: "", align: "left" },
                        ]}
                        listData={
                          el.key == "SO"
                            ? []
                            : el.array.filter(
                                (element: any) => element.type == "penalty"
                              )
                        }
                      />
                    ))}
                </Grid>
              </Grid>
            </>
          )}
          {selectedTab == 3 && (
            <>
              {/* home sketer Tables Grid */}
              <Grid item xs={12}>
                <TableTitle
                  title={`${gameData?.home_name}`}
                  onClick={(e: any) => {
                    alert(e.target.innerHTML);
                  }}
                />
                <TableMain
                  Title="ROSTER"
                  headers={[
                    { id: "jersey", label: "#" },
                    // { id: "active", label: "" },
                    { id: "name", label: "Skater", align: "left" },
                    { id: "playing", label: "Pos" },
                    { id: "goals", label: "G" },
                    { id: "assists", label: "A" },
                    { id: "pims", label: "PIM" },
                    { id: "shots", label: "Shots" },
                    { id: "plusminus", label: "+/-" },
                  ]}
                  listData={covertIntoArry(gameData?.home_skaters)}
                />
                <TableMain
                  Title="ROSTER"
                  headers={[
                    { id: "jersey", label: "#" },

                    { id: "name", label: "Goalie", align: "left" },
                    { id: "goals_against", label: "GA" },
                    { id: "toi", label: "Mins" },
                    { id: "shots_against", label: "SA" },
                    { id: "saves", label: "SVS" },

                    { id: "goals", label: "G" },
                    { id: "assists", label: "A" },
                    { id: "pims", label: "PIM" },
                  ]}
                  listData={gameData?.home_goalies}
                />
                <TableFooter content={covertIntoArry(gameData?.home_coaches)} />
              </Grid>
            </>
          )}
          {selectedTab == 4 && (
            <>
              {/* away skater Tables Grid */}
              <Grid container spacing={2.5} sx={{ py: 4 }}>
                <Grid item xs={12}>
                  <TableTitle
                    title={`${gameData?.away_name}`}
                    onClick={(e: any) => {
                      alert(e.target.innerHTML);
                    }}
                  />
                  <TableMain
                    Title="ROSTER"
                    headers={[
                      { id: "jersey", label: "#" },
                      // { id: "active", label: "" },
                      { id: "name", label: "Skater", align: "left" },
                      { id: "playing", label: "Pos" },
                      { id: "goals", label: "G" },
                      { id: "assists", label: "A" },
                      { id: "pims", label: "PIM" },
                      { id: "shots", label: "Shots" },
                      { id: "plusminus", label: "+/-" },
                    ]}
                    listData={covertIntoArry(gameData?.away_skaters)}
                  />
                  <TableMain
                    Title="ROSTER"
                    headers={[
                      { id: "jersey", label: "#" },

                      { id: "name", label: "Goalie", align: "left" },
                      { id: "goals_against", label: "GA" },
                      { id: "toi", label: "Mins" },
                      { id: "shots_against", label: "SA" },
                      { id: "saves", label: "SVS" },

                      { id: "goals", label: "G" },
                      { id: "assists", label: "A" },
                      { id: "pims", label: "PIM" },
                    ]}
                    listData={gameData?.away_goalies}
                  />
                  <TableFooter
                    content={covertIntoArry(gameData?.away_coaches)}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      ) : (
        <Typography sx={{ color: "white" }} variant="body2">
          No data is available for this game.
        </Typography>
      )}
    </>
  );
};

export default GameCenterBoard;

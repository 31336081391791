import { shallowEqual, useSelector } from "react-redux";

const useCommonDetails = () => {
  const commonDetails: any = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails,
    shallowEqual
  );
  return commonDetails;
};

export default useCommonDetails;

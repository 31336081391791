import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';

import {Backend} from './Backend';

export class QueueBackend implements Backend {
  queue: Sample[] = [];
  unloadQueue: Sample[] = [];
  syncQueue: Sample[] = [];
  adQueue: Array<AdSample & AdBreakSample & AdAnalyticsSample> = [];

  sendRequest(sample: Sample) {
    this.queue.push(sample);
  }
  sendUnloadRequest(sample: Sample) {
    this.unloadQueue.push(sample);
  }
  sendRequestSynchronous(sample: Sample) {
    this.syncQueue.push(sample);
  }
  sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    this.adQueue.push(sample);
  }

  flushTo(backend: Backend) {
    this.queue.forEach((e) => {
      backend.sendRequest(e);
    });
    this.unloadQueue.forEach((e) => {
      backend.sendUnloadRequest(e);
    });
    this.syncQueue.forEach((e) => {
      backend.sendRequestSynchronous(e);
    });
    this.adQueue.forEach((e) => {
      backend.sendAdRequest(e);
    });
  }
}

import React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import useConfig from "../../hooks/useConfig";

const SupportPage = () => {
  const configData = useConfig();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "60vh",
        padding: "2rem",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Contact Support
      </Typography>
      <Button
        href={configData.supportUrl}
        target="_blank"
        size="large"
        sx={{
          marginBottom: "1rem",
          fontSize: "1.25rem",
          color: "#fff",
          textDecoration: "none",
          ":visited , :focus": {
            color: "#fff",
            textDecoration: "none",
          },
        }}
        variant="contained"
      >
        Click here
      </Button>

      <Typography variant="body2">
        Email:{" "}
        <Link target="_blank" href={`mailto:${configData.supportEmail}`}>
          {configData.supportEmail}
        </Link>
      </Typography>
    </Box>
  );
};

export default SupportPage;

import React, { useEffect, useState } from "react";
import "./index.css";
import { APIRoutes } from "../../utils/constants";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  darken,
  lighten,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import GameCard from "../../Common/Component/GameCard";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import GameListCard from "../../Common/Component/GameCard/GameListCard";
import useConfig from "../../hooks/useConfig";
import InfiniteScroll from "react-infinite-scroll-component";

import { DateRangePicker } from "rsuite";

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import momentTimezone from "moment-timezone";

import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import HttpService from "../../hooks/Https-services";
import styled from "@emotion/styled";
import { getCurrentAuth } from "../../hooks/http-server";

const GroupHeader = styled("div")(({ theme }: any) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
const OnDemand = () => {
  const navigate = useNavigate();
  const auth = getCurrentAuth();
  const location = useLocation();
  const [usersFavoriteTeams, setUserFavoriteTeams] = useState("");

  const [channelOption, setChannelOption] = useState<any>([]);
  const [status, setStatus] = useState(location.pathname.split("/")[1]);
  const handleChageStatus = (event: any) => {
    navigate(`/${event.target.value}`);
    setStatus(event?.target.value);
  };
  const [teamOption, setTeamOption] = useState<
    {
      label: any;
      team_id: any;
      team_description: any;
      team_short_name: any;
    }[]
  >([]);
  const [filterValue, setFilterValue] = useState<any>({
    date: "",
    team: "",
    channel: "",
    division: "",
    group: "",
  });
  const [localFilterValue, setLocalFilterValue] = useState<any>({
    channel: "",
    division: "",
    group: "",
  });
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const [searchparams, setSearhparams] = useSearchParams();
  const channel_id: any = searchparams?.get("channel_id");
  const team: any = searchparams.get("team");
  const search = searchparams.get("search");
  const [hasMore, setHasMore] = useState(true);
  const [teamEventsList, setTeamEventsList] = useState<any>([]);
  const [filterTeamEventsList, setFilterTeamEventsList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const view: any = JSON.parse(localStorage.getItem("view") as any);
  const [isGridView, setIsGridView] = useState(view);
  const userTimeZone = momentTimezone.tz.guess();
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [datefilter, setDatefilter] = useState({
    start_date: "",
    end_date: "",
  });
  const [pagination, setpagination] = useState({
    currentPage: 1,
  });
  const configData = useConfig();
  const FetchChannels = async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );

      if (channel_id) {
        setLocalFilterValue({
          ...filterValue,
          channel: channel_id,
        });
      }
      setChannelOption(result.data.data);
    } catch (error) {}
  };

  const FetchTeams = async (channel_id: any) => {
    try {
      const team: {
        label: any;
        team_id: any;
        customer?: any;
        team_description: any;
        team_short_name: any;
      }[] = [];
      if (auth.AccessToken && (!channel_id || channel_id == 0)) {
        const res = await HttpService.get(APIRoutes.GetUsersFavouriteTeam);
        if (res?.data?.data) {
          const tempAll = {
            label: "All My Favorites",
            customer: 0,
            team_id: 0,
            team_description: "All My Favorites",
            team_short_name: "All My Favorites",
          };
          team.push(tempAll);
          res.data.data?.UserFavouriteTeam?.forEach((element: any) => {
            const temp = {
              label: `${element?.team_name} ${element?.team_mascot}`,
              customer: 0,
              team_id: element?.team_id,
              team_description: element?.team_description,
              team_short_name: element?.team_short_name,
            };

            if (element?.team_description) {
              temp.label = temp?.label + " (" + element?.team_description + ")";
            }
            team.push(temp);
          });

          const teamIds = res.data?.data?.UserFavouriteTeam?.map(
            (el: any) => el?.team_id
          )?.join(",");
          setUserFavoriteTeams(teamIds);
        }
      }
      if (channel_id != 0 || !channel_id) {
        const result = await HttpService.getapi(
          APIRoutes.Team.replace(":id", channel_id || "").replace(
            ":customer",
            configData.customer
          )
        );
        result.data.data?.forEach((element: any) => {
          const temp = {
            label: `${element?.team_name} ${element?.team_mascot}`,
            team_id: element?.team_id,
            team_description: element?.team_description,
            team_short_name: element?.team_short_name,
          };
          if (element?.team_description) {
            temp.label = temp?.label + " (" + element?.team_description + ")";
          }
          team.push(temp);
        });
      }

      setTeamOption(team);
    } catch (error) {
    } finally {
    }
  };
  const options = teamOption?.map((option: any) => {
    return {
      firstLetter: option.customer == 0 ? "My Favorites" : "Teams",
      ...option,
    };
  });

  const FetchTeamEvents = async (page: any) => {
    const startDate = datefilter?.start_date
      ? momentTimezone
          .tz(
            `${datefilter?.start_date} 00:00:00`,
            "YYYY-MM-DD HH:mm:ss.SSSSSS Z",
            userTimeZone
          )
          .tz("America/New_York")
          .format("YYYY-MM-DD HH:mm:ss")
      : "";
    const endDate = datefilter?.end_date
      ? momentTimezone
          .tz(
            `${datefilter?.end_date} 23:59:59`,
            "YYYY-MM-DD HH:mm:ss.SSSSSS Z",
            userTimeZone
          )
          .tz("America/New_York")
          .format("YYYY-MM-DD HH:mm:ss")
      : "";

    try {
      setIsLoading(true);
      const params: any = {
        customer: configData.customer,
        status: "vod",

        start: startDate,
        end: endDate,

        page: page,
      };

      if (filterValue.team || filterValue.team === 0 || team) {
        params.team = filterValue.team || usersFavoriteTeams || team;
      }
      if ((filterValue.channel && filterValue.channel != 0) || channel_id) {
        params.channel = filterValue.channel || channel_id;
      }
      if (search) {
        params.search = search;
      }
      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      const availableGames: any = await GetUserSubscriptionData();

      if (availableGames) {
        if (availableGames?.plan_type_id == 3) {
          const gamesWithSubscription = data.map((userGame: any) => ({
            ...userGame,
            subscribe: availableGames?.data?.some(
              (availableGame: any) => availableGame.game_id == userGame.game_id
            ),
          }));

          setTeamEventsList([...teamEventsList, ...gamesWithSubscription]);
        } else {
          const gamesWithSubscription = data.map((userGame: any) => ({
            ...userGame,
            subscribe: true,
          }));
          setTeamEventsList([...teamEventsList, ...gamesWithSubscription]);
        }
      } else {
        const gamesWithSubscription = data.map((userGame: any) => ({
          ...userGame,
          subscribe: false,
        }));
        setTeamEventsList([...teamEventsList, ...gamesWithSubscription]);
      }

      if (result.data.data === 0) {
        setHasMore(false);
      }
      setPage(page + 1);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const GetUserSubscriptionData = async () => {
    if (!auth.AccessToken) {
      return;
    }
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetails(res?.data?.data?.subscription);
        return res?.data?.data;
      }
    } catch (error) {}
  };
  const CheckUserSubscribe = async () => {
    try {
      const res = await HttpService.get(APIRoutes.CheckSubscribe);
      if (res?.data?.data?.isSubscribe) {
        setIsSubscribed(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (auth.AccessToken) {
      CheckUserSubscribe();
    }
    FetchChannels();
    if (process.env.REACT_APP_DO_NOT_SHOW_TEAM !== "true") {
      FetchTeams(channel_id);
    }
  }, []);

  useEffect(() => {
    FetchTeamEvents(1);
  }, [filterValue, datefilter]);
  const { combine, allowedMaxDays, beforeToday, afterToday }: any =
    DateRangePicker;
  const predefinedRanges: any = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), new Date()],
      placement: "left",
    },

    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last week",
      closeOverlay: false,
      value: (value: any) => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
        ];
      },
      appearance: "default",
    },
  ];
  useEffect(() => {
    setFilterTeamEventsList(teamEventsList);
  }, [filterValue, teamEventsList, localFilterValue]);
  useEffect(() => {
    if (team || search || channel_id) {
      setFilterValue({
        ...filterValue,
        team: team,
        channel: channel_id,
      });
      setPage(1);
      setTeamEventsList([]);
      setHasMore(true);
    }
  }, [location.search]);
  const deleteSearchParams = () => {
    searchparams.delete("search");
    searchparams.delete("team");
    searchparams.delete("channel_id");
    setSearhparams(searchparams);
  };
  const OnFilterAPIChange = (event: any, name: string, teamId?: number) => {
    deleteSearchParams();
    let value = event.target.value;

    if (name === "team") {
      value = teamId;
    }

    setFilterValue({
      ...filterValue,
      [name]: value,
    });
    setPage(1);
    setTeamEventsList([]);
    setHasMore(true);
  };

  const OnFilterLocalChange = (event: any, name: string) => {
    deleteSearchParams();
    let value = event.target.value;
    if (!value) {
      searchparams.delete("channel_id");
      setSearhparams(searchparams);
    }
    setLocalFilterValue({
      ...filterValue,
      [name]: value,
    });
    if (name == "channel") {
      setFilterValue({
        ...filterValue,
        ["channel"]: value,
        ["team"]: "",
      });
    }

    setPage(1);
    setTeamEventsList([]);
    setHasMore(true);
    if (process.env.REACT_APP_DO_NOT_SHOW_TEAM !== "true") {
      FetchTeams(value);
    }
  };

  const handleListView = (val: any) => {
    localStorage.setItem("view", JSON.stringify(val));
    setIsGridView(val);
  };

  return (
    <div>
      <h1 data-v-16b98fb6="" style={{ backgroundColor: "white" }}>
        <span data-v-16b98fb6="">
          <h2 className="event-head container-md">On Demand events</h2>
        </span>
      </h1>

      <div className="w3-row w3-section container-md">
        <div className="content-wrapper w3-panel">
          <div className="category-filter">
            <label className="select-wrapper">
              <span>Status </span>
              <select
                className="custom-select"
                value={status}
                onChange={(event: any) => handleChageStatus(event)}
                defaultValue={status}
              >
                <option value="upcoming-event">
                  Live and Upcoming Events{" "}
                </option>
                <option value={"vod"}>On Demand</option>
              </select>
            </label>
            <div>
              <label className="select-wrapper">
                <span> Channel </span>
                <select
                  className="custom-select"
                  value={localFilterValue.channel}
                  onChange={(event: any) =>
                    OnFilterLocalChange(event, "channel")
                  }
                  defaultValue={localFilterValue.channel}
                >
                  <option value="">Select All</option>
                  {channelOption
                    ?.sort((a: any, b: any) =>
                      a?.channel_weight < b?.channel_weight ? -1 : 1
                    )
                    ?.map((option: any) => (
                      <option value={option.channel_id}>
                        {option?.channel_name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            {process.env.REACT_APP_DO_NOT_SHOW_TEAM !== "true" && (
              <div className="filtered-list-wrapper">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  getOptionLabel={(option) => option.label}
                  options={options}
                  groupBy={(option) => option.firstLetter}
                  onChange={(event: any, value: any) => {
                    OnFilterAPIChange(
                      event,
                      "team",
                      value?.team_id >= 0 ? value.team_id : ""
                    );
                  }}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option?.team_id}>
                      <Typography
                        variant="body1"
                        sx={{ fontStyle: "normal", textTransform: "none" }}
                      >
                        {" "}
                        {option?.label}
                      </Typography>
                    </Box>
                  )}
                  size="small"
                  sx={{
                    width: 1,
                    background: "white",
                    fieldset: {
                      borderColor: "#959799",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Team"
                      sx={{
                        borderColor: "#959799",
                        input: { height: "25px" },
                      }}
                    />
                  )}
                  className=""
                />
              </div>
            )}
            <DateRangePicker
              className="datepicker"
              style={{ width: "100%" }}
              showOneCalendar
              block
              ranges={predefinedRanges}
              onChange={(value: any) => {
                if (value) {
                  deleteSearchParams();
                  setDatefilter({
                    start_date: moment(value[0]).format("YYYY-MM-DD"),
                    end_date: moment(value[1]).format("YYYY-MM-DD"),
                  });
                  setPage(1);
                  setTeamEventsList([]);
                  setHasMore(true);
                }
              }}
              placeholder="Select date range"
            />{" "}
          </div>

          {!isLoading && filterTeamEventsList.length === 0 && (
            <>
              <h2 className="mt-5 no-event text-uppercase">
                There are no events yet
              </h2>
              <div className="lds-roller" style={{ display: "none" }}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          )}
        </div>
      </div>

      <InfiniteScroll
        dataLength={filterTeamEventsList.length}
        next={() => FetchTeamEvents(page)}
        hasMore={hasMore}
        loader={
          isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
                height: "50vh",
              }}
            >
              <CircularProgress
                size={60}
                sx={{
                  color: "#bf311a",
                }}
              />
            </Box>
          )
        }
      >
        {filterTeamEventsList.length > 0 && (
          <>
            <div className="container-md">
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "end",
                  alignItems: "center",
                  gap: 1,
                  mb: 2,
                }}
              >
                <ListIcon
                  onClick={() => handleListView(true)}
                  sx={{
                    cursor: "pointer",
                    height: "32px",
                    width: "32px",
                    color: isGridView ? "#bf321b" : "#777",
                  }}
                />
                <GridViewIcon
                  onClick={() => handleListView(false)}
                  sx={{
                    cursor: "pointer",
                    color: !isGridView ? "#bf321b" : "#777",
                  }}
                />
              </Box>
            </div>
            {!isGridView || isSmallScreen ? (
              <Grid container spacing={2} sx={{ p: 5 }}>
                {filterTeamEventsList?.map((game: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      xl={3}
                      sx={{ justifyContent: "center" }}
                    >
                      <GameCard
                        subscriptionDetails={subscriptionDetails}
                        game={game}
                        auth={auth}
                        isSubscribed={isSubscribed}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <div data-v-b40b6a48="" className="container-md">
                {filterTeamEventsList?.map((game: any) => {
                  return (
                    <GameListCard
                      subscriptionDetails={subscriptionDetails}
                      game={game}
                      auth={auth}
                      isSubscribed={isSubscribed}
                    />
                  );
                })}
              </div>
            )}
            <Box
              sx={{
                textAlign: "center",
                pb: 2,
              }}
            >
              {/* <LoadingButton
								loading={isLoading}
								onClick={() => {
									setPageNo((page) => page + 1);
								}}
								variant="contained"
								sx={{
									background: "#d11d1d",
									fontStyle: "italic",
									":hover": {
										background: "#d11d1d",
									},
								}}
							>
								<Typography variant="h6">SHOW MORE</Typography>
							</LoadingButton> */}
            </Box>
          </>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default OnDemand;

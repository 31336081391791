import { Box, Dialog } from "@mui/material";
import React from "react";

const ImageDialogBox = ({ src }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Box sx={{ width: "100px" }}>
        <img onClick={handleClickOpen} src={src} width={"100%"} />
      </Box>

      <Dialog
        open={open}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={src} width={"100%"} />
      </Dialog>
    </div>
  );
};
export default ImageDialogBox;

import { Box } from "@mui/system";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useState } from "react";
import useConfig from "../../../hooks/useConfig";
import CardDetails from "./CardDetails";

const PaymentUpdate = ({
  customerId,
  subscriptionId,
  handleClose,
  handleChangeRefresh,
}: any) => {
  const configData = useConfig();
  const stripeKey: any = configData?.stripePublishableKey;
  const stripePromise = loadStripe(stripeKey);

  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState("");

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    appearance,
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CardDetails
        handleChangeRefresh={handleChangeRefresh}
        customerId={customerId}
        subscriptionId={subscriptionId}
        handleClose={handleClose}
      />
    </Elements>
  );
};
export default PaymentUpdate;

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import HttpService from "../../../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import useConfig from "../../../../../../hooks/useConfig";
const SelectImageForUpload = ({ setFileUrl, fileUrl, type }: any) => {
  const [listOfMedia, setListOfMedia] = useState([]);
  const [open, setOpen] = useState(false);
  const configData = useConfig();
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState("");
  const GetMediaList = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.getMediaList}?isActive=${true}&type=${
          type === "media" ? 1 : 3
        }`
      );
      setListOfMedia(res?.data?.data);
    } catch (error) {}
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setSelectedMedia(fileUrl);
  }, [fileUrl]);
  useEffect(() => {
    GetMediaList();
  }, [GetMediaList]);
  return (
    <Box>
      <Button variant="contained" onClick={handleOpen}>
        Select {type === "media" ? "Library" : "Poll Background"}
      </Button>
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        sx={{
          width: 1,
          ".MuiDialog-paper": {
            width: 1,
          },
        }}
        open={open}
      >
        <DialogTitle
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          Library
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            {!!listOfMedia.length ? (
              listOfMedia?.map((el: any, index: number) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index + 1}
                  >
                    <img
                      style={{ maxWidth: "250px" }}
                      src={el?.MediaUrl}
                      alt=""
                    />
                    <Box>
                      <Checkbox
                        checked={el?.MediaUrl === selectedMedia}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedMedia(el?.MediaUrl);
                          } else {
                            setSelectedMedia("");
                          }
                        }}
                      />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(PageRoutes.MediaCreate.replace(":id", "0"))
                  }
                >
                  Add Media
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ ":hover": { bgcolor: configData.primary } }}
            onClick={() => {
              setFileUrl(selectedMedia);
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default SelectImageForUpload;

import { Avatar, Box, Divider, Typography } from "@mui/material";
import React from "react";
import NAHL from "../../../assets/img/nahl.png";
import NOTEAM from "../../../assets/img/noteam.png";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "./index.css";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import useConfig from "../../../hooks/useConfig";
import DialogBox from "../DialogBox";
import HttpService from "../../../hooks/Https-services";
const userTimeZone = momentTimezone.tz.guess();
const GameListCard = ({
  game,
  auth,
  isSubscribed,
  subscriptionDetails,
}: {
  game: any;
  auth: any;
  isSubscribed: boolean;
  subscriptionDetails: any;
}) => {
  const navigate = useNavigate();
  const configData = useConfig();
  const gaEventTracker = useAnalyticsEventTracker("ondemand-events");
  const handleNavigate = (game: any) => {
    gaEventTracker("watch", `watch${game.game_id}`);
    navigate(PageRoutes.Watch.replace(":gameId", game.game_id));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const checkGameTimeIsBeetween48Hour = (time: any) => {
    const formatedTime = momentTimezone
      .tz(time, "YYYY-MM-DD hh:mm:ss.SSSSSS a", "America/New_York")
      .tz(userTimeZone);

    const currentTime = moment(moment(), "YYYY-MM-DD hh:mm:ss.SSSSSS a");
    const futureTime = moment(
      moment().add(2, "days"),
      "YYYY-MM-DD hh:mm:ss.SSSSSS a"
    );

    if (formatedTime.isBefore(futureTime)) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmitLogs = async (game: any) => {
    try {
      const data = {
        game_id: game.game_id,
        message: `You started watching ${game.away_team} at ${
          game.home_team
        } from ${moment(
          game.game_date_time,
          "YYYY-MM-DD hh:mm:ss.SSSSSS a Z"
        ).format("YYYY-MM-DD @ hh:mm A")}`,
        eventTyle: "GAME_LOGS",
      };

      const res = await HttpService.post(APIRoutes.GameLogsAdd, data);
    } catch (error) {}
  };

  return (
    <Box
      id={game.game_id}
      sx={{
        width: { xs: 1 },
        minHeight: "120px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        borderRadius: "8px",
        border: "1px solid black",
        boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",

        mb: 2,
      }}
    >
      <Box className="cardBody">
        <Box sx={{ display: "flex", alignItems: "stretch", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              px: 2,
            }}
          >
            {game?.card_logo ? (
              <Box>
                <img
                  src={game?.card_logo}
                  alt=""
                  style={{ width: "80px", height: "50px" }}
                />
              </Box>
            ) : (
              <img
                src={NAHL}
                alt=""
                style={{ width: "80px", height: "50px", visibility: "hidden" }}
              />
            )}
          </Box>
          <Box
            sx={{
              bgcolor: configData.primary,
              minWidth: "200px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                py: 2,
                px: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", lg: "20px" },
                    color: "white",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                  }}
                >
                  {momentTimezone
                    .tz(
                      game.game_date_time,
                      "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                      "America/New_York"
                    )
                    .tz(userTimeZone)
                    .format("MMM DD YYYY")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "8px", lg: "16px" },
                    color: "white",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  {momentTimezone
                    .tz(
                      game.game_date_time,
                      "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                      "America/New_York"
                    )
                    .tz(userTimeZone)
                    .format("h:mm a z")}{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontSize: { xs: "10px", md: "12px" },
                  }}
                >
                  {game?.facility_name}
                </Typography>
              </Box>
            </Box>
          </Box>
          {!!game?.card_image_url_wide ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: 1,
                height: 1,
                p: 1,
              }}
            >
              <Box
                component={"img"}
                title="Image"
                alt={game?.game_id}
                sx={{
                  objectFit: "cover",
                  width: 1,
                  borderRadius: 2,
                }}
                src={game?.card_image_url_wide}
              />
            </Box>
          ) : (
            <Box
              sx={{
                // width: { xs: "100%", md: "50%" },
                width: "100%",
                display: "flex",
                py: 1,
                px: 1,
                gap: 1,
                flexGrow: 1,

                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {game.away_team_logo ? (
                  <Avatar
                    className="card-list-team-log"
                    sx={{ width: "60px" }}
                    src={game.away_team_logo}
                    alt=""
                  />
                ) : (
                  <Avatar
                    className="card-list-team-log"
                    sx={{ width: "60px" }}
                    src={NOTEAM}
                    alt=""
                  />
                )}
                <Box
                  sx={{
                    display: "flex",

                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: { xs: "12px", lg: "16px" },
                    }}
                  >
                    {game.away_team} {game.away_team_mascot}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: { xs: "12px", lg: "14px" },
                    }}
                  >
                    {game.away_team_desc}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{ borderBottom: "1px solid black", width: "100%" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                {game.home_team_logo ? (
                  <Avatar
                    className="card-list-team-log"
                    sx={{ width: "60px" }}
                    src={game.home_team_logo}
                    alt=""
                  />
                ) : (
                  <Avatar
                    className="card-list-team-log"
                    sx={{ width: "60px" }}
                    src={NOTEAM}
                    alt=""
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: { xs: "12px", lg: "16px" },
                    }}
                  >
                    {`${game.home_team} ${game.home_team_mascot}`}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: { xs: "12px", lg: "14px" },
                    }}
                  >
                    {game.home_team_desc}
                  </Typography>
                  {/* <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "12px", lg: "16px" },
                }}
              >
                {game.away_team_mascot}
              </Typography> */}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              justifyContent: "space-between",
              width: { xs: "200px", md: "250px", lg: "280px" },
              bgcolor: configData.secondary,
            }}
          >
            <Box>
              {game?.card_description ? (
                <>
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: { xs: "10px", lg: "14px" },
                      color: "white",
                    }}
                  >
                    {game?.card_description}
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontSize: { xs: "10px", lg: "14px" },
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  REGULAR SEASON GAME
                </Typography>
              )}
            </Box>
            {game?.game_status == 90 ? (
              <Typography
                variant="body2"
                sx={{
                  color: configData?.tertiary,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontStyle: "italic",
                }}
              >
                Broadcast Canceled
              </Typography>
            ) : game?.game_status == 95 ? (
              <Typography
                variant="body2"
                sx={{
                  color: configData?.tertiary,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontStyle: "italic",
                }}
              >
                Game Canceled
              </Typography>
            ) : (
              <Box sx={{ textAlign: "end" }}>
                {isSubscribed || game?.subscribe ? (
                  <button
                    className="card-footer-button"
                    style={{ color: configData.primary }}
                    onClick={() => {
                      handleNavigate(game);
                      handleSubmitLogs(game);
                    }}
                  >
                    WATCH
                  </button>
                ) : !auth?.AccessToken && game.monetization_type == 1 ? (
                  <button
                    className="card-footer-button"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      padding: "0px 8px",
                      color: configData.primary,
                    }}
                    onClick={() => {
                      navigate(PageRoutes.Login);
                    }}
                  >
                    WATCH FREE
                  </button>
                ) : game.monetization_type == 0 ? (
                  <button
                    className="card-footer-button"
                    style={{ color: configData.primary }}
                    onClick={() => {
                      if (auth.AccessToken) {
                        checkGameTimeIsBeetween48Hour(game.game_date_time)
                          ? navigate(
                              `${PageRoutes.Packages}?id=${game.game_id}`
                            )
                          : handleClickOpen();
                      } else {
                        navigate(PageRoutes.Login);
                      }
                    }}
                  >
                    BUY EVENT
                  </button>
                ) : (
                  <button
                    className="card-footer-button"
                    style={{ color: configData.primary }}
                    onClick={() => {
                      handleNavigate(game);
                      handleSubmitLogs(game);
                    }}
                  >
                    WATCH
                  </button>
                )}
              </Box>
            )}
            <DialogBox
              open={open}
              handleClose={handleClose}
              buttonAction={() => handleClose()}
              buttonText={"Close"}
              content={
                "You can only buy a single game within 48 hours of game time."
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GameListCard;

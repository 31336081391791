import {ErrorCode} from '../enums/ErrorCode';
import {Event, EventMap} from '../enums/Event';
import {VideoStartFailedReason} from '../enums/VideoStartFailedReason';
import {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import * as ErrorData from '../types/EventData';
import {NoExtraProperties} from '../types/NoExtraProperties';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import * as Settings from '../utils/Settings';
import * as Utils from '../utils/Utils';

const REBUFFERING_HEARTBEAT_INTERVALS = [3000, 5000, 10000, 30000, 59700];

export abstract class AnalyticsStateMachine {
  protected stateMachine: StateMachine.StateMachine & {[key: string]: any};
  protected onEnterStateTimestamp = 0;

  protected readonly videoStartupTimeoutMs: number = Settings.ANALYTICS_VIDEOSTART_TIMEOUT;
  protected readonly rebufferTimeoutMs: number = Settings.ANALYTICS_REBUFFER_TIMEOUT;

  private rebufferingHeartbeatIntervalHandle?: number;
  private currentRebufferingIntervalIndex = 0;
  private rebufferingTimeoutHandle?: number;
  private videoStartTimeout?: number;

  constructor(
    protected stateMachineCallbacks: StateMachineCallbacks,
    opts: AnalyticsStateMachineOptions,
  ) {
    this.stateMachine = this.createStateMachine(opts);
  }

  abstract createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine.StateMachine;

  abstract callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void;

  callManualSourceChangeEvent(analyticsConfig: AnalyticsConfig, playbackCurrentTimeInSeconds?: number) {
    this.callEvent(
      Event.MANUAL_SOURCE_CHANGE,
      {config: analyticsConfig, currentTime: playbackCurrentTimeInSeconds},
      Date.now(),
    );
  }

  get currentState(): string {
    return this.stateMachine.current;
  }

  resetIntervals() {
    this.clearVideoStartTimeout();
    this.clearRebufferingTimeoutHandle(true);
    this.clearRebufferingHeartbeatHandle();
  }

  //#region rebuffer timeout

  protected startRebufferingHeartbeatInterval(reset = true) {
    this.resetRebufferingHelpers(reset);

    this.startRebufferingTimeoutHandle();

    this.rebufferingHeartbeatIntervalHandle = window.setInterval(() => {
      if (this.stateMachine.current.toLowerCase() !== 'rebuffering') {
        this.resetRebufferingHelpers();
        return;
      }
      const timestamp = new Date().getTime();
      const stateDuration = timestamp - this.onEnterStateTimestamp;
      this.stateMachineCallbacks.heartbeat(stateDuration, this.stateMachine.current.toLowerCase(), {
        buffered: stateDuration,
      });
      this.onEnterStateTimestamp = timestamp;
      this.currentRebufferingIntervalIndex = Math.min(
        this.currentRebufferingIntervalIndex + 1,
        REBUFFERING_HEARTBEAT_INTERVALS.length - 1,
      );
      this.startRebufferingHeartbeatInterval(false);
    }, REBUFFERING_HEARTBEAT_INTERVALS[this.currentRebufferingIntervalIndex]);
  }

  protected resetRebufferingHelpers(reset = true) {
    if (reset) {
      this.currentRebufferingIntervalIndex = 0;
    }
    this.clearRebufferingHeartbeatHandle();
    this.clearRebufferingTimeoutHandle(reset);
  }

  protected clearRebufferingHeartbeatHandle() {
    if (this.rebufferingHeartbeatIntervalHandle != null) {
      window.clearInterval(this.rebufferingHeartbeatIntervalHandle);
      this.rebufferingHeartbeatIntervalHandle = undefined;
    }
  }

  private startRebufferingTimeoutHandle() {
    if (this.currentRebufferingIntervalIndex > 0) {
      return;
    }
    this.rebufferingTimeoutHandle = window.setTimeout(() => {
      this.callEvent(
        Event.ERROR,
        {
          ...ErrorCode.BUFFERING_TIMEOUT_REACHED,
          data: {
            additionalData: `StateMachine timed out after ${this.rebufferTimeoutMs} ms of consecutive buffering.`,
          },
        },
        Utils.getCurrentTimestamp(),
      );
      this.stateMachineCallbacks.release();
    }, this.rebufferTimeoutMs);
  }

  protected clearRebufferingTimeoutHandle(reset: boolean) {
    if (reset && this.rebufferingTimeoutHandle != null) {
      window.clearTimeout(this.rebufferingTimeoutHandle);
      this.rebufferingTimeoutHandle = undefined;
    }
  }
  //#endregion

  //#region videoStartupFailed

  protected getVideoStartupFailedEventData(currentTime: number, event?: string, eventObject?: any) {
    const reason = this.getReasonForVideoStartFailure(event);
    const eventData: ErrorData.VideoStartFailedEvent = {currentTime, reason};
    return eventData;
  }

  protected getReasonForVideoStartFailure(event?: string) {
    switch (event) {
      case Event.ERROR:
        return VideoStartFailedReason.PLAYER_ERROR;
      case Event.UNLOAD:
        return VideoStartFailedReason.PAGE_CLOSED;
      case Event.VIDEOSTART_TIMEOUT:
        return VideoStartFailedReason.TIMEOUT;
      default:
        return VideoStartFailedReason.UNKNOWN;
    }
  }

  protected setVideoStartTimeout() {
    if (this.videoStartTimeout != null) {
      this.clearVideoStartTimeout();
    }
    this.videoStartTimeout = window.setTimeout(() => {
      this.callEvent(Event.VIDEOSTART_TIMEOUT, {}, Utils.getCurrentTimestamp());
      this.stateMachineCallbacks.release();
    }, this.videoStartupTimeoutMs);
  }

  protected clearVideoStartTimeout() {
    window.clearTimeout(this.videoStartTimeout);
    this.videoStartTimeout = undefined;
  }
  //#endregion
}

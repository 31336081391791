export class AdCallbacks {
  onAdStarted = (event: any): void => undefined;
  onAdFinished = (event: any): void => undefined;
  onAdBreakStarted = (event: any): void => undefined;
  onAdBreakFinished = (event: any): void => undefined;
  onAdClicked = (event: any): void => undefined;
  onAdError = (event: any): void => undefined;
  onAdManifestLoaded = (event: any): void => undefined;
  onAdQuartile = (event: any): void => undefined;
  onAdSkipped = (event: any): void => undefined;
  onPlay = (issuer?: string): void => undefined;
  onPause = (issuer?: string): void => undefined;
  onBeforeUnload = (): void => undefined;
}

export interface AdModuleAPI {
  readonly adCallbacks: AdCallbacks;
  isLinearAdActive: () => boolean;
  getContainer: () => HTMLElement;
  getAdModuleInfo: () => {name?: string; version?: string};
  currentTime: () => number;
}

import { Box, Typography } from "@mui/material";
import React from "react";
import UserListTable from "./UserListTable";

const UserList = () => {
  return (
    <Box>
      <UserListTable />
    </Box>
  );
};
export default UserList;

const FooterBlock = {
  backgroundSize: "100% 100%",
  backgroundColor: "primary.main",
  color: "#FFFFFF",
  pt: 1,
  pb: 3,
};

const PaddingBlock = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

const FooterBody = {
  textAlign: "center",
  width: "100%",
};

const StyleDivider = {
  backgroundColor: "common.white",
};

const FooterMenu = {
  color: "#fff",
  cursor: "pointer",
  fontSize: { xs: "10px", sm: "12px" },
};

export { FooterBlock, PaddingBlock, FooterBody, StyleDivider, FooterMenu };

import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader=()=>{
    return (
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          size={48}
          sx={{
            color: "#bf311a",
          }}
        />
      </Box>
    );
}
export default Loader
import React, { useContext, useEffect, useRef, useState } from "react";

import { Navigate, useLocation, useParams } from "react-router-dom";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import MainContainer from "../Component/MainContainer";

import PageRoutes from "../../utils/constants";
import useConfig from "../../hooks/useConfig";
import LoadingContext from "../Contex/LoadingContex";

import { Button } from "@mui/material";

function PrivateRoutes({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const auth = getCurrentAuth();
  let location = useLocation();

  if (!auth?.AccessToken) {
    return (
      <Navigate
        to={PageRoutes.Login}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }

  return (
    <MainContainer>
      <>{children} </>
    </MainContainer>
  );
}

export default PrivateRoutes;

import React, { useContext, useState } from "react";
import {
  Switch,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useConfig from "../../../hooks/useConfig";
import { grey } from "@mui/material/colors";
import { ThemeContext } from "../../../Common/Contex/ThemeContex";

const ToggleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "20px",
  padding: "5px 10px",
  color: "white",
}));

const CustomToggle = ({ checked, setChecked }: any) => {
  const configData = useConfig();
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const { applyTheme }: any = useContext(ThemeContext);
  return (
    <ToggleContainer sx={{ gap: 1 }}>
      <Typography
        variant="body1"
        style={{
          color: !checked ? configData.primary : "#000",
          fontWeight: 600,
          fontStyle: "normal",
        }}
      >
        Monthly
      </Typography>
      <FormControl>
        <Switch
          checked={checked}
          onChange={handleChange}
          sx={{
            height: "28px",
            "	.MuiSwitch-switchBase": { color: "rgba(0, 0, 0, 0.25)", m: "2px" },
            "	.MuiSwitch-track": {
              backgroundColor: "transparent",
              border: "1px solid #ADB5BD",
              borderRadius: "45px",
            },
            ".MuiSwitch-thumb": {
              boxShadow: "none",
              width: "18px",
              height: "18px",
            },
            "	.Mui-disabled": {
              cursor: "not-allowed",
            },

            padding: 0,

            ".MuiSwitch-switchBase": {
              p: "5.5px",
              m: 0,
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(20px)",
                marginLeft: "10px",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor: configData.primary,
                  opacity: 1,
                  border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
            },
            "& .MuiSwitch-track": {
              borderRadius: 26 / 2,
              backgroundColor: grey[300],
              opacity: 1,
              transition: applyTheme.transitions.create(["background-color"], {
                duration: 500,
              }),
            },
          }}
        />{" "}
      </FormControl>

      <Typography
        variant="body1"
        style={{
          color: checked ? configData.primary : "#000",
          fontWeight: 600,
          fontStyle: "normal",
        }}
      >
        Annually
      </Typography>
    </ToggleContainer>
  );
};

export default CustomToggle;

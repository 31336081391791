import { Box, Typography } from "@mui/material";
import React from "react";
import PollListTable from "./PollListTable";

const PollList = () => {
  return (
    <Box>
      <PollListTable />
    </Box>
  );
};
export default PollList;

import {PlayerAPI} from 'bitmovin-player/types/core/PlayerAPI';

import {SsaiEventHandler} from '../../../analyticsStateMachines/SsaiEventHandler';
import {SsaiAdBreakMetadata, SsaiAdMetadata} from '../../../api/AdapterAPI';
import {AD_TYPE} from '../../../enums/AdType';
import {customDataValuesKeys, extractCustomDataFieldsOnly} from '../../../types/CustomDataValues';
import {Sample} from '../../../types/Sample';

enum SsaiState {
  AD_BREAK_STARTED,
  ACTIVE,
  IDLE,
}

type InternalSsaiMetadata = SsaiAdMetadata & SsaiAdBreakMetadata;

/**
 * This class acts as a prototype for a generic version
 * in the furture we will replace bitmovin player specific classes with interfaces
 */
export class SsaiService {
  /**
   * eventHandler is outside dependency.
   * Due to the structure it is not possible to pass it to constructor of this class
   */
  eventHandler: undefined | SsaiEventHandler;

  private state: SsaiState = SsaiState.IDLE;
  /**
   * represents the current playing ad metdata
   * is `undefined` when no ad is playing (after endAdBreak)
   * or omitted by customer
   */
  private currentAdMetadata: undefined | InternalSsaiMetadata;
  private adIndex: number = 0;
  private isFirstSampleOfAd = false;

  // TODO: try to simplify it, all we need here is `player.getCurrentTime()`, not the whole player
  //    - let's try to do it with implementation of another adapters in [AN-4077]
  private player: PlayerAPI;

  constructor(player: any) {
    this.player = player as PlayerAPI;
  }

  adBreakStart(adBreakMetadata?: SsaiAdBreakMetadata) {
    if (this.state != SsaiState.IDLE) {
      return;
    }

    this.state = SsaiState.AD_BREAK_STARTED;
    this.currentAdMetadata = {
      ...adBreakMetadata,
    };
  }

  adStart(adMetadata?: SsaiAdMetadata) {
    if (this.state == SsaiState.IDLE) {
      return;
    }

    const timestamp = new Date().getTime();
    this.eventHandler?.onSsaiInteraction(timestamp, {currentTime: this.player.getCurrentTime()});

    this.state = SsaiState.ACTIVE;
    // to include the adIndex in the next sample which will be the first sample of this started ad
    this.isFirstSampleOfAd = true;

    // we are extracting the customData fields explicitly to make sure that
    // there is not a different object passed
    const customData = adMetadata?.customData != null ? extractCustomDataFieldsOnly(adMetadata.customData) : undefined;

    this.currentAdMetadata = {
      adPosition: this.currentAdMetadata?.adPosition,
      adId: adMetadata?.adId,
      adSystem: adMetadata?.adSystem,
      customData: customData,
    };
  }

  adBreakEnd() {
    if (this.state == SsaiState.IDLE) {
      return;
    }

    if (this.state == SsaiState.ACTIVE) {
      const timestamp = new Date().getTime();
      this.eventHandler?.onSsaiInteraction(timestamp, {currentTime: this.player.getCurrentTime()});
    }

    this.reset();
  }

  private reset() {
    this.state = SsaiState.IDLE;
    this.isFirstSampleOfAd = false;
    delete this.currentAdMetadata;
  }

  resetSourceRelatedState() {
    this.reset();
    this.adIndex = 0;
  }

  manipulate(sample: Sample): void {
    if (this.state != SsaiState.ACTIVE) {
      return;
    }

    sample.ad = AD_TYPE.SSAI;
    sample.adId = this.currentAdMetadata?.adId;
    sample.adSystem = this.currentAdMetadata?.adSystem;
    sample.adPosition = this.currentAdMetadata?.adPosition;
    if (this.isFirstSampleOfAd) {
      sample.adIndex = this.adIndex;
      this.isFirstSampleOfAd = false;
      this.adIndex++;
    }

    const customData = this.currentAdMetadata?.customData;
    if (customData != null) {
      customDataValuesKeys.forEach((key) => {
        if (customData[key]) {
          sample[key] = customData[key];
        }
      });
    }
  }
}

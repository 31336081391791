import { TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyAccount from "..";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import HttpService from "../../../hooks/Https-services";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import commonDetailSlice from "../../../store/reducers/commonReducer";
import axios from "../../../utils/axios";
import { APIRoutes } from "../../../utils/constants";

const EditProfile = () => {
  const userData = useCommonDetails();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validate = (values: any) => {
    let errors: any = {};

    if (!values.first_name) {
      errors.first_name = "Please fill the required fields";
    }
    if (!values.last_name) {
      errors.last_name = "Please fill the required fields";
    }

    return errors;
  };

  const intialValue = {
    first_name: userData.FirstName,
    last_name: userData.LastName,
    email: userData.Email || "",
  };
  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      try {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
        };
        const res = await HttpService.post(APIRoutes.EditProfile, data);
        dispatch(commonDetailSlice.actions.setcommonDetails(res.data.data));
        toast.success("Profile updated successfully");
      } catch (error) {}
    },
  });

  return (
    <MyAccount>
      <div className="col-sm-9 text-left">
        <div className="container mb-4 mt-4">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="form form-group col-md-6">
                <TextField
                  size="small"
                  data-v-ee71dd48=""
                  type="text"
                  name="first_name"
                  id="first_name"
                  defaultValue={userData.FirstName}
                  placeholder="First Name"
                  value={formik.values.first_name}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.errors.first_name = "";
                  }}
                  className="form-control"
                  error={formik.errors.first_name ? true : false}
                  helperText={
                    formik.errors.first_name &&
                    "Please fill the required fields"
                  }
                />
              </div>

              <div className="form form-group col-md-6">
                <TextField
                  size="small"
                  data-v-ee71dd48=""
                  type="text"
                  name="last_name"
                  error={formik.errors.last_name ? true : false}
                  helperText={
                    formik.errors.last_name && "Please fill the required fields"
                  }
                  id="last_name"
                  placeholder="Last Name"
                  value={formik.values.last_name}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.errors.last_name = "";
                  }}
                  className="form-control"
                />
                <span className="field-icon">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAiJJREFUOI191E+IV1UUwPHP/Fz0xyDpjw1Fm5IRS0smSMS7aOMijEiGLuqARLURdFAz1Hqk0KXETTGkxmCbFgO+YEBaNDiiI75NOdRmlhUik0KRgUzMyDj8XLw7w2P6TXdz73n3nO/58865Xe1223KrKKsV2ISt2ICHMIUruJxiuLWsMbo6wYuy6sUA+vDIMrZt/IxvMZRimP1feFFWT+AU3sZdPIhf8TV+wAzWYnfWmccDOZuDKYbvOsKLslqP79GdQdtxGgMphvkO2b2KUfyJafTiJI6mGNrQyorPYwxdCHgFPy4HhhTDT3g3Z3IGgziMzxcj//jc1Ycz6ElswTPqH9aXYhhpRHkWT6HMTtv5bhK3Ugxbi7L6AvvRn2IYbuEDvIgdKYbf8HJ2PN4I9Ky6W1ZjL95q3I1jYz4fyvJgUVaPtrAZtzGRFVbm/d8G4NklVWnK0ws2uYQX8Th6WvgEq/BlVr6Z9zUNwDeN8z8Yacg9+AOKsnoBH2EkxXCtlWKYwKd4ryirz3BJ3cP9DcCHeBP78FKKYSrDVqsHbKwoqx5cyM73kFuxKKsuDOF9nFf37+vYkmL4RYeVbc6p638ExzCH11IMk4vwhvIBnMBsdtDGrhTD6BLwY/gKO/E7nsM1xBTD9QW9/4x/UVbr1MPwRuPzHdxQT2g3nsaKfPcXjqufgHtNVse3JTvpwTa8kyNbqR6yOfyt7ophjKcYZjox7gO7RMC15nhDegAAAABJRU5ErkJggg=="
                    alt=""
                    className="toggle-password-visibility-icon"
                    style={{ display: "none" }}
                  />
                </span>
                <small
                  className="text-danger"
                  style={{ display: "none" }}
                ></small>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="form form-group col-md-6">
                <TextField
                  disabled
                  size="small"
                  style={{ backgroundColor: "lightgrey" }}
                  type="text"
                  autoComplete="off"
                  placeholder="Email"
                  className="form-control"
                  id="txt_email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <button type="submit" className="w-100 btn btn-block btn-dark">
              Update
            </button>
          </form>
        </div>
      </div>
    </MyAccount>
  );
};
export default EditProfile;

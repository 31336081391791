import axios from "axios";
import { MD5, HmacSHA256 } from "crypto-js";

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
const username = "darshan";
const secret = "3aQ@04Ib6tzT";
const base_url = "https://api.nahl.tts.h22s.net";
// "https://api.sharksice.timetoscore.com";

// ("https://api.sharksice.timetoscore.com");

const firebaseConfig = {
  apiKey: "AIzaSyDlrMQ_KQi-O_j45DG8AAClMIvyn9y0H5A",
  authDomain: "tts-firebase-393711.firebaseapp.com",
  databaseURL: "https://tts-firebase-393711-default-rtdb.firebaseio.com",
  projectId: "tts-firebase-393711",
  storageBucket: "tts-firebase-393711.appspot.com",
  messagingSenderId: "785380654472",
  appId: "1:785380654472:web:0cd700cd4d792573320e3d",
  measurementId: "G-3W4R11ZHKC",
};

export const makeGetRequest = async (request, query) => {
  try {
    const body = "";
    const auth_time = Math.floor(Date.now() / 1000);
    const body_md5 = MD5(body).toString(); // crypto.createHash("md5").update(body).digest("hex");
    let args = `auth_key=${username}&auth_timestamp=${auth_time}&body_md5=${body_md5}&${query}`;

    const url = `${base_url}/${request}?${args}`;

    let hash_string = `GET\n/${request}\n${args}`;
    const signature = HmacSHA256(hash_string, secret).toString();
    // const signature = crypto
    //   .createHmac("sha256", secret)
    //   .update(hash_string)
    //   .digest("hex");

    args += `&auth_signature=${signature}`;
    const full_url = `${url}&auth_signature=${signature}`;

    let response = false;
    response = await axios.get(full_url);

    if (response.status === 200) {
      return response.data;
    }

    // axios
    //   .get(full_url)
    //   .then((response) => {
    //     status = response.status;

    //     if (status === 200) {
    //       return response.data;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //     if (error.response) {
    //       status = error.response.status;
    //     } else {
    //       status = error.code;
    //     }
    //     throw error;
    //   });
  } catch (error) {
    if (!window.customconfiguration?.username) {
      console.log("User name is missing");
    }
    if (!window.customconfiguration?.secret) {
      console.log("Secret is missing");
    }
    if (!window.customconfiguration?.base_url) {
      console.log("Api url is missing");
    }
    if (!window.customconfiguration?.league_id) {
      console.log("League id is missing");
    }
    if (!window.customconfiguration?.team_id) {
      console.log("Team id is missing");
    }
    console.log(error);

    return false;
  }
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

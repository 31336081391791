import { USERROLE } from "../utils/constants";

const useCheckHasPermission = (userDetails: any, permission: string) => {
  const findUserRole: any = USERROLE.filter(
    (el: any) => el?.id == userDetails?.UserRoleID
  );
  const hasPermisstion = findUserRole[0]?.permissitions.includes(permission)
    ? true
    : false;

  return hasPermisstion;
};

export default useCheckHasPermission;

import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

const ToggleSwitch = (props: any) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.switch}
          onChange={() => props.setSwitch()}
          color="primary"
          size="small"
        />
      }
      label={props.label}
      defaultChecked={props.switch}
      labelPlacement="start"
      sx={{
        // Adjust fontSize for smaller label
        '.MuiFormControlLabel-label': {
          fontSize: '12px', // You can adjust this value
        },
      }}
    />
  );
};

export default ToggleSwitch;

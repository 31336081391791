import {
  Edit,
  Delete,
  PlayArrow,
  PlayArrowOutlined,
} from "@mui/icons-material";
import { Box, TextField, Typography, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import ChatComponent from "../ChatComponent";
import NOTEAM from "../../../assets/img/noteam.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import React from "react";
const HighLight = ({
  el,
  jumptoTime,
  handleSubmitData,
  handleDelete,
  hide,
}: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({ name: "", time: "" });
  function formatTime(seconds: any) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let timeString = "";
    if (hours > 0) {
      timeString += hours + ":";
    }
    if (minutes > 0 || hours > 0) {
      timeString += minutes;
    }
    timeString += ":" + remainingSeconds;

    return timeString.trim();
  }
  function getOrdinalNumber(number: any) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = number % 100;
    const suffix =
      suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
    return number + suffix;
  }
  const handleEdit = (e: any) => {
    if (isEdit) {
      handleSubmitData(value, el?.video_clip_uuid);
      setIsEdit(false);
    }
    setIsEdit(!isEdit);
  };
  const handleChange = (e: any) => {
    const item: any = { ...value };
    item[e.target.name] = e.target.value;
    setValue(item);
  };
  useEffect(() => {
    setValue({ ...value, name: el?.name, time: el?.duration });
  }, [el]);

  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        bgcolor: "#FFFFFF",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {el?.team_logo_url ? (
            <img src={el?.team_logo_url} width="40px" />
          ) : (
            <img src={NOTEAM} width="40px" />
          )}

          <Typography
            variant="body2"
            sx={{ fontWeight: 600, fontStyle: "normal" }}
          >
            {el?.team_short_name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            pt: 1,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontStyle: "normal",
            }}
            variant="body1"
          >
            {el?.player_first_name?.charAt(0)}.{el?.player_last_name}(
            {el?.player_jersey_number})
          </Typography>
          <Typography
            onClick={jumptoTime}
            sx={{
              textTransform: "capitalize",
              fontStyle: "italic",
            }}
            variant="body2"
          >
            {el?.event_time} / {getOrdinalNumber(el?.period)}{" "}
            {el?.clip_name?.split(" ")[0]}
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={jumptoTime}
        sx={{
          textTransform: "capitalize",
        }}
      >
        <PlayCircleOutlineIcon sx={{ color: "blue", fontSize: "30px" }} />
      </Box>
    </Box>
  );
};
export default HighLight;
// "uuid": "6bb3e4db-ed3e-e50e-8947-2c870b994f19",
//             "game_id": 11156,
//             "start_time": "812.0",
//             "period": 1,
//             "period_time": "615.1",
//             "event_time": "10:15",
//             "clip_duration": "0.8",
//             "clip_name": "GOAL (6) Nehmens - GRA",
//             "player_first_name": "Clarke",
//             "player_last_name": "Nehmens",
//             "player_jersey_number": 6,
//             "team_id": 1560,
//             "team_name": "Gray",
//             "team_logo_url": "https://images-us-east.htptv.net/usah/logos/1560.png",
//             "team_short_name": "GRA",
//             "timestamp": "2023-07-17T12:54:54.000Z"

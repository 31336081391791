import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";
import { useParams, useSearchParams } from "react-router-dom";
import useConfig from "../../hooks/useConfig";

const TableMain = (props: any) => {
  const configData = useConfig();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: searchParams.get("sort") || "points",
    direction: searchParams.get("direction") || "desc",
  });
  
  
  const handleSort = (a: any, b: any, sortConfig: any) => {
    if (!isNaN(a[sortConfig.key]) && !isNaN(b[sortConfig.key])) {
      // return parseInt(a) - parseInt(b); // Sort numbers numerically
      if (+a[sortConfig.key] < +b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
    } else {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
    }
    if (!isNaN(a[sortConfig.key]) && !isNaN(b[sortConfig.key])) {
      // return parseInt(a) - parseInt(b); // Sort numbers numerically
      if (+a[sortConfig.key] > +b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    } else {
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
  
    return 0;
  };

  const sortTable = (key: string, flag: any) => {
    searchParams.set("sort", key);

    if (flag == "filter") {
      if (key) {
        {
          props.headers?.forEach((column:any) => {
            if (column.id == key) {
              const elementsList: any = document.querySelectorAll(
                `#${column.id}`
              );
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.add(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            } else if (key == "rs") {
              searchParams.set("rookie", "true");
              setSearchParams(searchParams);
              const elementsList: any = document.querySelectorAll(`#points`);
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.add(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            } else {
              const elementsList: any = document.querySelectorAll(
                `#${column.id}`
              );
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.remove(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            }
          });
        }

        searchParams.set("direction", "desc");
        setSearchParams(searchParams);

        setSortConfig({ key, direction: "desc" });
      } else {
        {
          props.header.forEach((column:any) => {
            if (column.id == key) {
              const elementsList: any = document.querySelectorAll(`#points`);
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.add(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            }
          });
        }

        searchParams.delete("sort");
        searchParams.delete("direction");
      }
    } else {
      {
        props.headers.forEach((column:any) => {
          if (column.id == key) {
            const elementsList: any = document.querySelectorAll(
              `#${column.id}`
            );
            const elementsArray = [...elementsList];
            elementsArray.forEach((element) => {
              element?.classList.add(
                "wtd-statview-table-highlight-column-color"
              );
            });
          } else {
            const elementsList: any = document.querySelectorAll(
              `#${column.id}`
            );
            const elementsArray = [...elementsList];
            elementsArray.forEach((element) => {
              element?.classList.remove(
                "wtd-statview-table-highlight-column-color"
              );
            });
          }
        });
      }

      let direction: "asc" | "desc" = "asc";
      if (
        searchParams.get("sort") === key &&
        searchParams.get("direction") === "asc"
      ) {
        direction = "desc";
      } else {
      }
      searchParams.set("direction", direction);
      setSearchParams(searchParams);

      setSortConfig({ key, direction });
    }
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  className={`statview-header-color ${
                    headItem.id == "pts"
                      ? "statview-table-highlight-column-color"
                      : ""
                  }`}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={{
                    ...CommonStyle.tableHeader,
                    background:configData.primary,
                    p:"4px"
                  }}
                >
                  {[ "Skater","G","A","PIM","Shots","+/-",'GA','Mins','SA','SVS',
                    ]?.includes(headItem.label) ? (
                      <Typography variant="body2" sx={{fontSize:"12px",color:"#ffffff",textDecoration: "underline",cursor: "pointer",}} onClick={() => sortTable(headItem.id, "table")}>{headItem.label}</Typography>
                    ):(
                  <Typography variant="body2" sx={{fontSize:"12px",color:"#ffffff"}} >{headItem.label}</Typography>)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.listData?.length > 0 ? (
              props.listData
              .sort((a:any, b:any) => handleSort(a, b, sortConfig))
              .map((row: any, index: number) => {
                return (
                  <StyledTableRow
                    hover
                    sx={{ textTransform: "capitalize" }}
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className={`wtd-statview-table-row-color`}
                  >
                    {props.headers?.map((headItem: any) => {
                      const value = row[headItem.id];
                      return headItem.type !== "img" ? (
                        headItem.id == "record" ? (
                          <TableCell
                            sx={{
                              py: 1,
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "12px" }}
                            >{`${row.wins}-${row.losses}-${row.otlosses}-${row.so_losses}`}</Typography>
                          </TableCell>
                        ) : headItem.id == "penalty_name" ? (
                          <TableCell
                            sx={{
                              p:1,
                              py: 1,
                              minWidth:
                                headItem.id == "penalty_player_name"
                                  ? { xs: "40px" }
                                  : "auto",
                              width:
                                headItem.id == "time_in"
                                  ? { xs: "40px"}
                                  : "auto",
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            className={`${
                              headItem.id == "pts"
                                ? "wtd-statview-table-highlight-column-color"
                                : "auto"
                            }`}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            <Typography variant="body2" sx={{fontSize:"12px"}}>{value}</Typography>
                          </TableCell>
                        ): headItem.id == "name" ? (
                          <TableCell
                            sx={{
                              cursor: "pointer",
                              p:1,
                              py: 1,
                              width:"100px"
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            className={`${
                              headItem.id == "pts"
                                ? "wtd-statview-table-highlight-column-color"
                                : "auto"
                            }`}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            <Typography variant="body2" sx={{fontSize:"12px"}}>
                              {value}
                            </Typography>
                          </TableCell>
                        ): (
                          <TableCell
                            sx={{
                              cursor: "pointer",
                              p:1,
                              py: 1,
                              whiteSpace: "nowrap",
                              width:
                                headItem.id == "time"
                                  ? { xs: "40px" }
                                  : headItem.id == "team_name_GOALTENDERS" ||
                                    headItem.id == "penalty_player_name"
                                  ? "250px"
                                  : "auto",
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            className={`${
                              headItem.id == "pts"
                                ? "wtd-statview-table-highlight-column-color"
                                : "auto"
                            }`}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "12px" }}
                            >
                              {headItem.format && typeof value === "number"
                                ? headItem.format(value)
                                : value}
                            </Typography>
                          </TableCell>
                        )
                      ) : (
                        <TableCell
                          sx={{p:1, py: 1, width: "32px" }}
                          key={headItem.id}
                          id={headItem.id}
                          align={headItem?.align ? headItem.align : "left"}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{ width: "36px", height: "36px" }}
                              src={
                                row[headItem.id]
                                  ? row[headItem.id]
                                  : "https://chatmatrix.s3.ap-south-1.amazonaws.com/WTD/assets/no-image-icon-6.png"
                              }
                            />
                            <Typography>{row?.short_name}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={`wtd-statview-table-row-color`}
              >
                <TableCell >
                  {" "}
                  {props?.Title == "PENALTIES"
                    ? "No Penalties"
                    : props?.Title == "ROSTER"
                    ? "No Roster has been set."
                    : "No Data"}
                </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableMain;

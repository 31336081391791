import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import HttpService from "../../../hooks/Https-services";
import { APIRoutes } from "../../../utils/constants";
import Loader from "../../../Common/Component/Loader";
import momentTimezone from "moment-timezone";
import { Download, Refresh } from "@mui/icons-material";
import DownloadClips from "./DownloadClips";
const userTimeZone = momentTimezone.tz.guess();

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const MyClips = ({ isInsider }: any) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof any>("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [clipsData, setClipsData] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDownloadStarted, setIsDownLoadStarted] = useState(false);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clipsData.length) : 0;

  const visibleRows: any = React.useMemo(
    () =>
      stableSort(clipsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [clipsData, order, orderBy, page, rowsPerPage]
  );
  const GetUserAllVideoClips = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetUserAllVideoClips);
      setClipsData(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    GetUserAllVideoClips();
  }, [GetUserAllVideoClips]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
        <Button variant="outlined" onClick={GetUserAllVideoClips}>
          <Refresh />
        </Button>
      </Box>

      <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
        <TableContainer sx={{ height: visibleRows?.length ? 440 : "auto" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableCell>Game Details</TableCell>

                <TableCell align="left">Clip Name</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows
                ?.filter((el: any) => el?.active)
                .map((row: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index + 1}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.game_details?.game_date_time &&
                          momentTimezone
                            .tz(
                              row?.game_details?.game_date_time,
                              "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                              "America/New_York"
                            )
                            .tz(userTimeZone)
                            .format("MM/DD/YYYY")}
                        {"-"}
                        {row?.game_details?.away_team &&
                          `${row?.game_details?.away_team}@${row?.game_details?.home_team}`}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        <DownloadClips
                          isInsider={isInsider}
                          el={row}
                          isDownloadStarted={isDownloadStarted}
                          setIsDownLoadStarted={setIsDownLoadStarted}
                          GetUserAllVideoClips={() => GetUserAllVideoClips()}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={clipsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
export default MyClips;

import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import MainContainer from "../Component/MainContainer";

import PageRoutes, { UserRoles } from "../../utils/constants";
import useCommonDetails from "../Hooks/useCommonDetails";

function AdminRoutes({
  children,
  allowedUser,
}: {
  children: React.ReactElement;
  allowedUser?: any[];
}): React.ReactElement {
  const auth = getCurrentAuth();
  let location = useLocation();
  const userdetails = useCommonDetails();
  if (allowedUser?.includes(userdetails?.UserRoleID)) {
    return <>{children} </>;
  }
  return (
    <Navigate
      to={PageRoutes.HomePage}
      state={{ from: location.pathname, search: location.search }}
      replace
    />
  );
}

export default AdminRoutes;

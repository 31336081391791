import { Edit, PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import momentTimezone from "moment-timezone";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContext from "../../Common/Contex/LoadingContex";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import HttpService from "../../hooks/Https-services";
import useAxiosPrivate, { Logout } from "../../hooks/useAxiosPrivate";
import useConfig from "../../hooks/useConfig";
import commonDetailSlice from "../../store/reducers/commonReducer";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import "./index.css";
import FavoriteTeam from "./FavoriteTeam";
const ProfileSetting = () => {
  const userData = useCommonDetails();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoadingPayment }: any = useContext(LoadingContext);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPassword, setIsEditPassowrd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userTimeZone = momentTimezone.tz.guess();
  const configData = useConfig();
  const currentTime = momentTimezone().tz(userTimeZone);
  const [passwordData, setPassword] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordDataError, setPasswordError] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const validate = (values: any) => {
    let errors: any = {};

    if (!values.first_name) {
      errors.first_name = "Please fill the required fields";
    }
    if (!values.last_name) {
      errors.last_name = "Please fill the required fields";
    }

    return errors;
  };

  const intialValue = {
    first_name: userData.FirstName,
    last_name: userData.LastName,
    email: userData.Email || "",
  };

  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      try {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
        };
        const res = await HttpService.post(APIRoutes.EditProfile, data);
        dispatch(commonDetailSlice.actions.setcommonDetails(res.data.data));
        toast.success("Profile updated successfully");
        setIsEdit(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleChange = async (e: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const res = await HttpService.postImage(APIRoutes.UploadImage, formData);
      const data = {
        image: `${configData.s3HostUrl}${res?.data?.data}`,
      };

      const res2 = await HttpService.post(APIRoutes.EditProfile, data);
      dispatch(commonDetailSlice.actions.setcommonDetails(res2.data.data));
      toast.success("Profile image updated successfully");
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);

      setIsLoading(false);
    }
  };
  const handleChangePassword = (e: any) => {
    setPasswordError({ new_password: "", password: "", confirm_password: "" });

    setPassword({ ...passwordData, [e.target.name]: e.target.value });
  };
  const handleEdit = (e: any) => {
    e.preventDefault();
    setIsEdit(true);
  };
  const handlesubmitPassword = async () => {
    const passwordRegx = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );

    if (!passwordData.password) {
      setPasswordError({
        ...passwordDataError,
        password: "Please fill the required fields",
      });
      return;
    }
    if (!passwordData.new_password) {
      setPasswordError({
        ...passwordDataError,
        new_password: "Please fill the required fields",
      });
      return;
    } else if (!passwordRegx.test(passwordData.new_password)) {
      setPasswordError({
        ...passwordDataError,
        new_password:
          "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.",
      });
      return;
    }
    if (!passwordData?.confirm_password) {
      setPasswordError({
        ...passwordDataError,
        confirm_password: "Please fill the required fields",
      });
      return;
    } else if (passwordData?.confirm_password != passwordData?.new_password) {
      setPasswordError({
        ...passwordDataError,
        confirm_password: "Confirm password do not match with entered password",
      });
      return;
    }
    try {
      const res = await HttpService.post(
        APIRoutes.ChangePassword,
        passwordData
      );
      if (res) {
        toast.success("Passowrd changed successfull");
        setIsEditPassowrd(false);
      }
      setIsEditPassowrd(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handleEditPassoword = (e: any) => {
    e.preventDefault();
    setIsEditPassowrd(true);
  };
  const handleImageUpload = async (e: any) => {
    e.preventDefault();
  };
  return (
    <div className="container-fluid">
      {isLoadingPayment && (
        <Box
          sx={{
            position: "absolute",
            bottom: "50%",
            top: "50%",
            right: 0,
            zIndex: 1301,
            left: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                gap: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Your order is being processed.
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, fontStyle: "normal" }}
              >
                Please do not refresh the page
              </Typography>

              <CircularProgress
                size={48}
                sx={{
                  color: "#bf311a",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={
          isLoadingPayment
            ? {
                "-webkit-filter": "blur(5px)",
                filter: " blur(5px)",
              }
            : {}
        }
        className="main-content padding-top"
      >
        <div className="container">
          <div className="row">
            <Box sx={{ mb: 2 }}></Box>

            <div className="col-sm-12 text-left">
              <Box>
                <Typography variant="h1">Profile Information</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontStyle: "normal",
                    textTransform: "none",
                  }}
                >
                  Update your profile information
                </Typography>

                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <IconButton
                      sx={{
                        width: "64px",
                        height: "64px",
                        backgroundColor: "#D9D9D9",
                        p: 0,
                      }}
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={36}
                          sx={{
                            color: configData.primary,
                          }}
                        />
                      ) : userData?.ProfileImage ? (
                        <img
                          width={"100%"}
                          height="100%"
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                          src={userData?.ProfileImage}
                          alt=""
                        />
                      ) : (
                        <PhotoCamera sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                    <Box>
                      <Typography variant="h3">
                        {" "}
                        {userData.FirstName} {userData.LastName}
                      </Typography>
                      <Typography
                        sx={{ wordBreak: "break-all" }}
                        variant="subtitle2"
                      >
                        {userData?.Email}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "block" }}>
                    <label
                      className={configData.buttonCommonClass}
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        textTransform: "uppercase",
                        fontWeight: 600,
                      }}
                    >
                      Edit Profile Photo
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(e) => handleChange(e)}
                      />
                    </label>
                  </Box>
                </Box>
              </Box>
              {process.env.REACT_APP_DO_NOT_SHOW_TEAM !== "true" && (
                <FavoriteTeam />
              )}
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h1">Contact Information</Typography>

                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                      flexDirection: { xs: "column", md: "row" },
                      border: "1px solid #DADADA",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textTransform: "none",
                              fontWeight: 600,
                              minWidth: { xs: "auto", md: "300px" },
                            }}
                          >
                            Display Name
                          </Typography>
                          {isEdit ? (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <TextField
                                size="small"
                                data-v-ee71dd48=""
                                type="text"
                                name="first_name"
                                id="first_name"
                                defaultValue={userData.FirstName}
                                placeholder="First Name"
                                value={formik.values.first_name}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.errors.first_name = "";
                                }}
                                error={formik.errors.first_name ? true : false}
                                helperText={
                                  formik.errors.first_name &&
                                  "Please fill the required fields"
                                }
                              />

                              <TextField
                                size="small"
                                data-v-ee71dd48=""
                                type="text"
                                name="last_name"
                                error={formik.errors.last_name ? true : false}
                                helperText={
                                  formik.errors.last_name &&
                                  "Please fill the required fields"
                                }
                                id="last_name"
                                placeholder="Last Name"
                                value={formik.values.last_name}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.errors.last_name = "";
                                }}
                              />
                            </Box>
                          ) : (
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textTransform: "none",
                                fontWeight: 600,
                                minWidth: { xs: "auto", md: "300px" },
                              }}
                            >
                              {userData.FirstName} {userData.LastName}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textTransform: "none",
                              fontWeight: 600,
                              minWidth: { xs: "auto", md: "300px" },
                            }}
                          >
                            Verified Email Address
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textTransform: "none",
                              fontWeight: 600,
                              wordBreak: "break-all",
                              minWidth: { xs: "auto", md: "300px" },
                            }}
                          >
                            {userData?.Email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "block" }}>
                      {isEdit ? (
                        <button
                          type="submit"
                          className={configData.buttonCommonClass}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={handleEdit}
                          className={configData.buttonCommonClass}
                        >
                          Edit
                        </button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </form>

              <Box sx={{ py: 4 }}>
                <Typography variant="h1">Password</Typography>

                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    flexDirection: { xs: "column", md: "row" },
                    border: "1px solid #DADADA",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          Password
                        </Typography>
                        {isEditPassword ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              size="small"
                              data-v-ee71dd48=""
                              type="text"
                              fullWidth
                              name="password"
                              id="password"
                              placeholder="Current Password"
                              value={passwordData.password}
                              onChange={(e) => handleChangePassword(e)}
                              error={passwordDataError.password ? true : false}
                              helperText={
                                passwordDataError.password &&
                                passwordDataError.password
                              }
                            />

                            <TextField
                              size="small"
                              fullWidth
                              data-v-ee71dd48=""
                              type="text"
                              name="new_password"
                              id="new_password"
                              placeholder="New Password"
                              error={
                                passwordDataError.new_password ? true : false
                              }
                              helperText={
                                passwordDataError.new_password &&
                                passwordDataError.new_password
                              }
                              value={passwordData.new_password}
                              onChange={(e) => handleChangePassword(e)}
                            />
                            <TextField
                              size="small"
                              fullWidth
                              data-v-ee71dd48=""
                              type="text"
                              name="confirm_password"
                              id="confirm_password"
                              placeholder="Confirm New Password"
                              error={
                                passwordDataError.confirm_password
                                  ? true
                                  : false
                              }
                              helperText={
                                passwordDataError.confirm_password &&
                                passwordDataError.confirm_password
                              }
                              value={passwordData.confirm_password}
                              onChange={(e) => handleChangePassword(e)}
                            />
                          </Box>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            sx={{
                              textTransform: "none",
                              fontWeight: 600,
                              minWidth: { xs: "auto", md: "300px" },
                            }}
                          >
                            ********
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "block" }}>
                    {isEditPassword ? (
                      <button
                        onClick={handlesubmitPassword}
                        className={configData.buttonCommonClass}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={handleEditPassoword}
                        className={configData.buttonCommonClass}
                      >
                        Edit
                      </button>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ py: 4 }}>
                <Typography variant="h1"> Personal Information</Typography>

                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    flexDirection: { xs: "column", md: "row" },
                    border: "1px solid #DADADA",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          Current Timezone
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          {currentTime.format("z")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          Registration Date
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          {momentTimezone(
                            userData.createdAt,
                            "YYYY-MM-DD HH:mm:ss.SSSSSS A Z",

                            "America/New_York"
                          )
                            .tz(userTimeZone)
                            .format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          Terms & Conditions Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            wordBreak: "break-all",
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          {momentTimezone(
                            userData.createdAt,
                            "YYYY-MM-DD HH:mm:ss.SSSSSS A Z",

                            "America/New_York"
                          )
                            .tz(userTimeZone)
                            .format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          Privacy Policy Date
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: "none",
                            fontWeight: 600,
                            wordBreak: "break-all",
                            minWidth: { xs: "auto", md: "300px" },
                          }}
                        >
                          {" "}
                          {momentTimezone(
                            userData.createdAt,
                            "YYYY-MM-DD HH:mm:ss.SSSSSS A Z",

                            "America/New_York"
                          )
                            .tz(userTimeZone)
                            .format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};
export default ProfileSetting;

import {
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  Table,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import momentTimezone from "moment-timezone";
import React from "react";
import { visuallyHidden } from "@mui/utils";
import DownloadClips from "../DownloadClips";
const userTimeZone = momentTimezone.tz.guess();
interface Data {
  date: string;
  away_team: string;
  home_team: string;
  name: string;
  jobStatus: string;
  button: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "away_team",
    numeric: false,
    disablePadding: false,
    label: "Away Team",
  },
  {
    id: "home_team",
    numeric: false,
    disablePadding: false,
    label: "Home Team",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "jobStatus",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "button",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.label ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
const DownloadedClipsTable = ({ isInsider, clipsData }: any) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clipsData.length) : 0;

  const visibleRows: any = React.useMemo(
    () =>
      stableSort(clipsData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [clipsData, order, orderBy, page, rowsPerPage]
  );

  return (
    <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
      <TableContainer sx={{ height: visibleRows?.length ? 440 : "auto" }}>
        <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.map((row: any, index: any) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index + 1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {row?.game_details?.game_date_time &&
                      momentTimezone
                        .tz(
                          row?.game_details?.game_date_time,
                          "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                          "America/New_York"
                        )
                        .tz(userTimeZone)
                        .format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    {row?.game_details?.away_team &&
                      `${row?.game_details?.away_team}`}
                  </TableCell>
                  <TableCell align="left">
                    {row?.game_details?.home_team}
                  </TableCell>

                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.jobStatus}</TableCell>
                  <TableCell align="right">
                    <DownloadClips isInsider={isInsider} el={row} />
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={clipsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default DownloadedClipsTable;

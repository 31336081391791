export interface CustomDataValues {
  customData1?: string;
  customData2?: string;
  customData3?: string;
  customData4?: string;
  customData5?: string;
  customData6?: string;
  customData7?: string;
  customData8?: string;
  customData9?: string;
  customData10?: string;
  customData11?: string;
  customData12?: string;
  customData13?: string;
  customData14?: string;
  customData15?: string;
  customData16?: string;
  customData17?: string;
  customData18?: string;
  customData19?: string;
  customData20?: string;
  customData21?: string;
  customData22?: string;
  customData23?: string;
  customData24?: string;
  customData25?: string;
  customData26?: string;
  customData27?: string;
  customData28?: string;
  customData29?: string;
  customData30?: string;
  experimentName?: string;
}

/**
 * Lists all keys of the {@see CustomDataValues} interface.
 */
export const customDataValuesKeys: Array<keyof CustomDataValues> = [
  'customData1',
  'customData2',
  'customData3',
  'customData4',
  'customData5',
  'customData6',
  'customData7',
  'customData8',
  'customData9',
  'customData10',
  'customData11',
  'customData12',
  'customData13',
  'customData14',
  'customData15',
  'customData16',
  'customData17',
  'customData18',
  'customData19',
  'customData20',
  'customData21',
  'customData22',
  'customData23',
  'customData24',
  'customData25',
  'customData26',
  'customData27',
  'customData28',
  'customData29',
  'customData30',
  'experimentName',
];

/**
 * The number of CustomDataXX fields in {@see CustomDataValues}.
 */
export const customDataFieldCount = customDataValuesKeys.filter((i) => i.match('customData\\d+')).length;

// TODO: [AN-4136] implement unit tests
export const extractCustomDataFieldsOnly = (input: CustomDataValues): CustomDataValues => {
  const retVal: CustomDataValues = {};
  customDataValuesKeys.forEach((key) => {
    if (input[key]) {
      retVal[key] = input[key];
    }
  });
  return retVal;
};

import { Edit, Delete, Share, Handshake } from "@mui/icons-material";
import { Box, TextField, Typography, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import ChatComponent from "../ChatComponent";
import DownloadIcon from "@mui/icons-material/Download";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
} from "../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import HttpService from "../../../hooks/Https-services";
import DialogBox from "../../../Common/Component/DialogBox";
import ShareClips from "./ShareClips";
export function formatTime(seconds: any) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let timeString = "";
  if (hours > 0) {
    timeString += (hours < 10 ? "0" + hours : hours) + ":";
  }
  if (minutes > 0 || hours > 0) {
    timeString += minutes < 10 ? "0" + minutes : minutes;
  }
  timeString +=
    ":" + (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);

  return timeString.trim();
}
const ClipsData = ({
  el,
  jumptoTime,
  setCurrentClip,
  handleSubmitData,
  handleDelete,
  hide,
  setIsEdit,
  isEdit,
  isDownloadStarted,
  setIsDownLoadStarted,
  GetClipsDetails,
  isLive,
  subscriptionDetails,
  videoUrl,
  vod_url,
}: any) => {
  const [isDownload, setIsDownload] = useState(false);
  const [value, setValue] = useState({ name: "", time: "" });
  const userDetails = useCommonDetails();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { gameId } = useParams();

  const handleEdit = (e: any) => {
    // if (isEdit) {
    //   handleSubmitData(value, el?.video_clip_uuid);
    //   setIsEdit(false);
    // }

    setCurrentClip(el);
    jumptoTime(el?.start_time);

    setIsEdit(!isEdit);
  };

  //  game_id: Joi.string().required(),

  //         videoUrl: Joi.string().optional(),
  //         unique_id: Joi.string().optional(),
  const handelDownload = async (e: any, el: any) => {
    try {
      setIsDownLoadStarted(true);
      setIsDownload(true);
      if (el.jobStatus != "COMPLETE" && !isDownload) {
        const downloadVideo = await HttpService.post(
          isLive
            ? APIRoutes.CreteDownloadVideoInLive
            : APIRoutes.CreteDownloadVideo,
          {
            game_id: gameId,
            videoUrl: isLive ? videoUrl : vod_url,
            unique_id: el?.video_clip_uuid,
          }
        );
        toast.info(
          "Your download has been requested.  You will receive an email when it is complete."
        );
        GetClipsDetails();
        setIsDownload(false);
        setIsDownLoadStarted(false);
        return;
      }
      const a = document.createElement("a");
      a.href = `https://images-us-east.htptv.net/videoclips/${el?.jobS3FileName}`;
      a.target = "_blank";
      a.download = `${el.jobId}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      setIsDownload(false);
      setIsDownLoadStarted(false);
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };
  useEffect(() => {
    setValue({ ...value, name: el?.name, time: el?.duration });
  }, [el]);
  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {" "}
        <Typography
          onClick={jumptoTime}
          sx={{
            color: "black",
            fontStyle: "normal",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          variant="body2"
        >
          {formatTime(el?.start_time)}
        </Typography>
        <Typography
          onClick={jumptoTime}
          sx={{
            color: "black",
            fontStyle: "normal",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          variant="body2"
        >
          {el?.name}
        </Typography>
      </Box>
      {!hide && (
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
          }}
        >
          {/* {" "}
        {isEdit ? (
          <TextField
            size="small"
            onChange={handleChange}
            autoComplete="off"
            type={"text"}
            value={value.time}
            name="time"
            defaultValue={formatTime(el?.duration)}
            sx={{
              input: {
                color: "white",
                fontSize: "14px",
                fontWeight: 600,
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#DADADA",
                },
              },
            }}
            variant="outlined"
          />
        ) : (
          <Typography
            sx={{
              color: "white",
              fontStyle: "normal",
              fontWeight: 600,
              mr: 1,
            }}
            variant="body2"
          >
            {el?.duration}
          </Typography>
        )} */}
          {el?.type === "SHARED" && (
            <Tooltip
              placement="top"
              arrow
              title={
                el?.shared_by?.FirstName
                  ? `Clip shared by ${el?.shared_by?.FirstName}  
                ${el?.shared_by?.LastName}`
                  : ""
              }
            >
              <IconButton sx={{ p: 0.5 }}>
                <Handshake sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          )}
          <IconButton sx={{ p: 0.5 }}>
            <Edit onClick={(e) => handleEdit(e)} sx={{ color: "black" }} />
          </IconButton>
          {(userDetails?.UserRole?.Permissions?.includes("download") ||
            process.env.REACT_APP_FEATURE === "true") && (
            <IconButton
              disabled={
                el?.jobStatus == "SUBMITTED" ||
                el?.jobStatus == "ERROR" ||
                el?.jobStatus == "PENDING" ||
                isDownload ||
                (!el?.jobStatus && isDownloadStarted)
              }
              sx={{ p: 0.5 }}
            >
              <DownloadIcon
                onClick={(e) => {
                  subscriptionDetails?.plan_type_id == INSIDER_PLAN_ID ||
                  userDetails?.UserRole?.Permissions?.includes("download")
                    ? handelDownload(e, el)
                    : handleClickOpen();
                }}
                sx={{
                  color:
                    el?.jobStatus == "SUBMITTED" ||
                    el?.jobStatus == "ERROR" ||
                    el?.jobStatus == "PENDING" ||
                    !el?.jobStatus
                      ? "grey"
                      : "green",
                }}
              />
            </IconButton>
          )}
          <DialogBox
            buttonText={""}
            handleClose={handleClose}
            open={open}
            content={
              <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
                It looks like you're trying to initiate a download. Downloads
                are limited to subscribers of our Insider Plan. Head over to the{" "}
                <Link to={PageRoutes.Packages}>Plans</Link> tab to upgrade to
                the Insider Plan and then try your download again.
              </Typography>
            }
            buttonActionSecondary={handleClose}
            butttonTextSecondary={"Cancel"}
          />

          <IconButton sx={{ p: 0.5 }}>
            <Delete
              onClick={(e) => handleDelete(el?.video_clip_uuid)}
              sx={{ color: "black" }}
            />
          </IconButton>
          {userDetails?.UserRole?.Permissions?.includes("watch") && (
            <ShareClips el={el} />
          )}
        </Box>
      )}
    </Box>
  );
};
export default ClipsData;
